import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@material-ui/core';
import Dropzone, { Accept } from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ImageIcon from '@material-ui/icons/Image';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './styles';
import { formatBytes } from 'utils/sections';

interface FileDropzoneProps {
  label?: string;
  info?: string;
  accept?: Accept;
  files: File[];
  /* eslint-disable */
  onChange: (files: File[]) => void;
  onDelete: (file: File) => void;
  /* eslint-enable */
}

export default function FileDropzone({
  label,
  info,
  accept,
  files,
  onChange,
  onDelete
}: FileDropzoneProps) {
  const classes = styles();

  const renderFileList = () => {
    if (!files || !files.length) return null;

    return (
      <List dense={true} className={classes.list}>
        {files.map((file: any, index: number) => {
          return (
            <ListItem key={`file-${index}`}>
              <ListItemAvatar>
                <Avatar>
                  <ImageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={classes.name}
                primary={file.name}
                secondary={formatBytes(file.size)}
              />
              <ListItemSecondaryAction>
                <IconButton
                  data-testid='cj3-dropzone-file-delete'
                  edge='end'
                  aria-label='delete'
                  onClick={() => onDelete(file)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
  };

  return (
    <>
      <Dropzone onDrop={onChange} accept={accept}>
        {({ getRootProps, getInputProps }: any) => (
          <div {...getRootProps()} className={classes.dropzone} data-testid='cj3-dropzone'>
            <input {...getInputProps()} data-testid='cj3-dropzone-input' />
            {label && <Typography className={classes.label}>{label}</Typography>}
            <CloudUploadIcon className={classes.uploadIcon} />
            {info && <Typography className={classes.uploadDescription}>{info}</Typography>}
          </div>
        )}
      </Dropzone>

      {renderFileList()}
    </>
  );
}
