import { ThemeProvider } from '@material-ui/core';
import { AppContext } from 'context/App/AppContext';
import useGetCustomizationSettings from 'hooks/useGetCustomizationSettings';
import { ReactNode, useContext } from 'react';
import defaultTheme from 'theme/default';

type Props = {
  children: ReactNode;
};

const CustomTheme = ({ children }: Props) => {
  const { appState } = useContext(AppContext);
  const customizationSettings = useGetCustomizationSettings();

  return (
    <ThemeProvider
      theme={defaultTheme(
        customizationSettings.parsedSettings,
        customizationSettings.fonts,
        appState.tenantSettings?.organisationId
      )}
    >
      {children}
    </ThemeProvider>
  );
};

export default CustomTheme;
