import { ThemeFonts, Font, FontProvider } from 'context/App/types';
import { load } from 'webfontloader';

export const setupFonts = (fonts?: ThemeFonts, organisation?: string) => {
  const defaultFonts = {
    typography:
      organisation === 'srv'
        ? ['FK Grotesk Neue', 'sans-serif'].join(',')
        : ['Helvetica Neue', 'sans-serif'].join(',')
  };
  if (fonts) {
    const fontValues: Font[] = Object.values(fonts);

    const availableFontNames = [
      'Open+Sans',
      'Material+Icons',
      'Montserrat',
      'Lato',
      'Poppins',
      'Inter'
    ];
    const fontsForQuill = availableFontNames.map((font) => {
      return {
        provider: FontProvider.GOOGLE,
        fontFamily: font
      };
    });

    const fontsToLoad = fontValues.concat(fontsForQuill);
    const parsedFonts = fontsToLoad.reduce(
      (fontsMap: Map<FontProvider, string[]>, current: Font) => {
        if (current.provider) {
          const currentProviderFonts = fontsMap.get(current.provider) || [];
          fontsMap.set(current.provider, currentProviderFonts?.concat(current.fontFamily));
        }
        return fontsMap;
      },
      new Map()
    );

    const googleFonts = parsedFonts.get(FontProvider.GOOGLE) || [];
    // const customProviderFonts = parsedFonts.get(FontProvider.CUSTOM) || [];
    if (googleFonts.length) {
      load({
        google: {
          families: googleFonts
        }
        // custom: customProviderFonts.length ? {

        // } : undefined
      });
      return fonts.textFont.fontFamily && fonts.textFont.provider === FontProvider.GOOGLE
        ? {
            typography: fonts.textFont.fontFamily
            // headersTypography: customFonts.headersFont.fontFamily
          }
        : defaultFonts;
    }
  }
  return defaultFonts;
};
