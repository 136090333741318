import { ListItemText, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { getDateFnsLocale } from 'i18n';
import { ChatMessageType } from '../../types';
import styles from './styles';

interface ChatMessagePropsType {
  message: ChatMessageType;
  messages: ChatMessageType[];
  index: number;
}

export default function ChatMessage({ message, index, messages }: ChatMessagePropsType) {
  const { content, sendTime, userName } = message;
  const classes = styles();
  const date = new Date(sendTime * 1000);

  return (
    <ListItemText className={`${classes.root} ${message.isFromUser ? 'left' : 'right'}`}>
      {index > 0 && userName === messages[index - 1].userName ? null : (
        <Typography className={classes.senderName}>{userName}</Typography>
      )}
      <Typography className={classes.date}>
        {format(date, 'YYY-MM-dd HH:mm', { locale: getDateFnsLocale() })}
      </Typography>
      <Typography className={classes.content}>{content}</Typography>
    </ListItemText>
  );
}
