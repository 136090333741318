import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px 22px 24px'
  },

  button: {
    fontSize: theme.typography.overline.fontSize,
    textTransform: 'none'
  },

  rightbuttoncontainer: {
    marginLeft: 'auto',
    display: 'flex',
    gap: theme.spacing(1)
  }
}));

export default useStyles;
