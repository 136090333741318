import { createStyles, makeStyles } from '@material-ui/core/styles';

const integratedAppStyles = makeStyles(() =>
  createStyles({
    iframeContainer: {
      height: '100%',
      width: '100%'
    },
    iframe: {
      height: '100%',
      width: '100%',
      border: 'none'
    }
  })
);

export default integratedAppStyles;
