import { getTenantSettings } from 'axiosInstances';
import { AppContext } from 'context/App/AppContext';
import { useContext, useEffect } from 'react';
import { getStartParameters } from 'utils/startParameters';
import { login } from 'utils/utils';
import useT from './useT';
import { initializeGA } from 'services/tracking';

const useSetupApp = (): void => {
  const { appState, dispatch } = useContext(AppContext);
  const startParameters = getStartParameters();
  const { organisationId, tenantId } = startParameters;
  const t = useT();

  const setup = async () => {
    if (appState.token) return;

    try {
      const { token, user } = await login(startParameters);

      const tenantSettings = await getTenantSettings(
        String(organisationId),
        String(tenantId),
        token
      );

      dispatch({
        ...appState,
        loading: false,
        startParameters,
        token,
        user,
        tenantSettings
      });
    } catch (e) {
      console.error(e);

      dispatch({
        ...appState,
        error: t('alert.appSetupFailed'),
        loading: false
      });
    }
    initializeGA();
  };

  useEffect(() => {
    setup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useSetupApp;
