import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface StylesProps {
  isSuccess?: boolean;
}

export default makeStyles<Theme, StylesProps>((theme: Theme) =>
  createStyles({
    buttonGroup: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px'
    },
    buttonGroupSmall: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px 0'
    },
    textButton: {
      fontSize: theme.typography.caption.fontSize,
      color: theme.palette.common.white,
      '&:hover': {
        textDecoration: 'none'
      }
    } as CSSProperties,
    textButton2: {
      fontSize: theme.typography.caption.fontSize,
      color: theme.palette.common.black,
      '&:hover': {
        textDecoration: 'none'
      }
    },
    smallTextButton: {
      fontSize: '10px'
    },
    buttonContainer: {
      fontSize: theme.typography.caption.fontSize,
      width: '170px',
      textTransform: 'none',
      padding: ' 4px 0px'
    },
    snackBar: {
      padding: '8px',
      display: 'flex',
      flexDirection: 'row',
      color: 'white'
    },
    snackBarContainer: {
      backgroundColor: (props: StylesProps) =>
        props.isSuccess ? theme.palette.success.main : theme.palette.error.main,
      borderRadius: 10,
      marginBottom: '30px',
      padding: 4
    }
  })
);
