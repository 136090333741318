import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography
} from '@material-ui/core';
import useT from 'hooks/useT';
import useStyles from './styles';
interface ConfirmationDialogProps {
  dialogProps: DialogProps;
  onHandling: () => void;
}

function ConfirmationDialog({ dialogProps, onHandling }: ConfirmationDialogProps) {
  const t = useT();
  const classes = useStyles();
  return (
    <Dialog {...dialogProps} PaperProps={{ style: { minWidth: '20rem' } }}>
      <DialogContent dividers style={{ textAlign: 'center', border: 'none', margin: '10px auto' }}>
        <Typography variant='h6'>{t('userDetails.deleteConfirmation')} ?</Typography>
      </DialogContent>
      <DialogActions className={classes.buttonGroup}>
        <Button
          onClick={(evt) => {
            if (dialogProps.onClose) {
              dialogProps.onClose(evt, 'escapeKeyDown');
            }
          }}
          className={classes.button}
          variant='contained'
          color='secondary'
          data-testid='cancel-btn'
        >
          {t('userDetails.cancel')}
        </Button>
        <Button
          onClick={(evt) => {
            if (dialogProps.onClose) {
              dialogProps.onClose(evt, 'escapeKeyDown');
              onHandling();
            }
          }}
          className={classes.button}
          variant='contained'
          color='primary'
          data-testid='save-btn'
        >
          {t('userDetails.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
