import { fetchUserRights } from 'axiosInstances';
import { getProjects } from '../axiosInstances/instances/app';
import { useContext, useEffect } from 'react';
import { AppContext } from 'context/App/AppContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import { getProjectSettings } from 'axiosInstances/instances/project';
import StartParameter from 'utils/startParameters';
import useT from './useT';
import { ProjectSettings } from 'context/Project/interfaces';
import UserRights from 'context/App/UserRights';
import { UserRight } from 'context/App/types';

const useSetupProjects = () => {
  const { appState } = useContext(AppContext);
  const { projectState, dispatch } = useContext(ProjectContext);
  const { token, startParameters } = appState;
  const { tenantId, organisationId, projectId } = startParameters;
  const t = useT();

  const setupProjects = async () => {
    if (appState.loading || appState.error || projectState.projects.length) return;

    dispatch({
      ...projectState,
      loading: true
    });

    try {
      const { projects, selectedProject } = await getProjects(String(token), String(projectId));

      if (!projects.length) throw Error('No projects found');

      dispatch({
        ...projectState,
        projects,
        selectedProject,
        loadDetails: true
      });
    } catch (e) {
      console.log(e);
      dispatch({
        ...projectState,
        error: t('alert.projectsFetchFailed')
      });
    }
  };

  const setupProjectDetails = async () => {
    if (!projectState.selectedProject || !projectState.loadDetails) {
      return null;
    }

    dispatch({
      ...projectState,
      loading: true
    });

    const selectedProject = projectState.selectedProject;
    let projectSettings: ProjectSettings = {} as ProjectSettings;
    let userRights: UserRights = {} as UserRights;

    try {
      projectSettings = await getProjectSettings(
        String(token),
        String(organisationId),
        String(tenantId),
        String(selectedProject?.id)
      );

      userRights = await fetchUserRights({
        projectId: String(projectId),
        tenantId: String(tenantId),
        organisationId: String(organisationId),
        token
      });

      sessionStorage.setItem(StartParameter.projectId, String(selectedProject?.id));
    } catch (e) {
      console.error(e);
      dispatch({
        ...projectState,
        error: t('alert.projectDetailsFetchFailed')
      });
    } finally {
      dispatch({
        ...projectState,
        projectSettings,
        userRights,
        loading: false,
        loadDetails: false,
        editingModeEnabled:
          userRights?.check(UserRight.editMode) && startParameters.editingMode === 'true'
            ? true
            : false
      });
    }
  };

  useEffect(() => {
    setupProjects();
  }, [appState.loading]); // eslint-disable-line

  useEffect(() => {
    setupProjectDetails();
  }, [projectState.loadDetails]); // eslint-disable-line
};

export default useSetupProjects;
