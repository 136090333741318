import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex'
    },
    icon: { color: theme.palette.navBarSecondary?.main || 'white' },
    langName: { color: theme.palette.navBarSecondary?.main || 'white', margin: 'auto' }
  });
});

export default useStyles;
