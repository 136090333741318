import { GetOwnerSearchUsers, getUserDetailPicture } from 'axiosInstances';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import { AppContext } from 'context/App/AppContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import { useContext, useEffect, useState } from 'react';

export interface OwnerSearchInterface {
  apartmentId: number;
  apartmentName: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  picture: string;
  projectId: number;
  hasMore?: boolean;
}

const useGetOwnerSearch = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { appState } = useContext(AppContext);
  const [apartmentOwners, setApartmentOwners] = useState<OwnerSearchInterface[]>([]);
  const { apartmentState } = useContext(ApartmentContext);
  const { selectedApartment } = apartmentState;
  const { organisationId, tenantId, authToken } = appState.startParameters;
  const { projectState } = useContext(ProjectContext);

  const fetch = async () => {
    if (!projectState.selectedProject || !authToken || !organisationId || !tenantId) return;
    try {
      setLoading(true);
      const res = await GetOwnerSearchUsers({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        gbToken: String(authToken),
        projectId: String(projectState.selectedProject?.id)
      });
      const emailSet = new Set();
      const ownerSet = new Set();
      const ownerEmailMap = new Map();

      const apartmentOwnersArray: OwnerSearchInterface[] = res
        .flatMap((apartment: any) => {
          if (apartment.owners && apartment.owners.length > 0) {
            return (Array.isArray(apartment.owners) ? apartment.owners : []).map((owner: any) => ({
              apartmentId: apartment.apartmentId,
              apartmentName: apartment.name,
              projectId: apartment.projectId,
              firstName: owner.firstName,
              email: owner.email,
              lastName: owner.lastName,
              phoneNumber: owner.phoneNumber,
              picture: owner.picture
            }));
          }
          return [];
        })
        .filter((owner: any) => {
          if (ownerSet.has(owner.email)) {
            emailSet.add(owner.email);
            return true;
          } else {
            ownerSet.add(owner.email);
            ownerEmailMap.set(owner.email, owner);
            return true;
          }
        });

      apartmentOwnersArray.forEach((owner) => {
        owner.hasMore = emailSet.has(owner.email);
      });
      setApartmentOwners(apartmentOwnersArray);
      setLoading(false);
      // Fetch and update the pictures asynchronously
      apartmentOwnersArray.forEach(async (owner: OwnerSearchInterface) => {
        if (owner.picture) {
          const ownerAuth0Id = owner.picture.split('.')[0];
          const imageBlob = await getUserDetailPicture({
            organisationId: String(organisationId),
            tenantId: String(tenantId),
            gbToken: String(authToken),
            auth0Id: String(ownerAuth0Id)
          });
          if (imageBlob) {
            const imageObjectURL = URL.createObjectURL(imageBlob);
            setApartmentOwners((prevOwners) =>
              prevOwners.map((prevOwner) =>
                prevOwner.email === owner.email
                  ? { ...prevOwner, picture: imageObjectURL }
                  : prevOwner
              )
            );
          }
        }
      });
    } catch (e) {
      console.error(e);
      setError(true);
    }
  };

  useEffect(() => {
    fetch();
  }, [selectedApartment, organisationId, tenantId, projectState.selectedProject]); // eslint-disable-line

  return { loading, error, apartmentOwners };
};

export default useGetOwnerSearch;
