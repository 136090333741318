import { UserRight } from 'context/App/types';
import { ProjectContext } from 'context/Project/ProjectContext';
import { useState, useEffect, useContext } from 'react';

interface EditingModeProps {
  editingModeAvailable: boolean;
  editingModeEnabled?: boolean;
  toggleEditingMode: () => void;
}

/**
 * useEditingMode hook
 *
 * @returns - EditingModeProps
 */
export const useEditingMode = (): EditingModeProps => {
  const { projectState, dispatch } = useContext(ProjectContext);
  const { selectedProject } = projectState;
  const [editingModeAvailable, setEditingModeAvailable] = useState<boolean>(false);

  const toggleEditingMode = () => {
    dispatch({
      ...projectState,
      editingModeEnabled: !projectState.editingModeEnabled
    });
  };

  useEffect(() => {
    if (projectState.userRights?.check(UserRight.editMode)) {
      setEditingModeAvailable(true);
    }
  }, [selectedProject?.id, projectState]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    editingModeAvailable,
    editingModeEnabled: projectState.editingModeEnabled,
    toggleEditingMode
  };
};
