import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    tile: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',

      '& button': {
        marginTop: 'auto'
      },

      '& .tag': {
        right: '-32px',

        '&::before': {
          content: '""'
        }
      }
    }
  })
);
