import { LocalFormatDateTimeType } from 'components/Sections/Calendar/interfaces';
import { getDateFnsLocale } from 'i18n/Languages';

export const localFormatDateTime = (
  date: Date,
  formatType: LocalFormatDateTimeType = LocalFormatDateTimeType.Full
) => {
  const { code } = getDateFnsLocale() || { code: 'en-US' };
  const locale = code || 'en-US';

  const isEnglish = locale.startsWith('en');

  let options: Intl.DateTimeFormatOptions;

  switch (formatType) {
    case 'date':
      options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      };
      break;

    case 'time':
      options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: isEnglish ? true : false
      };
      break;

    case 'full':
    default:
      options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: isEnglish ? true : false
      };
      break;
  }

  return new Intl.DateTimeFormat(locale, options).format(date);
};
