import { fetchBulletins, fetchRoomMessages } from 'axiosInstances';
import { useContext, useEffect } from 'react';
import { ChatStateAction } from 'store/chatState/actions';
import { ChatStateContext } from 'store/chatState/ChatStateProvider';
import { AppContext } from 'context/App/AppContext';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import { Room } from 'context/Apartment/interfaces';

const useChatMessages = (disableRefresh = false) => {
  const { dispatch } = useContext(ChatStateContext);
  const { apartmentState } = useContext(ApartmentContext);
  const { appState } = useContext(AppContext);
  const selectedApartment = apartmentState.selectedApartment;

  const syncMessages = async () => {
    if (selectedApartment) {
      const rooms: Room[] = selectedApartment.rooms || [];

      if (!disableRefresh) dispatch({ type: ChatStateAction.SET_IS_REFRESHING, data: true });

      const threads = await Promise.all(
        rooms.map(async (room) => {
          const messagesResponse = await fetchRoomMessages({
            gbToken: String(appState.token),
            roomId: '' + room.id
          });

          return { messages: messagesResponse.data.data, room };
        })
      );

      const bulletinsResponse = await fetchBulletins({
        gbToken: String(appState.token),
        apartmentId: '' + selectedApartment.id
      });

      dispatch({ type: ChatStateAction.SET_THREADS, data: threads });
      dispatch({ type: ChatStateAction.SET_LAST_REFRESH_TIME });
      dispatch({
        type: ChatStateAction.SET_BULLETINS,
        data: bulletinsResponse.data.data
      });
      if (!disableRefresh) dispatch({ type: ChatStateAction.SET_IS_REFRESHING, data: false });
    }
  };

  useEffect(() => {
    syncMessages();

    const interval = !disableRefresh ? setInterval(syncMessages, 30000) : null;
    return interval ? () => clearInterval(interval) : undefined;
  }, [apartmentState.selectedApartment?.id, disableRefresh]); // eslint-disable-line

  return syncMessages;
};

export default useChatMessages;
