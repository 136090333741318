import React from 'react';
import { Snackbar, CircularProgress, Typography } from '@material-ui/core';
import useStyles from './styles';

interface SnackBarNotificationProps {
  open: boolean;
  message: string;
  isSuccess: boolean;
  onClose: () => void;
  dialogClosingTime: number;
}

export interface SnackbarState {
  open: boolean;
  message: string;
  isSuccess: boolean;
}

const SnackBarNotification: React.FC<SnackBarNotificationProps> = ({
  open,
  message,
  isSuccess,
  onClose,
  dialogClosingTime
}) => {
  const classes = useStyles();

  return (
    <Snackbar
      data-testid={'snackbar-id'}
      open={open}
      autoHideDuration={dialogClosingTime}
      onClose={onClose}
      className={isSuccess ? classes.snackBarContainer : classes.snackBarErrorContainer}
    >
      <div className={classes.snackBar}>
        {isSuccess ? (
          <>
            <CircularProgress size={20} color='inherit' />
            <Typography variant='body2' style={{ paddingLeft: 10, color: 'inherit' }}>
              {message}
            </Typography>
          </>
        ) : (
          <Typography variant='body2' style={{ paddingLeft: 10, color: 'inherit' }}>
            {message}
          </Typography>
        )}
      </div>
    </Snackbar>
  );
};

export default SnackBarNotification;
