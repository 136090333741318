import { deleteProjectPhase, updateProjectPhase } from 'axiosInstances';
import PhaseForm from 'components/PhaseForm/PhaseForm';
import { AppContext } from 'context/App/AppContext';
import { ModalMessageContext } from 'context/ModalMessage/ModalMessageContext';
import { PhaseContext } from 'context/Phase/PhaseContext';
import { PhaseToSubmit } from 'context/Phase/interfaces';
import { ProjectContext } from 'context/Project/ProjectContext';
import useT from 'hooks/useT';
import { useContext, useState } from 'react';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import FlashNotification from 'components/FlashNotification/FlashNotification';
import { useHistory } from 'react-router-dom';
import useBasePath from 'hooks/useBasePath';

export default function EditPhase() {
  const t = useT();
  const history = useHistory();
  const basePath = useBasePath();
  const { appState } = useContext(AppContext);
  const { organisationId, tenantId } = appState.startParameters;
  const { projectState } = useContext(ProjectContext);
  const { selectedProject } = projectState;
  const { phaseState, dispatch } = useContext(PhaseContext);
  const { modalMessageState, dispatch: dispatchModalMessage } = useContext(ModalMessageContext);
  const [deleteFailed, setDeleteFailed] = useState<boolean>(false);
  const [updateOrderFailed, setUpdateOrderFailed] = useState<boolean>(false);

  const handleDeleteSection = async () => {
    try {
      const deletedPhase = await deleteProjectPhase({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        projectId: String(selectedProject?.id),
        gbToken: String(appState.token),
        phaseId: String(phaseState.selectedPhase?.id)
      });

      const updatedPhases = phaseState.phases.filter(
        (p) => String(p.id) !== String(deletedPhase.phaseId)
      );

      dispatch({
        ...phaseState,
        phases: updatedPhases,
        selectedPhase: updatedPhases.length > 0 ? updatedPhases[0] : undefined
      });

      if (updatedPhases.length > 0) {
        history.push(`${basePath}/phase/${updatedPhases[0].id}`);
      } else {
        history.push(`${basePath}/`);
      }
    } catch (e: any) {
      console.error(`Failed to delete phase, error: ${e.message}`);
      setDeleteFailed(true);
    } finally {
      dispatchModalMessage({
        ...modalMessageState,
        isOpen: false
      });
    }
  };

  const deletePhase = () => {
    dispatchModalMessage({
      icon: <ReportProblemOutlinedIcon />,
      title: t('modal.deletePhaseCaption'),
      description: t('modal.deletePhaseDescription'),
      isOpen: true,
      onApprove: handleDeleteSection
    });
  };

  const editSelectedPhase = async (phase: PhaseToSubmit) => {
    const newPhase = await updateProjectPhase({
      organisationId: String(organisationId),
      tenantId: String(tenantId),
      projectId: String(selectedProject?.id),
      gbToken: String(appState.token),
      phase
    });

    dispatch({
      ...phaseState,
      phases: phaseState.phases.map((ph) => (ph.id === phase.id ? newPhase : ph))
    });
  };

  const closeFlashNotification = () => {
    if (updateOrderFailed) setUpdateOrderFailed(false);
    else setDeleteFailed(false);
  };

  return (
    <>
      <FlashNotification
        open={updateOrderFailed || deleteFailed}
        message={
          updateOrderFailed ? t('alert.sectionOrderUpdateFailed') : t('alert.sectionDeleteFailed')
        }
        onClose={closeFlashNotification}
      />
      <PhaseForm
        onSubmit={(phase: PhaseToSubmit) => {
          editSelectedPhase(phase);
        }}
        orderNumber={phaseState.phases.length + 1}
        phase={phaseState.selectedPhase}
        onDelete={deletePhase}
      />
    </>
  );
}
