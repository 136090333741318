import Section, { SectionType } from '../Section';

export default class ContactsSection extends Section {
  constructor(props: any) {
    super(props);

    this.type = SectionType.CONTACTS;
    this.alertProps.error.txtKey = 'contacts.fetchFailed';
    this.alertProps.noItems.txtKey = 'contacts.noContactsFound';
  }
}
