import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    chatContainer: {
      position: 'absolute',
      background: theme.palette.common.white,
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0),
      paddingBottom: '1rem',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: 'auto',
      maxWidth: '75%',
      maxHeight: '85%'
    },
    headerImage: {
      height: theme.typography.pxToRem(300),
      width: '100%',
      objectFit: 'cover'
    },
    headerTitle: {
      color: theme.palette.common.white
    },
    headerDescription: {
      color: theme.palette.common.white,
      fontSize: theme.typography.h5.fontSize
    },
    headerTexts: {
      padding: theme.typography.pxToRem(16),
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,0.4)'
    },
    chatSection: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
      maxHeight: '75%',
      overflowY: 'scroll'
    },
    headerImageContainer: {
      position: 'relative'
    },
    headerTextsWrapper: {
      position: 'absolute',
      top: '50%',
      left: theme.typography.pxToRem(50)
    }
  })
);
