import { useContext, useEffect, useRef, useState } from 'react';
import useStyles from './styles';
import WebViewer, { Core, WebViewerInstance } from '@pdftron/webviewer';
import { signDocument } from 'axiosInstances';
import Progress from 'components/Progress/Progress';
import useT from 'hooks/useT';
import { GBButton } from 'components/Button/GBButton';
import { Grid } from '@material-ui/core';
import { AppContext } from 'context/App/AppContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import { AttachmentViewerContext } from 'context/AttachmentViewer/AttachmentViewerContext';
import { AttachmentType } from 'components/Sections/Attachments/interfaces';
import { Alert } from '@material-ui/lab';

const viewerConfig = {
  path: '/webviewer/lib',
  licenseKey: process.env.PDFTRON_LICENSE_KEY,
  fullAPI: true,
  disabledElements: ['ribbons', 'textFieldToolGroupButton', 'header', 'toolsHeader']
};

interface SignableDocumentProps {
  document: AttachmentType;
}

export default function SignableDocument({ document }: SignableDocumentProps): JSX.Element {
  const { id, url, name, xfdf, signed } = document;
  const classes = useStyles();
  const text = useT();
  const viewerDiv = useRef<HTMLDivElement | null>(null);
  const [instance, setInstance] = useState<WebViewerInstance | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [signature, setSignature] = useState<string | null>(null);
  const [signing, setSigning] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const { appState } = useContext(AppContext);
  const { projectState } = useContext(ProjectContext);
  const { attachmentViewerState, dispatch } = useContext(AttachmentViewerContext);

  const setDownloadLink = async (instance: WebViewerInstance) => {
    const { annotationManager, documentViewer } = instance.Core;

    const xfdfString = await annotationManager.exportAnnotations();
    const data = await documentViewer.getDocument().getFileData({ xfdfString });
    const arr = new Uint8Array(data);
    const url = URL.createObjectURL(new Blob([arr], { type: 'application/pdf' }));

    dispatch({
      ...attachmentViewerState,
      downloadLink: url,
      showDownloadButton: true
    });
  };

  const annotationChanged = (instance: WebViewerInstance, action: string) => {
    if (action === 'delete') {
      setSignature(null);
      return;
    }
    instance.Core.annotationManager.exportAnnotations().then(async (annotXfdf: string) => {
      if (signed) {
        // document loaded and its signed
        setDownloadLink(instance);
      } else if (annotXfdf !== xfdf) {
        // signature changed
        setSignature(annotXfdf);
      }
    });
  };

  const documentLoaded = (annotationManager: Core.AnnotationManager) => {
    annotationManager.importAnnotations(String(xfdf)).then(() => setLoading(false));
  };

  const signClicked = async () => {
    setError(undefined);
    if (!signature) return;
    setSigning(true);

    const { tenantId, organisationId } = appState.startParameters;
    const gbToken = appState.token;
    const projectId = projectState.selectedProject?.id;

    try {
      await signDocument({
        gbToken: String(gbToken),
        projectId: String(projectId),
        tenantId: String(tenantId),
        organisationId: String(organisationId),
        attachmentId: String(id),
        xfdf: signature
      });
    } catch (e) {
      setSigning(false);
      setError(text('alert.signDocumentFailed'));
      return;
    }

    await setDownloadLink(instance as WebViewerInstance);

    dispatch({
      ...attachmentViewerState,
      documentUpdated: true
    });

    setSigning(false);

    dispatch({
      ...attachmentViewerState,
      open: false
    });
  };

  useEffect(() => {
    if (instance || !viewerDiv.current || loading) return;

    setLoading(true);

    WebViewer(viewerConfig, viewerDiv.current).then((instance: WebViewerInstance) => {
      setInstance(instance);

      const { Core, UI } = instance;
      const { annotationManager, documentViewer } = Core;

      UI.loadDocument(url, { filename: name });

      documentViewer.addEventListener('documentLoaded', async () =>
        documentLoaded(annotationManager)
      );

      annotationManager.addEventListener(
        'annotationChanged',
        (annotations: any[], action: string) => {
          if (!annotations) return;

          annotationChanged(instance, action);
          setInstance(instance);
        }
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      {loading && <Progress />}

      <div
        ref={viewerDiv}
        data-testid='viewer-div'
        className={`${classes.pdfViewer} ${loading ? ' loading' : ''}`}
      />
      {!attachmentViewerState.downloadLink && signature && (
        <Grid item className={`${classes.buttonsGrid} buttons-grid`}>
          {error && (
            <Alert severity='error' variant='outlined' style={{ marginBottom: '10px' }}>
              {error}
            </Alert>
          )}
          <GBButton loading={signing} onClick={() => signClicked()}>
            {text('attachments.sign')}
          </GBButton>
        </Grid>
      )}
    </>
  );
}
