import { createContext, Dispatch } from 'react';
import { SectionState } from './interfaces';

export const initialSectionState: SectionState = {
  sections: [],
  loading: true,
  showBackButton: true
};

export const SectionContext = createContext<{
  sectionState: SectionState;
  dispatch: Dispatch<SectionState>;
}>({ sectionState: initialSectionState, dispatch: () => initialSectionState });
