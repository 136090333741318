import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  FormHelperText,
  Typography,
  MenuItem,
  Grid,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import BackButton from 'components/BackButton/BackButton';
import FileDropzone from 'components/FileDropzone/FileDropzone';
import HtmlContentEditor from 'components/HtmlContent/HtmlContentEditor';
import { SectionContext } from 'context/Section/SectionContext';
import useT from 'hooks/useT';
import useValidation from 'hooks/useValidation';
import { useContext, useRef, useState } from 'react';
import { Accept } from 'react-dropzone';
import {
  FieldType,
  FormState,
  HtmlInputState,
  InputState,
  SelectInputState
} from 'components/Form/interfaces';
import styles from './styles';
import { Attachment } from 'components/Sections/Section';
import { AppContext } from 'context/App/AppContext';
import { Button, ButtonType } from '@groupbuilderoy/gb-components-library';
import { FormQuestionGroup } from 'components/Sections/Forms/FormsAccordions/interfaces';

interface FormLocalizationKeys {
  title: string;
  description?: string;
  submit: string;
  submitSuccess: string;
  submitError: string;
}

interface FormProps {
  localizationKeys: FormLocalizationKeys;
  fields: Record<string, InputState>;
  onSubmit: (_formState: FormState) => Promise<void>;
  onBackButtonClick?: () => void;
  isQuestionnarie?: boolean;
  localizationOverrides?: Partial<FormLocalizationKeys>;
  hideSubmit?: boolean;
  fieldsWithGroup?: FormQuestionGroup[];
}

export default function Questionnaire({
  localizationKeys,
  fields,
  onSubmit,
  onBackButtonClick,
  isQuestionnarie = false,
  localizationOverrides,
  hideSubmit = false,
  fieldsWithGroup
}: FormProps) {
  const { appState } = useContext(AppContext);
  const classes = styles();
  const t = useT();
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [formState, setFormState] = useState<FormState>(JSON.parse(JSON.stringify(fields)));
  const { sectionState } = useContext(SectionContext);
  const headingRef = useRef<HTMLHeadingElement>(null);
  const { validateHtmlContent, validateAttachments } = useValidation();

  const renderAlert = (messageKey: string, severity: Color) => {
    return (
      <Alert
        className={
          appState.tenantSettings?.organisationId === 'srv'
            ? classes.formAlertSrv
            : classes.formAlert
        }
        variant='outlined'
        severity={severity}
      >
        {t(messageKey)}
      </Alert>
    );
  };

  const validateInput = (input: InputState) => {
    const inputState = input;
    inputState.error = input.required && !input.value?.length;

    if (!inputState.error) {
      if (input.maxLength && input.value?.length > input.maxLength) {
        inputState.error = true;
        inputState.validationErrorMessage = t('form.validation.maxLengthExceeded', {
          maxLength: input.maxLength
        });
      }
    }

    return inputState;
  };

  const validateForm = () => {
    let valid = true;
    let validatedFormState = { ...formState };

    for (const key in formState) {
      let inputFieldState = validateInput(formState[key]);

      if (formState[key].type === FieldType.HTML_CONTENT) {
        inputFieldState = validateHtmlContent(inputFieldState);
        inputFieldState = validateAttachments(inputFieldState);
      }

      validatedFormState = {
        ...validatedFormState,
        [key]: inputFieldState
      };

      if (inputFieldState.error) valid = false;
    }

    setFormState(validatedFormState);

    return valid;
  };

  const submitClicked = async () => {
    setSuccess(false);
    setError(false);

    if (!validateForm()) return;

    try {
      setSubmitting(true);

      await onSubmit(formState);
      if (!isQuestionnarie) {
        setFormState(fields);
      }
      setSuccess(true);
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setSubmitting(false);

      if (headingRef && headingRef.current)
        headingRef.current.scrollIntoView({ top: 0, block: 'start', behavior: 'smooth' } as any);
    }
  };

  const onInputChange = (e: React.SyntheticEvent<{ value: unknown }>) => {
    const { name, value } = e.target as HTMLInputElement;
    const input = { ...formState[name], value };
    setFormState({
      ...formState,
      [name]: validateInput(input)
    });
  };

  const onFileChange = (images: File[]) => {
    const currentImages = formState.images.value as File[];

    setFormState({
      ...formState,
      images: {
        ...formState['images'],
        value: currentImages.concat(images)
      }
    });
  };

  const deleteImage = (file: File) => {
    const currentImages = formState.images.value as File[];

    setFormState({
      ...formState,
      images: {
        ...formState['images'],
        value: currentImages.filter((item) => item !== file)
      }
    });
  };

  const onHtmlContentChange = (value: string, attachments?: Attachment[]) => {
    const name = 'html';
    const input = formState[name] as HtmlInputState;
    const updatedInput = {
      ...input,
      value,
      attachments: attachments ? attachments : input.attachments
    };

    setFormState({
      ...formState,
      [name]: attachments ? validateAttachments(updatedInput) : updatedInput
    });
  };

  const onSelectChange = (name: string, value: string) => {
    const input = { ...formState[name], value } as SelectInputState;
    const { onSelectOption } = fields[name] as SelectInputState;

    setFormState({
      ...formState,
      ...(onSelectOption && onSelectOption(value, formState)),
      [name]: validateInput(input)
    });
  };

  function getField(input: InputState, key: string) {
    if (input.hidden) return null;
    if (input.type === FieldType.STRING) {
      return (
        <TextField
          disabled={input.disabled || hideSubmit}
          className={classes.inputField}
          id={key}
          name={key}
          label={!isQuestionnarie ? t(`form.${input.localizationKey}`) : undefined}
          variant='outlined'
          required={input.required}
          autoComplete='off'
          onChange={onInputChange}
          onBlur={onInputChange}
          value={input.value}
          error={input.error}
          helperText={
            input.error && !input.validationErrorMessage
              ? t('form.' + input.validationErrorKey)
              : input.validationErrorMessage
          }
          InputLabelProps={{
            shrink: true
          }}
        />
      );
    }

    if (input.type === FieldType.LONG_STRING) {
      return (
        <TextField
          disabled={input.disabled || hideSubmit}
          className={classes.inputField}
          id={key}
          name={key}
          label={!isQuestionnarie ? t(`form.${input.localizationKey}`) : undefined}
          variant='outlined'
          multiline
          minRows={4}
          required={input.required}
          autoComplete='off'
          onChange={onInputChange}
          onBlur={onInputChange}
          value={input.value}
          error={input.error}
          helperText={input.error && t('form.' + input.validationErrorKey)}
          InputLabelProps={{
            shrink: true
          }}
        />
      );
    }

    if (input.type === FieldType.SELECT) {
      const { options, defaultValue, disabled } = input as SelectInputState;

      if (defaultValue && !formState[key].value) {
        onSelectChange(key, String(defaultValue));
      }

      return (
        <FormControl
          className={classes.inputField}
          variant='outlined'
          required={input.required}
          error={input.error}
          disabled={disabled || hideSubmit}
        >
          <InputLabel shrink={true} id={`${key}-selector`}>
            {t(`form.${input.localizationKey}`)}
          </InputLabel>
          <Select
            name={key}
            labelId={`${key}-selector`}
            input={<OutlinedInput notched label={t(`form.${input.localizationKey}`)} />}
            inputProps={{ 'data-testid': `${key}-selector` }}
            value={input.value}
            onChange={(e: any) => onSelectChange(key, e.target.value)}
            onBlur={onInputChange}
            label={key}
          >
            {options?.map((opt) => (
              <MenuItem key={opt.value} value={String(opt.value)}>
                {opt.name}
              </MenuItem>
            ))}
          </Select>
          {input.error && <FormHelperText>{t('form.' + input.validationErrorKey)}</FormHelperText>}
        </FormControl>
      );
    }

    if (input.type === FieldType.FILE_DROPZONE) {
      return (
        <FileDropzone
          label={t(`form.${input.localizationKey}`)}
          info={t(`form.${input.infoLocalizationKey}`)}
          accept={
            {
              'image/*': ['.png', '.gif', '.jpeg', '.jpg']
            } as Accept
          }
          files={(formState[key].value as File[]) || []}
          onChange={onFileChange}
          onDelete={deleteImage}
        />
      );
    }
    if (input.type === FieldType.HTML_CONTENT) {
      return (
        <HtmlContentEditor
          name={key}
          initialValue={typeof input.value === 'string' ? input.value : ''}
          input={input as HtmlInputState}
          onChange={onHtmlContentChange}
          preview={(input as HtmlInputState).preview}
        />
      );
    }
    if (input.type === FieldType.NUMERIC) {
      return (
        <TextField
          type='number'
          disabled={input.disabled || hideSubmit}
          className={classes.inputField}
          id={key}
          name={key}
          label={!isQuestionnarie ? t(`form.${input.localizationKey}`) : undefined}
          variant='outlined'
          required={input.required}
          autoComplete='off'
          onChange={onInputChange}
          onBlur={onInputChange}
          value={input.value}
          error={input.error}
          helperText={
            input.error && !input.validationErrorMessage
              ? t('form.' + input.validationErrorKey)
              : input.validationErrorMessage
          }
          InputLabelProps={{
            shrink: true
          }}
        />
      );
    }

    if (input.type === FieldType.CHECKBOX) {
      return (
        <>
          <FormControlLabel
            control={
              <Checkbox
                disabled={input.disabled || hideSubmit}
                checked={
                  input.value
                    ? (input.value as any)
                    : input.booleanValue !== undefined
                    ? input.booleanValue
                    : false
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (input.id !== undefined)
                    setFormState({
                      ...formState,
                      [`${input.id + 2}`]: {
                        ...input,
                        booleanValue: event.target.checked
                      }
                    });
                }}
                color='primary'
              />
            }
            label={input.localizationKey}
          />
        </>
      );
    }
    if (input.type === FieldType.BOOLEAN) {
      const initialValue = input.value as any;
      const [value, setValue] = useState(hideSubmit ? !!initialValue : null);
      return (
        <RadioGroup
          style={{ marginTop: '20px' }}
          row
          aria-labelledby='radio-buttons-group'
          name='radio-buttons-group'
          defaultValue={value || (null as any)}
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (input.id !== undefined) {
              setValue(event.target.value === 'true');
              setFormState({
                ...formState,
                [`${input.id + 2}`]: {
                  ...input,
                  value: event.target.value
                }
              });
            }
          }}
        >
          <FormControlLabel
            style={{ fontSize: '0.875rem' }}
            disabled={hideSubmit}
            value={true}
            control={<Radio />}
            label={t('yes')}
          />
          <FormControlLabel
            style={{ fontSize: '0.875rem' }}
            disabled={hideSubmit}
            value={false}
            control={<Radio />}
            label={t('no')}
          />
        </RadioGroup>
      );
    }
  }

  const renderedGroupIds: number[] = [];
  return (
    <>
      {sectionState?.showBackButton && <BackButton callback={onBackButtonClick} />}
      <Grid className={classes.headingContainer}>
        <Typography ref={headingRef} variant='h5' style={{ fontWeight: 'bold' }}>
          {localizationOverrides && localizationOverrides?.title
            ? localizationOverrides.title
            : t(`form.${localizationKeys.title}`)}
        </Typography>
        {localizationOverrides && localizationOverrides?.description && (
          <Typography variant='body2' style={{ color: '#667085' }}>
            {localizationOverrides.description}
          </Typography>
        )}
      </Grid>

      {success && renderAlert(localizationKeys.submitSuccess, 'success')}
      {error && renderAlert(localizationKeys.submitError, 'error')}

      <form
        noValidate
        name='new-conversation-form'
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <br />

        {Object.keys(formState).map((key, index) => {
          if (!formState[key].groupId && !formState[key].hidden) {
            return (
              <div key={index} style={{ marginBottom: '2rem' }}>
                {formState[key].type !== FieldType.CHECKBOX && (
                  <>
                    <Typography variant='subtitle2'>{`${formState[key].localizationKey}${
                      formState[key].required ? '*' : ''
                    }`}</Typography>
                    <Typography variant='body2' style={{ marginBottom: '-15px', color: '#667085' }}>
                      {formState[key].additionalInfo}
                    </Typography>
                  </>
                )}
                {getField(formState[key], key)}
              </div>
            );
          } else {
            const group = fieldsWithGroup?.find((group) => group.id === formState[key].groupId);
            if (group && !renderedGroupIds.includes(group.id)) {
              renderedGroupIds.push(group.id);
              return (
                <>
                  <Typography
                    style={{ marginBottom: '1rem', fontWeight: 'bold' }}
                    variant='subtitle2'
                  >
                    {group.name}
                  </Typography>
                  <div
                    key={index}
                    style={{ paddingLeft: '1rem', borderLeft: '1px solid lightgray' }}
                  >
                    {Object.keys(formState).map((key, index) => {
                      if (formState[key].groupId && formState[key].groupId === group.id) {
                        return (
                          <div key={index} style={{ marginBottom: '2rem' }}>
                            <Typography variant='subtitle2'>{`${formState[key].localizationKey}${
                              formState[key].required ? '*' : ''
                            }`}</Typography>
                            <Typography
                              variant='body2'
                              style={{ marginBottom: '-15px', color: '#667085' }}
                            >
                              {formState[key].additionalInfo}
                            </Typography>
                            {getField(formState[key], key)}
                          </div>
                        );
                      }
                    })}
                  </div>
                </>
              );
            }
          }
        })}
        {!hideSubmit && (
          <div className={classes.buttonRow}>
            <Button
              type={
                appState.tenantSettings?.organisationId === 'srv'
                  ? ButtonType.contained
                  : ButtonType.outlined
              }
              caption={t(`form.action.${localizationKeys.submit}`)}
              onClick={submitClicked}
            >
              {submitting ? (
                <CircularProgress className='progress' style={{ padding: '8px' }} />
              ) : undefined}
            </Button>
          </div>
        )}
      </form>
    </>
  );
}
