import { AppContext } from 'context/App/AppContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import { useState, useEffect, useContext } from 'react';

interface ShowMessagingProps {
  messagingEnabled: boolean | null;
  newMessagingEnabled: boolean;
}

/**
 * useMessaging hook
 *
 * @returns - ShowMessagingProps
 */
export const useShowMessaging = (): ShowMessagingProps => {
  const { appState } = useContext(AppContext);
  const { projectState } = useContext(ProjectContext);
  const { tenantSettings } = appState;
  const [messagingEnabled, setMessagingEnabled] = useState<boolean | null>(null);
  const [newMessagingEnabled, setNewMessagingEnabled] = useState<boolean>(false);

  const { selectedProject } = projectState;

  useEffect(() => {
    if (
      !selectedProject ||
      !tenantSettings ||
      !projectState.projectSettings ||
      projectState.loadDetails
    )
      return;

    const newMessagingProjectSetting = projectState.projectSettings?.['useNewMessaging'];
    let newMessagingEnabled: boolean = tenantSettings.useNewMessaging as boolean;

    if (typeof newMessagingProjectSetting === 'boolean')
      newMessagingEnabled = newMessagingProjectSetting as boolean;

    setMessagingEnabled(tenantSettings.useMessaging as boolean);
    setNewMessagingEnabled(newMessagingEnabled);
  }, [selectedProject?.id, projectState.loadDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  return { messagingEnabled, newMessagingEnabled };
};
