import { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import styles from './styles';
import 'react-quill/dist/quill.snow.css';
import { FormHelperText } from '@material-ui/core';
import { HtmlInputState } from 'components/Form/interfaces';
import useT from 'hooks/useT';
import useGetPreviewTags from 'hooks/useGetPreviewTags';
import PlainClipboard, {
  previewIdentifier,
  PreviewElement,
  attachmentIdentifier,
  CustomImage,
  CustomVideo
  // colorPickerIdentifier,
  // ColorPickerElement
} from './CustomElement';
import AttachmentHandler from './AttachmentHandler';
import { Attachment } from 'components/Sections/Section';
import useQuillHelper from 'hooks/useQuillHelper';
// import ColorPickerHandler from './ColorPickerHandler';
import BlotFormatter from 'quill-blot-formatter';

export interface EditorProps {
  name: string;
  input: HtmlInputState;
  // eslint-disable-next-line
  onChange: (content: string, attachment?: Attachment[]) => void;
  initialValue?: string;
  preview?: string;
}

Quill.register(PreviewElement);
// Quill.register('modules/clipboard', ColorPickerElement, true);
Quill.register('modules/clipboard', PlainClipboard, true);
const Size = Quill.import('attributors/style/size');
const Font = Quill.import('formats/font');
Quill.register(Size, true);
Quill.register(Font, true);
Size.whitelist = ['12px', '14px', '16px', '18px', '20px', '24px', '32px', '48px'];
Font.whitelist = [
  'Helvetica',
  'Roboto',
  'Arial',
  'Ubuntu',
  'OpenSans',
  'Montserrat',
  'Lato',
  'Poppins',
  'Inter'
];
Quill.register('modules/blotFormatter', BlotFormatter);

Quill.register({
  // ... other formats
  'formats/image': CustomImage
});
Quill.register({
  // ... other formats
  'formats/video': CustomVideo
});

export default function HtmlContentEditor(props: EditorProps) {
  const { input, initialValue, preview } = props;
  const [value, setValue] = useState<string>(initialValue || '');
  const quill = useRef<ReactQuill>(null);
  const attachmentInputRef = useRef<HTMLInputElement>(null);
  // const colorPickerInputRef = useRef<HTMLInputElement>(null);
  const classes = styles();
  const t = useT();
  const { endPreviewTextWithTags } = useGetPreviewTags();
  const quillHelper = useQuillHelper(quill);

  const togglePreview = () => {
    const previewElement = document.querySelector('.' + previewIdentifier) as HTMLElement;

    if (previewElement) {
      previewElement.parentElement?.remove();
      return;
    }

    quillHelper.insertHtml(`${endPreviewTextWithTags}<br>`, quillHelper.getCurrentCaretPosition());
  };

  const onAttachmentIconClick = () => {
    if (attachmentInputRef && attachmentInputRef.current) attachmentInputRef.current.click();
  };

  // const onColorPickerIconClick = () => {
  //   if (colorPickerInputRef && colorPickerInputRef.current) colorPickerInputRef.current.click();
  // };

  const insertInitialPreviewText = () => {
    if (!preview || document.querySelector('.' + previewIdentifier)) return;

    const previewIndex = value.indexOf(preview) + preview.length;
    const valueWithPreviewText =
      value.slice(0, previewIndex) + endPreviewTextWithTags + value.slice(previewIndex);

    onChange(valueWithPreviewText);
  };

  const onChange = (content: string) => {
    setValue(content);
    props.onChange(content);
  };

  // memo is used, so that react-quill does not loose it state in re-render(s)
  const modules = useMemo(
    () => ({
      blotFormatter: {
        // see config options below
      },
      toolbar: {
        container: [
          [
            { header: [false, 2, 3, 4] },
            {
              font: [
                'Helvetica',
                'Roboto',
                'Arial',
                'Ubuntu',
                'OpenSans',
                'Montserrat',
                'Lato',
                'Poppins',
                'Inter'
              ]
            },
            { size: ['12px', '14px', '16px', '18px', '20px', '24px', '32px', '48px'] },
            // colorPickerIdentifier,
            'bold',
            'italic',
            'underline'
          ],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link', 'image', 'video', attachmentIdentifier],
          [previewIdentifier]
        ],
        handlers: {
          preview: togglePreview,
          attachment: onAttachmentIconClick
          // colorpicker: onColorPickerIconClick
        }
      }
    }),
    []
  );

  useEffect(() => {
    // clearing textarea after submit
    if (!input.value || !input.value.length) {
      setValue('');
    }
  }, [input.value]);

  useEffect(() => {
    insertInitialPreviewText();
  }, []);

  return (
    <div className={classes.container}>
      <AttachmentHandler
        ref={attachmentInputRef}
        quill={quill}
        attachments={input.attachments as Attachment[]}
        onChange={(attachments) => {
          props.onChange(quillHelper.getContent(), attachments);
        }}
      />
      {/* <ColorPickerHandler
        ref={colorPickerInputRef}
        quill={quill}
        onChange={(_color: string) => {
          console.log(_color);
        }}
      /> */}
      <ReactQuill
        theme='snow'
        modules={modules}
        value={value}
        placeholder={t('form.htmlContentEditor.placeholder')}
        onChange={onChange}
        className={`${classes.editor} ${input.error ? 'error' : ''}`}
        ref={quill}
      />

      {input.error && (
        <FormHelperText className={`${classes.errorText} Mui-error`}>
          {input.validationErrorMessage
            ? input.validationErrorMessage
            : t('form.validation.' + input.validationErrorKey)}
        </FormHelperText>
      )}
    </div>
  );
}
