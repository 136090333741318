import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0.5rem 0',
      paddingLeft: '0.2rem',
      paddingRight: '0.2rem',
      marginBottom: '1rem',
      width: '100%',
      border: '1px solid #EAECF0',
      boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
      borderRadius: '8px',
      '&:hover': {
        backgroundColor: theme.palette.grey[200]
      }
    },
    eventDetailContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,
      padding: '0px',
      paddingRight: '0.4rem',
      paddingLeft: '0.4rem'
    },
    eventDetailNarrow: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'start'
    },
    descriptionContainer: {
      paddingTop: '0.1rem',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      maxWidth: '100%'
    },
    descriptionContainerColumn: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      maxWidth: '100%'
    },
    descriptionTitle: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.black,
      fontSize: theme.typography.body2.fontSize,
      margin: '0px'
    } as CSSProperties,
    descriptionDescription: {
      color: theme.palette.common.black,
      fontSize: theme.typography.subtitle2.fontSize,
      overflowWrap: 'break-word',
      marginBottom: '5px'
    },
    captionDescription: {
      color: theme.palette.common.black,
      fontSize: theme.typography.caption.fontSize,
      overflowWrap: 'break-word'
    },
    descriptionRightBottom: {
      fontWeight: theme.typography.fontWeightRegular,
      textAlign: 'right',
      color: theme.palette.common.black,
      fontSize: theme.typography.overline.fontSize,
      overflowWrap: 'break-word'
    } as CSSProperties,
    captionDescriptionRightBottom: {
      fontWeight: theme.typography.fontWeightRegular,
      textAlign: 'right',
      color: theme.palette.common.black,
      fontSize: theme.typography.caption.fontSize,
      overflowWrap: 'break-word'
    } as CSSProperties,
    textLeftAligned: {
      margin: '4px 0',
      textAlign: 'left',
      fontWeight: theme.typography.fontWeightRegular
    } as CSSProperties,
    eventColumn: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start'
    },
    eventColumnNarrow: {
      justifyContent: 'left',
      alignItems: 'start',
      marginTop: '10px',
      padding: 0
    },
    eventColumnBottom: {
      marginTop: 'auto'
    }
  });
});
