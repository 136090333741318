import { DeadlineType } from './../components/Header/Deadlines/types';
import { getDeadlines } from 'axiosInstances';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from 'context/App/AppContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';

const useGetDeadlines = () => {
  const [deadlines, setDeadlines] = useState<DeadlineType[]>([]);
  const { appState } = useContext(AppContext);
  const { projectState } = useContext(ProjectContext);
  const { apartmentState } = useContext(ApartmentContext);
  const { selectedProject } = projectState;
  const { selectedApartment } = apartmentState;

  const fetch = async () => {
    if (!selectedApartment || projectState.loadDetails || apartmentState.loadDetails) return;

    try {
      const { organisationId, tenantId } = appState.startParameters;

      const deadlineData = await getDeadlines({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        gbToken: appState.token as string,
        projectId: String(selectedProject?.id),
        apartmentId: String(selectedApartment.id)
      });

      setDeadlines(deadlineData.calendarEvents);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetch();
  }, [apartmentState.loadDetails]); // eslint-disable-line

  return deadlines;
};

export default useGetDeadlines;
