import styles from './styles';
import { Grid, Typography } from '@material-ui/core';
import ChecklistAccordions from './ChecklistAccordions/ChecklistAccordions';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BackButton from 'components/BackButton/BackButton';
import CreateChecklistItem from './CreateChecklistItem/CreateChecklistItem';
import useT from 'hooks/useT';
import { ProjectContext } from 'context/Project/ProjectContext';
import { SectionContext } from 'context/Section/SectionContext';
import { UserRight } from 'context/App/types';
import { AppContext } from 'context/App/AppContext';
import ChecklistSection from './ChecklistSection';
import { Button, ButtonType, IconKeys } from '@groupbuilderoy/gb-components-library';

interface Props {
  section: ChecklistSection;
}

const ChecklistPage = ({ section }: Props) => {
  const t = useT();
  const classes = styles();
  const [createNew, setCreateNew] = useState(false);
  const history = useHistory();
  const { projectState } = useContext(ProjectContext);
  const { appState } = useContext(AppContext);
  const { sectionState, dispatch } = useContext(SectionContext);

  useEffect(() => {
    dispatch({ ...sectionState, showBackButton: false });

    return () => {
      dispatch({ ...sectionState, showBackButton: true });
    };
  }, []); // eslint-disable-line

  const toggleCreateNew = () => {
    setCreateNew(!createNew);
  };

  const back = () => {
    if (!createNew) history.goBack();
    else toggleCreateNew();
  };

  const isCreateNewButtonVisible = () => {
    if (
      projectState.loading ||
      !projectState.projectSettings ||
      createNew ||
      !appState.newItemInspectionType
    )
      return false;

    const { projectSettings, userRights } = projectState;

    return (
      projectSettings['addingChecklistItemsEnabled'] && userRights?.check(UserRight.checklistUpdate)
    );
  };

  return (
    <Grid container direction='column'>
      <Grid container direction='row' className={classes.topButtonRow}>
        <BackButton customClickHandler={back} />

        {isCreateNewButtonVisible() && (
          <Button
            caption={t('checklist.createNew.buttonLabel')}
            className={classes.createNewButton}
            type={
              appState.tenantSettings?.organisationId === 'srv'
                ? ButtonType.contained
                : ButtonType.outlined
            }
            color='primary'
            startIcon={IconKeys.add}
            onClick={() => toggleCreateNew()}
          />
        )}
      </Grid>
      {!createNew && (
        <Grid className={classes.headingContainer}>
          <Typography variant='h5'>{section.name}</Typography>

          {section.description && (
            <Typography className={classes.subtitle}>{section.description}</Typography>
          )}
        </Grid>
      )}

      <Grid item className={classes.content}>
        {createNew ? <CreateChecklistItem /> : <ChecklistAccordions section={section} />}
      </Grid>
    </Grid>
  );
};

export default ChecklistPage;
