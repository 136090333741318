import { AxiosResponse } from 'axios';
import { instance } from 'axiosInstances';
import { Contact } from 'hooks/useGetContacts';
import { IssueState } from './CheckListResponse';
import { AttachmentsResponse, BaseResponse } from './types';
import { createConfig, createPrivatePath } from './utils';
import { ISection } from 'components/Sections/Section';

interface AttachmentParams {
  gbToken: string;
  organisationId: string;
  tenantId: string;
  projectId: string;
}

interface FetchAttachmentsParams extends AttachmentParams {
  sectionId: string;
  apartmentId: string;
  limit?: number;
}

interface UpdateAttachmentsParams extends AttachmentParams {
  attachmentId: string;
  xfdf: string;
}

export const fetchAttachments = async ({
  gbToken,
  organisationId,
  tenantId,
  projectId,
  apartmentId,
  sectionId,
  limit
}: FetchAttachmentsParams): Promise<AttachmentsResponse> => {
  let path =
    createPrivatePath({ organisationId, tenantId, projectId }) +
    `/attachments/sections/${sectionId}?apartmentId=${apartmentId}`;

  if (limit) path += `&limit=${limit}`;

  const { data } = await instance.get(path, createConfig({ token: gbToken }));

  if (data.error) throw new Error('Unable to fetch attachments!');

  return data;
};

export const signDocument = async ({
  gbToken,
  organisationId,
  tenantId,
  projectId,
  attachmentId,
  xfdf
}: UpdateAttachmentsParams): Promise<AttachmentsResponse> => {
  const path =
    createPrivatePath({ organisationId, tenantId, projectId }) +
    `/attachments/${attachmentId}/sign`;

  const { data } = await instance.put(path, { xfdf }, createConfig({ token: gbToken }));

  if (data.error) throw new Error('Unable to update signature!');

  return data;
};

interface FetchChecklistParams {
  token: string;
  organisationId: string;
  tenantId: string;
  projectId: string;
  apartmentId: string;
  sectionId: number;
}

export const fetchChecklist = async ({
  token,
  organisationId,
  tenantId,
  projectId,
  apartmentId,
  sectionId
}: FetchChecklistParams) => {
  try {
    const response = (await instance.get(
      createPrivatePath({ organisationId, tenantId, projectId, apartmentId }) +
        `/sections/${sectionId}/checklist`,
      createConfig({ token })
    )) as BaseResponse;

    const { data, error } = response.data;

    if (error || !data) throw new Error('Unable to fetch checklist for the section: ' + sectionId);

    return data;
  } catch (e) {
    throw e;
  }
};

interface FetchContactsParams {
  organisationId: string;
  tenantId: string;
  projectId: string;
  gbToken: string;
}
interface FetchSectionFoldersParams {
  organisationId: string;
  tenantId: string;
  gbToken: string;
  parentSectionId: string;
}

interface ContactSection {
  contacts?: Contact[];
  id: number;
}
interface ContactPhase {
  sections?: ContactSection[];
  id: number;
}

interface FetchContactsResponse {
  data: {
    phases: ContactPhase[];
  };
}

interface ChecklistItem {
  name: string;
  description: string;
  newStatus: string;
  issueType: number;
  inspectionType: number;
  images?: File[] | null;
}

export const fetchContacts = ({
  organisationId,
  tenantId,
  projectId,
  gbToken
}: FetchContactsParams): Promise<AxiosResponse<FetchContactsResponse>> =>
  instance.get(
    `v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/cj/contacts`,
    createConfig({ token: gbToken })
  );

export const fetchFolderSections = async ({
  organisationId,
  tenantId,
  gbToken,
  parentSectionId
}: FetchSectionFoldersParams): Promise<ISection[]> => {
  const response = (await instance.get(
    `v6/private/organisations/${organisationId}/tenants/${tenantId}/sections?parentSectionId=${parentSectionId}`,
    createConfig({ token: gbToken })
  )) as BaseResponse;
  if (response.error) throw new Error('Failed to fetch section items');
  return response.data.data;
};

export const postChecklistItem = async (token: string, roomId: string, item: ChecklistItem) => {
  try {
    const response = (await instance.post(
      createPrivatePath({}, 'v3') + `/rooms/${roomId}/checklist`,
      createFormDataForChecklistItemPost(item),
      createConfig({
        token,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    )) as BaseResponse;

    if (response.error) throw new Error('Failed to post checklist item');

    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const approveChecklistItem = async (token: string, roomId: number, itemId: number) => {
  try {
    const response = (await instance.put(
      createPrivatePath({}, 'v3') + `/rooms/${roomId}/checklist/${itemId}/status`,
      { newStatus: IssueState.APPROVED },
      createConfig({ token })
    )) as BaseResponse;
    if (response.error) throw new Error('Failed to update checklist item status');
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

const createFormDataForChecklistItemPost = (item: ChecklistItem) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(item)) {
    if (key === 'images') {
      value.forEach((image: File) => {
        formData.append('images', image);
      });
    } else formData.append(key, value);
  }
  return formData;
};
