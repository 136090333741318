import { instance } from 'axiosInstances';
import { ProjectSettingProperties, ProjectSettings } from 'context/Project/interfaces';
import { createConfig, createPrivatePath } from './utils';

export const getProjectSettings = async (
  token: string,
  organisationId: string,
  tenantId: string,
  projectId: string
) => {
  try {
    const { data } = await instance.get(
      createPrivatePath({ organisationId, tenantId, projectId }) + '/settings',
      createConfig({ token })
    );

    if (!data.data?.settings) throw new Error('Failed to get project settings');

    const projectSettings: ProjectSettings = {};
    for await (const [key, value] of Object.entries(data.data?.settings)) {
      const settingProperties = value as ProjectSettingProperties;
      projectSettings[key] = settingProperties.value;
    }

    return projectSettings;
  } catch (e: any) {
    throw new Error(e);
  }
};
