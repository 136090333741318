import { Grid, ListItem, Typography } from '@material-ui/core';
import useStyles from './styles';
import { Avatar } from '@groupbuilderoy/gb-components-library';
import { Contact } from 'hooks/useGetContacts';

const ContactListItem: React.FC<Contact> = ({
  name,
  description,
  phoneNumber,
  email,
  companyName,
  jobTitle,
  imageUrlSmall,
  streetAddress,
  city,
  postalCode,
  state,
  country,
  link
}: Contact) => {
  const classes = useStyles();
  const handleClick = () => {
    if (link) {
      window.open(link, '_blank');
    }
  };
  const details = [jobTitle, companyName, streetAddress, city, postalCode, state, country];
  const parsedDetails = details.map((d) => {
    if (d) {
      return d;
    }
    return '';
  });

  const links = [];
  if (phoneNumber) links.push(phoneNumber);
  if (email) links.push(email);

  return (
    <ListItem onClick={handleClick} className={`${classes.root} ${link ? 'link' : ''}`}>
      <Grid container direction='row' spacing={2} alignItems='center' wrap='nowrap'>
        <Grid item>
          <Avatar
            imgSrc={imageUrlSmall || undefined}
            name={name || ''}
            data={parsedDetails}
            links={links}
            imageSize={80}
          />
          {!!description && <Typography className={classes.description}>{description}</Typography>}
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default ContactListItem;
