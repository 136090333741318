import ReactGA4 from 'react-ga4';
import { EventAction } from './EventAction';
import { EventCategory } from './EventCategory';

export interface GAEvent {
  action: EventAction | string;
  category: EventCategory;
  label?: string;
}

export const initializeGA = () => {
  ReactGA4.initialize('G-X540L6L6VJ');
  ReactGA4.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
    title: 'Page'
  });
};
