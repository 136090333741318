import BackButton from 'components/BackButton/BackButton';
import { Dispatch, useContext, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import useStyles from './styles';
import { IconKeys, StyledPaper } from '@groupbuilderoy/gb-components-library';
import { SectionContext } from 'context/Section/SectionContext';
import { ISection, SectionType } from 'components/Sections/Section';
import EditableContent from 'components/EditableContent/EditableContent';
import useT from 'hooks/useT';
import { useEditingMode } from 'hooks/useEditingMode';
import CreateSection from 'routes/CreateSection/CreateSection';
import EditSection from 'routes/EditSection/EditSection';
import useApi from 'hooks/useApi';
import { SectionWithContent, createSectionsWithContent } from 'utils/sections';
import { CustomerJourneyApi } from 'services/CustomerJourneyApi';
import { SectionState } from 'context/Section/interfaces';

const editableSections = [SectionType.CUSTOM_CONTENT];

const getCurrentSection = async (
  id: string,
  api: CustomerJourneyApi,
  dispatch: Dispatch<SectionState>,
  sectionState: SectionState
) => {
  const sectionData = await api.getSection(Number(id), SectionType.FOLDER);
  const currentSections = createSectionsWithContent([
    sectionData
  ] as ISection[]) as SectionWithContent[];
  const currentSection = currentSections[0];
  const updatedSections = [currentSection].filter(
    (newSection) =>
      !sectionState.sections.some((existingSection) => existingSection.id === newSection.id)
  );
  dispatch({
    ...sectionState,
    loading: false,
    update: false,
    sections: [...updatedSections, ...sectionState.sections]
  });
  return currentSection;
};

export default function SectionPage() {
  const t = useT();
  const api = useApi();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { editingModeEnabled } = useEditingMode();
  const classes = useStyles();
  const urlSegments = url.split('/');
  const sectionId = urlSegments[urlSegments.length - 1];
  const { sectionState, dispatch } = useContext(SectionContext);

  // const editPath = `${basePath}/phase/${currentSection?.phaseId}/section/${currentSection?.id}/edit`;
  const editPath = `${url}/edit`;

  const [currentSection, setCurrentSection] = useState<SectionWithContent | undefined>(
    sectionState.sections?.find((section) => String(section.id) === sectionId)
  );

  useEffect(() => {
    const fetchCurrentSection = async () => {
      let section = sectionState.sections?.find((section) => String(section.id) === sectionId);
      if (!section) {
        section = await getCurrentSection(sectionId, api, dispatch, sectionState);
      }
      setCurrentSection(section);
    };
    fetchCurrentSection();
  }, [sectionId, dispatch, sectionState]);

  if (!currentSection?.page) return null;

  const Section = (
    <StyledPaper style={{ width: '100%' }}>
      <div className={classes.section}>
        {sectionState.showBackButton && <BackButton />}
        {currentSection.page}
      </div>
    </StyledPaper>
  );
  const editableSection = (
    <EditableContent
      editCaption={t('edit')}
      iconKey={IconKeys.edit}
      onEdit={() => {
        history.push(editPath);
      }}
    >
      {Section}
    </EditableContent>
  );

  const SectionWithRoutes = (
    <Switch>
      {editingModeEnabled && <Route path={`${url}/create`} component={CreateSection} />}
      {editingModeEnabled && <Route path={`${url}/edit`} component={EditSection} />}
      <Route path={`${url}/:subSectionId`} component={SectionPage} />
      <Route path={url} key={sectionId}>
        {currentSection?.type && editableSections.includes(currentSection.type)
          ? editableSection
          : Section}
      </Route>
    </Switch>
  );
  return SectionWithRoutes;
}
