import { previewIdentifier } from 'components/HtmlContent/CustomElement';
import useT from './useT';

export const getEndPreviewText = (localizedText: string) =>
  `--------------------${localizedText}--------------------`;
export const getEndPreviewTextWithTags = (text: string) =>
  `<p><div class="${previewIdentifier}" contenteditable="false">${text}</div></p>`;

const useGetPreviewTags = () => {
  const t = useT();

  const endPreviewText = getEndPreviewText(t('endPreview'));
  const endPreviewTextWithTags = getEndPreviewTextWithTags(endPreviewText);
  const appendCustomContent = (content: string, tag: string) => {
    return String(content).replace(endPreviewTextWithTags, tag);
  };

  return {
    endPreviewText,
    endPreviewTextWithTags,
    appendCustomContent
  };
};

export default useGetPreviewTags;
