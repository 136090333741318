import { DialogType, EventType, CalendarReservation } from '../../interfaces';
import CalendarEventDialog from '../CalendarEventDialogs/CalendarEventDialog';
import BookAppointmentDialog from '../CalendarEventDialogs/BookAppointmentDialog';

interface EventDialogDisplayProps {
  event: EventType;
  openDialog: DialogType | null;
  userReservation: CalendarReservation | null;
  handleCloseDialog: () => void;
  addNewReservation: any;
  updateExistingReservation: any;
  deleteExistingReservation: any;
}

const EventDialogDisplay = ({
  event,
  openDialog,
  userReservation,
  handleCloseDialog,
  addNewReservation,
  updateExistingReservation,
  deleteExistingReservation
}: EventDialogDisplayProps) => {
  if (
    (openDialog === DialogType.Edit_Registration || openDialog === DialogType.Edit_Appointment) &&
    userReservation
  ) {
    return (
      <CalendarEventDialog
        openDialog={true}
        dialogType={openDialog}
        handleCloseDialog={handleCloseDialog}
        event={event}
        userReservation={userReservation}
        updateReservationHandling={updateExistingReservation}
        deleteReservationHandling={deleteExistingReservation}
      />
    );
  } else if (openDialog === DialogType.Add_Appointment) {
    return (
      <BookAppointmentDialog
        openDialog={true}
        dialogType={openDialog}
        handleCloseDialog={handleCloseDialog}
        event={event}
        addNewReservation={addNewReservation}
      />
    );
  } else if (openDialog === DialogType.Add_Registration) {
    return (
      <CalendarEventDialog
        openDialog={true}
        handleCloseDialog={handleCloseDialog}
        dialogType={openDialog}
        event={event}
        addNewRegistrationHandling={addNewReservation}
      />
    );
  } else {
    return <></>;
  }
};

export default EventDialogDisplay;
