import { getCalendarEvents } from 'axiosInstances';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from 'context/App/AppContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import { CalendarSection } from 'components/Sections/Calendar/interfaces';
import { CalendarEventsContext } from 'context/CalendarEvents/CalendarEventsContext';

const useGetCalendarEventsSection = (sectionId: string) => {
  const { appState } = useContext(AppContext);
  const { projectState } = useContext(ProjectContext);
  const { apartmentState } = useContext(ApartmentContext);
  const { selectedProject } = projectState;
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { calendarEventsState, dispatch } = useContext(CalendarEventsContext);

  const fetch = async () => {
    if (!sectionId || projectState.loading || apartmentState.loading) return;

    try {
      const { organisationId, tenantId } = appState.startParameters;
      if (!appState.token) {
        return;
      }
      setLoading(true);
      let user = calendarEventsState.user;
      if (!user) {
        user = await getUser();
      }
      const response = await getCalendarEvents({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        gbToken: appState.token as string,
        projectId: String(selectedProject?.id),
        sectionId: String(sectionId),
        apartmentId: String(apartmentState.selectedApartment?.id)
      });

      const calendarEventsSection: CalendarSection = {
        name: 'name placeholder',
        description: 'desc placeholder',
        events: response['calendarEvents']
      };

      dispatch({
        ...calendarEventsState,
        CalendarEventsSection: calendarEventsSection as CalendarSection,
        user: user
      });
    } catch (e) {
      setError(true);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getUser = async () => {
    if (appState.token) {
      try {
        const user = appState.user;
        return user;
      } catch (e) {
        setError(true);
        console.error(e);
      }
    }
  };

  useEffect(() => {
    fetch();
  }, [apartmentState.loadDetails]); // eslint-disable-line

  return {
    loading,
    error
  };
};

export default useGetCalendarEventsSection;
