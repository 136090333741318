import useStyles from './styles';

interface ListItemTagProps {
  text: string;
}

export const ListItemTag: React.FC<ListItemTagProps> = ({ text }: ListItemTagProps) => {
  const classes = useStyles();

  return (
    <div className={`${classes.tag} tag`}>
      <span className={classes.text}>{text}</span>
    </div>
  );
};
