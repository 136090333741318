import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    body: {
      color: 'white',
      margin: 0,
      fontFamily: 'Helvetica Neue, sans-serif',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    },
    container: {
      margin: '2rem auto'
    },
    iframeContainer: {
      margin: 0,
      padding: 0,
      maxWidth: '100%',
      width: '100%',
      minHeight: '100%',
      height: 'calc(100vh - 72px - 3rem)'
    },
    selectionsFrameContainer: {
      margin: 0,
      padding: 0,
      maxWidth: '100%',
      width: '100%',
      minHeight: '100%',
      height: 'calc(100vh - 72px)',
      background: '#fafafa'
    },
    alert: {
      margin: '20px'
    }
  })
);
