import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import useStyles from './styles';
import { getDateFnsLocale } from 'i18n';

export interface SquareEventPropsType {
  startDateTimeString?: string;
  millisecondsTime?: number;
  endDateTimeString?: string;
  showTime?: boolean;
  showYear?: boolean;
  color?: string;
  textColor?: string;
  tableCell?: boolean;
  borderRadius?: string;
}

export default function DateSquare({
  color = 'primary',
  textColor,
  showTime,
  showYear,
  startDateTimeString,
  millisecondsTime,
  endDateTimeString,
  tableCell = false,
  borderRadius
}: SquareEventPropsType) {
  const classes = useStyles({ color, textColor, tableCell, borderRadius });
  const date = startDateTimeString ?? (millisecondsTime ?? 0) * 1000;
  const startDate = date ? new Date(date) : new Date();
  const endDate = endDateTimeString ? new Date(endDateTimeString) : undefined;

  // Not translated because this was supposed to change?
  return (
    <div className={classes.eventSquare}>
      <Typography className={classes.day}>{format(startDate, 'dd')}</Typography>
      <Typography className={classes.month}>
        {format(startDate, 'MMMM', { locale: getDateFnsLocale() })}
      </Typography>
      {showTime && (
        <Typography className={classes.time}>{`${format(startDate, 'H:mm')}${
          endDate ? '-'.concat(format(endDate, 'H:mm')) : ''
        }`}</Typography>
      )}
      {showYear && <Typography className={classes.time}>{format(startDate, 'yyyy')}</Typography>}
    </div>
  );
}
