import useT from 'hooks/useT';
import { useContext } from 'react';
import { logout } from 'utils/utils';
import { AppContext } from 'context/App/AppContext';
import { UserMenu } from '@groupbuilderoy/gb-components-library';
import StartParameter from 'utils/startParameters';
import { useEditingMode } from 'hooks/useEditingMode';
import { useHistory } from 'react-router-dom';
import { UserRight } from 'context/App/types';
import { ProjectContext } from 'context/Project/ProjectContext';

export default function User() {
  const t = useT();
  const { appState } = useContext(AppContext);
  const { projectState } = useContext(ProjectContext);
  const { editingModeAvailable, editingModeEnabled, toggleEditingMode } = useEditingMode();
  const history = useHistory();

  const handleLogout = () => {
    logout(appState.startParameters.organisationId);
  };

  const handleToggleEditingMode = () => {
    sessionStorage.setItem(StartParameter.editingMode, String(!editingModeEnabled));
    toggleEditingMode();
  };

  const handleNavigateToUserDetails = () => {
    history.push('/userdetails');
  };

  const handleNavigateToOwnerDetails = () => {
    history.push('/ownersDetails');
  };

  const handleNavigateToOwnerSearch = () => {
    history.push('/ownersearch');
  };

  const links = [
    { text: t('user.logout'), onClick: handleLogout },
    { text: t('user.userDetailsLink'), onClick: handleNavigateToUserDetails }
  ];
  if (projectState.userRights?.check(UserRight.userSearch)) {
    links.push({ text: t('user.ownersDetails'), onClick: handleNavigateToOwnerDetails });
    links.push({ text: t('user.ownerSearch'), onClick: handleNavigateToOwnerSearch });
  }
  if (editingModeAvailable) {
    links.push({
      text: t('user.editingMode', { state: editingModeEnabled ? t('state.on') : t('state.off') }),
      onClick: handleToggleEditingMode
    });
  }

  return (
    <>
      <p>{editingModeAvailable}</p>
      <UserMenu data-testid='user-menu' name={appState.user?.name} links={links} />
    </>
  );
}
