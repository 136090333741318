import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(5)
  },
  formContainer: {
    marginBottom: theme.spacing(5)
  },
  textField: {
    marginBottom: theme.spacing(5)
  },
  fileDropzone: {
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(5),
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      marginTop: theme.spacing(2)
    }
  },
  uploadIcon: {
    fontSize: 48,
    marginBottom: theme.spacing(5)
  },
  fileList: {
    marginTop: theme.spacing(5)
  },
  listItem: {
    marginBottom: theme.spacing(5)
  },
  profilePicture: {
    width: '120px',
    height: 'auto',
    borderRadius: '50%',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(2)
    }
  },
  header: {
    color: theme.palette.sectionsHeader?.main
  },
  countryMenuItem: {
    width: '300px'
  }
}));

export default useStyles;
