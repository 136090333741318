import useT from 'hooks/useT';
import { FormsAccordion } from '@groupbuilderoy/gb-components-library';
import { useContext } from 'react';
import useStyles from './styles';
import SectionWrapper from 'components/Sections/SectionWrapper/SectionWrapper';
import FormSection from '../FormSection';
import { IssueState } from 'axiosInstances/instances/CheckListResponse';
import { format } from 'date-fns';
import useGetForms from 'hooks/useGetForms';
import { FormsContext } from 'context/Forms/FormsContext';
import { SectionContext } from 'context/Section/SectionContext';

interface Props {
  section: FormSection;
}

export default function ChecklistAccordiºs({ section }: Props) {
  const classes = useStyles();
  const { sectionState } = useContext(SectionContext);
  const { loading, error } = useGetForms(sectionState.sections[0].id.toString());
  const { formsState, dispatch } = useContext(FormsContext);
  const t = useT();

  const getAccordionTableLabels = () => {
    const labels = ['details', 'created', 'lastEvent'];
    const localizedLabels = {} as any;

    labels.forEach((label) => {
      localizedLabels[label] = t('forms.accordions.' + label);
    });
    return localizedLabels;
  };

  return (
    <SectionWrapper loading={loading} error={error} section={section}>
      {formsState.formsSection?.forms.map((item: any, index: number) => {
        return (
          <FormsAccordion
            isExpanded={true}
            type={'outlined' as any}
            key={index}
            className={classes.accordion}
            data-testid='accordion'
            caption={item.name}
            description={item.description}
            items={item.filledForms.map((form: any) => {
              return {
                ...form,
                description: undefined,
                created: format(new Date(form.createdOn), 'dd/MM/yyyy, HH:mm'),
                lastEvent: format(new Date(form.updatedOn), 'dd/MM/yyyy, HH:mm')
              };
            })}
            status={IssueState.NO_ACTION_NEEDED}
            colorOverride={'#f7f7f7'}
            labels={getAccordionTableLabels()}
            onRowClick={(_rowData, rowMeta) => {
              const formType = formsState.formsSection?.forms[index];
              dispatch({
                ...formsState,
                selectedFormType: formType || null,
                selectedForm: formType?.filledForms[rowMeta.dataIndex] || null
              });
            }}
            createFormProps={{
              caption: t('forms.accordions.createForm', { formName: item.name }),
              onClick: (e: any) => {
                e.stopPropagation();
                dispatch({
                  ...formsState,
                  selectedFormType: item,
                  creatingNewForm: true
                });
              }
            }}
            noItemsText={t('forms.accordions.noItems')}
          />
        );
      })}
    </SectionWrapper>
  );
}
