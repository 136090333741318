import en from "./en/main.json";
import en_GB from "./en_GB/main.json";
import fi from "./fi/main.json";
import nl from "./nl/main.json";
import fr from "./fr/main.json";
import de from "./de/main.json";
import sv from "./sv/main.json";
import ru from "./ru/main.json";
import nb from "./nb/main.json";
import ar from "./ar/main.json";
import es from "./es/main.json";
import et from "./et/main.json"

const languages = { en, en_GB, fi, nb, nl, fr, de, sv, ru, ar, es, et };

export default languages;
