import { ISection } from 'components/Sections/Section';
import { PhaseContext } from 'context/Phase/PhaseContext';
import { SectionContext } from 'context/Section/SectionContext';
import { useContext, useEffect } from 'react';
import { createSectionsWithContent, SectionWithContent } from 'utils/sections';
import useApi from './useApi';
import useT from './useT';

const useSetupSections = () => {
  const { phaseState } = useContext(PhaseContext);
  const { sectionState, dispatch } = useContext(SectionContext);
  const api = useApi();
  const t = useT();

  const fetch = async () => {
    if (!phaseState.selectedPhase) return;
    try {
      dispatch({
        ...sectionState,
        loading: true
      });

      const sections = await api.getAllSections(phaseState.selectedPhase.id);

      let settings = sectionState.settings;
      if (!settings) settings = await api.getSettings();

      dispatch({
        ...sectionState,
        loading: false,
        update: false,
        sections: createSectionsWithContent(sections as ISection[]) as SectionWithContent[],
        settings
      });
    } catch (e) {
      console.error(e);
      dispatch({
        ...sectionState,
        error: t('alert.sectionsFetchFailed')
      });
    }
  };

  useEffect(() => {
    fetch();
  }, [phaseState.selectedPhase?.id]); // eslint-disable-line

  useEffect(() => {
    if (!sectionState.update) return;

    fetch();
  }, [sectionState.update]); // eslint-disable-line
};

export default useSetupSections;
