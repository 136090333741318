import { HtmlInputState, InputState } from 'components/Form/interfaces';
import { SectionSettings } from 'components/Sections/Section';
import { SectionContext } from 'context/Section/SectionContext';
import { useContext } from 'react';
import { contentToHtmlElement, formatBytes } from 'utils/sections';
import useT from './useT';

const EMPTY_HTML_CONTENT = '<p><br></p>';

const useValidation = () => {
  const t = useT();
  const { sectionState } = useContext(SectionContext);
  const settingsValidation = {
    ...(sectionState.settings as SectionSettings).validation,
    allowedTags: [...(sectionState.settings as SectionSettings).validation.allowedTags, 'iframe'],
    allowedAttributes: {
      '*': [...(sectionState.settings as SectionSettings).validation.allowedAttributes['*']]
    }
  };

  const {
    allowedTags,
    allowedAttributes,
    maxHtmlContentSize,
    maxAttachmentFileCount,
    maxAttachmentFileSize
  } = settingsValidation;

  const validateTagsAndAttributes = (content: string) => {
    const html = contentToHtmlElement(content);
    const linkRegex = new RegExp(/https?:\/\/|http?:\/\//gm);
    let error = null;

    html.querySelectorAll('*').forEach((e: Element) => {
      if (!e.tagName) return;

      const tag = e.tagName.toLowerCase();

      if (!allowedTags.includes(tag)) error = 'invalidTag';

      Array.from(e.attributes).forEach(({ name, value }) => {
        if (!name) return;

        const attributeName = name.toLowerCase();
        if (!allowedAttributes['*'].includes(attributeName)) {
          error = 'invalidAttribute';
        } else if (attributeName === 'href' && (!value || !linkRegex.test(value))) {
          error = 'invalidLink';
        }
      });
    });

    return error;
  };

  const validateHtmlContent = (input: InputState): InputState => {
    let error = null;
    const content = input.value as string;

    if (!content || !content.length || content === EMPTY_HTML_CONTENT) error = 'fillContent';

    const size = new TextEncoder().encode(content).length;

    if (!error && size > maxHtmlContentSize) error = 'contentTooLarge';

    if (!error) error = validateTagsAndAttributes(content);

    validateAttachments(input);

    return {
      ...input,
      error: !!error,
      validationErrorKey: error
    } as InputState;
  };

  const validateAttachments = (input: HtmlInputState): InputState => {
    if (!input.attachments || !input.attachments.length) return input;

    let error = null;

    if (input.attachments.length > maxAttachmentFileCount)
      error = t('form.validation.attachmentCountExceeded', {
        maxCount: maxAttachmentFileCount
      });

    const exceededSizeAttachment = input.attachments.find(
      (a) => a.file && a.file?.size > maxAttachmentFileSize
    );

    if (!error && exceededSizeAttachment)
      error = t('form.validation.attachmentSizeExceeded', {
        filename: exceededSizeAttachment.name,
        maxSize: formatBytes(maxAttachmentFileSize)
      });

    return {
      ...input,
      error: !!error,
      validationErrorMessage: error
    } as InputState;
  };

  return {
    validateHtmlContent,
    validateAttachments
  };
};

export default useValidation;
