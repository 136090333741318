import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2)
  },
  dialogTitle: {
    padding: theme.spacing(2),
    width: '100%'
  },
  dialogTitleInfoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dialogTitleUserInfo: {
    flex: 0.9
  },
  dialogContent: {
    padding: theme.spacing(2)
  },
  apartmentCard: {
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    position: 'relative',
    background: 'white',
    border: '1px solid #EAECF0',
    boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  },
  ownerDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%'
  },
  nameTitle: {
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.sectionsHeader?.main
  },
  contactDetails: {
    display: 'flex',
    alignItems: 'start',
    marginTop: theme.spacing(1)
  },
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:active': {
      backgroundColor: 'transparent'
    },
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 0,
    right: 0
  },
  projectNameItem: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(1)
  },
  apartmentNameItem: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main
  },
  contactDetailsIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    marginTop: '3px'
  },
  apartmentItemIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main
  },
  body2Title: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.body2.fontSize,
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    whiteSpace: 'break-spaces',
    overflow: 'visible'
  },
  linkTitle: {
    color: theme.palette.common.black,
    fontSize: theme.typography.body2.fontSize,
    '&:hover': {
      textDecoration: 'underline'
    },
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word'
  }
}));

export default useStyles;
