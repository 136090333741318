import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    box: {
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      borderRadius: 10,
      padding: '8px 14px',
      margin: '20px auto',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      position: 'relative'
    },
    ownershipHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    title: {
      marginLeft: '8px',
      color: theme.palette.sectionsHeader?.main,
      fontSize: theme.typography.h6.fontSize
    },
    addButton: {
      position: 'absolute',
      right: 4,
      top: 4,
      fontSize: theme.typography.button.fontSize
    },
    iconStyle: {
      color: theme.palette.sectionsHeader?.main
    }
  })
);
