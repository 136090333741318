export interface EventType {
  startTime?: string;
  endTime?: string;
  description?: string;
  id: number;
  name: string;
  type: string;
  tenantId: string;
  registrationDeadline: string | null;
  selectedApartmentsOnly: boolean;
  timeCreated: string;
  question1: string | null;
  question2: string | null;
  author: any;
  calendar: any;
  authorAuthId: string | null;
  reservations: CalendarReservation[];
  appointmentDays: AppointmentDays[];
}

export interface ReservationType {
  id: number;
  duration: number;
  name: string;
}

export interface appointmentSlots {
  calendarResource: CalendarResource;
  startTime: string;
  endTime: string;
  eventId: number;
  resourceAvailabilityPoolId: number;
  reservationTypeId: number;
}

export interface CalendarResource {
  auth0Id: string;
  displayName: string;
}

export interface AppointmentDays {
  reservationType: ReservationType;
  availableSlots: AvailableSlots[];
}

export interface AvailableSlots {
  date: string;
  appointmentSlots: appointmentSlots[];
}

export interface CalendarSection {
  name: string;
  description: string | null;
  events: EventType[];
}

export interface InsertedReservation {
  answer1: string;
  answer2: string;
  endTime: string;
  startTime: string;
  numberOfPersonsAttending: number;
  apartmentId: number | null;
  reservationTypeId?: number;
  resourceAvailabilityPoolId?: number;
}

export interface EditedReservation {
  answer1: string;
  answer2: string;
  numberOfPersonsAttending?: number;
}

export interface CalendarReservation {
  id: number;
  answer1: string;
  answer2: string;
  endTime: string;
  numberOfPersonsAttending: number;
  startTime: string;
  timeCreated: string;
  apartmentId: number;
  author: any;
  eventId: number;
  reservationTypeId: number | null;
  resourceId: number | null;
  resourceAvailabilityPoolId: number | null;
}

export enum DialogType {
  Add_Registration = 'addRegistration',
  Edit_Registration = 'editRegistration',
  Not_Participate = 'notParticipate',
  Add_Appointment = 'addAppointment',
  Edit_Appointment = 'editAppointment'
}

export enum SnackbarMessageEnum {
  savedMessage = 'snackboxSavedMessage',
  deletedMessage = 'snackboxDeletedMessage',
  errorMessage = 'snackboxErrorMessage',
  deleteErrorMessage = 'snackboxDeleteErrorMessage',
  createErrorMessage = 'snackboxCreateErrorMessage',
  editErrorMessage = 'snackboxEditErrorMessage'
}

export enum LocalFormatDateTimeType {
  Full = 'full',
  Date = 'date',
  Time = 'time'
}
