import { Grid, CardContent, Typography, Link, Tooltip, Button } from '@material-ui/core';
import styles from './styles';
import useT from 'hooks/useT';
import { Email, Home, Phone } from '@material-ui/icons';
import { useContext } from 'react';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import { useHistory } from 'react-router-dom';
import { OwnerSearchInterface } from 'hooks/useGetOwnerSearch';
import { useTrackers } from 'hooks/useTrackers';
import { EventAction } from 'services/EventAction';
import { EventCategory } from 'services/EventCategory';
import OwnerAvatar from 'components/ApartmentOwners/OwnerAvatar/OwnerAvatar';

interface OwnerInforCardProps {
  owner: OwnerSearchInterface;
  handleDialogClick: () => void;
}

const OwnerInforCard = ({ owner, handleDialogClick }: OwnerInforCardProps) => {
  const { triggerEvent } = useTrackers();
  const { apartmentState, dispatch } = useContext(ApartmentContext);
  const history = useHistory();
  const classes = styles();
  const t = useT();

  const handleSelectedApartment = () => {
    const apartment = apartmentState.apartments.find(
      (apartment) => apartment.id === owner.apartmentId
    );
    if (!apartment || apartment.id === apartmentState.selectedApartment?.id) return;
    dispatch({
      ...apartmentState,
      selectedApartment: apartment,
      loadDetails: true
    });
    triggerEvent({
      action: EventAction.CHANGE_APARTMENT,
      category: EventCategory.USER
    });
    const actualPath = `project/${apartment.projectId}/apartment/${apartment.id}`;
    history.push(actualPath);
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <div className={classes.ownerCard}>
        <div className={classes.cardContent}>
          <div className={classes.avatarContainer}>
            <OwnerAvatar
              lastName={owner.firstName}
              firstName={owner.lastName}
              imageUrl={owner.picture}
            />
          </div>
          <div className={classes.ownerInforSide}>
            <CardContent className={classes.ownerDetails}>
              <Tooltip title={`${owner.firstName} ${owner.lastName}`} arrow>
                <Typography className={classes.headerTitle}>
                  {owner.firstName} {owner.lastName}
                </Typography>
              </Tooltip>
              {owner.email && (
                <span className={classes.detailItem}>
                  <Email fontSize='small' className={classes.iconCard} />
                  <Tooltip title={owner.email} arrow>
                    <Link href={`mailto:${owner.email}`} className={classes.body2Title}>
                      {owner.email}
                    </Link>
                  </Tooltip>
                </span>
              )}
              {owner.phoneNumber && (
                <span className={classes.detailItem}>
                  <Phone fontSize='small' className={classes.iconCard} />
                  <Tooltip title={owner.phoneNumber} arrow>
                    <Link href={`tel:${owner.phoneNumber}`} className={classes.body2Title}>
                      {owner.phoneNumber}
                    </Link>
                  </Tooltip>
                </span>
              )}
              <span className={classes.detailItem}>
                <Home fontSize='small' className={classes.iconCard} />{' '}
                <Tooltip title={owner.apartmentName} arrow>
                  <Link
                    className={classes.body2Title}
                    style={{ marginBottom: 0 }}
                    underline='none'
                    onClick={handleSelectedApartment}
                  >
                    {owner.apartmentName}
                  </Link>
                </Tooltip>
              </span>
            </CardContent>
            {owner.hasMore === true && (
              <Button
                onClick={() => handleDialogClick()}
                variant='text'
                size='small'
                className={classes.buttonContainer}
              >
                <Typography color='textSecondary' className={classes.textButton}>
                  {`${t('ownerSearch.moreApartments')}`}
                </Typography>
              </Button>
            )}
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default OwnerInforCard;
