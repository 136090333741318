import { AppContext } from 'context/App/AppContext';
import { useContext, useEffect } from 'react';

const useSetupFavIcon = (): void => {
  const { appState } = useContext(AppContext);

  useEffect(() => {
    if (appState.tenantSettings?.themeCustomizationSettings?.assets.favIcon) {
      let link = document.querySelector("link[rel~='icon']") as any;
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = appState.tenantSettings?.themeCustomizationSettings?.assets.favIcon;
    }
  }, [appState.tenantSettings?.themeCustomizationSettings]);
};

export default useSetupFavIcon;
