import React from 'react';
import { CircularProgress } from '@material-ui/core';
import useStyles from './ActionButton.styles';
import { Button } from '@groupbuilderoy/gb-components-library';

export interface ActionButtonProps {
  loading?: boolean;
  onClick: (_e: React.SyntheticEvent) => void;
}

const ActionButton: React.FC<ActionButtonProps> = (props) => {
  const { loading, children, onClick } = props;
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      color='primary'
      className={`${classes.button} ${loading ? 'loading' : ''}`}
      caption={''}
    >
      <>
        {loading && <CircularProgress className='progress' />}
        <div className='children'>{children}</div>
      </>
    </Button>
  );
};

export default ActionButton;
