import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    minHeight: '406px'
  },
  description: {
    color: theme.palette.common.black,
    textTransform: 'capitalize',
    fontSize: '.875rem,',
    lineHeight: '1.25rem',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    flexGrow: 1
  },
  titleStyle: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    color: theme.palette.primary?.main,
    position: 'relative',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    transition: 'color 0.3s ease-in-out',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '0',
      height: '2px',
      bottom: '-2px',
      left: '0',
      backgroundColor: theme.palette.primary?.main,
      transition: 'width 0.4s ease-in-out'
    }
  },
  iconStyle: {
    color: theme.palette.primary?.main,
    backgroundColor: '#f5f5f4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.3s ease-in-out, transform 0.3s ease-in-out',
    flexShrink: 0
  },
  circleIcon: {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '45px',
    height: '45px',
    marginRight: '15px'
  },
  buttonStyle: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 0',
    width: '100%',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: 'transparent',
      '& $titleStyle': {
        color: theme.palette.primary?.dark
      },
      '& $iconStyle': {
        backgroundColor: theme.palette.primary?.main,
        '& svg': {
          color: theme.palette.common.white
        }
      }
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    position: 'absolute',
    top: 0,
    right: 0
  },
  button: {
    float: 'right',
    marginBottom: '1rem'
  },
  folderItems: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    overflowY: 'auto',
    maxHeight: '300px',
    padding: '10px'
  },
  link: {
    textDecoration: 'none',
    marginBottom: '3px'
  }
}));

export default useStyles;
