import { withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { ReactElement } from 'react';

import styles from './styles';

type ErrorBoundaryStateType = {
  error?: Error;
  eventId: any;
};

type ErrorBoundaryPropsType = {
  classes: any;
  children: ReactElement;
};

class ErrorBoundary extends React.Component<ErrorBoundaryPropsType, ErrorBoundaryStateType> {
  constructor(props: ErrorBoundaryPropsType) {
    super(props);
    this.state = { error: undefined, eventId: null };
  }

  componentDidCatch(error: Error) {
    this.setState({ error });

    // TODO: sentry and eventId
  }

  render() {
    const { children, classes } = this.props;
    const { error, eventId } = this.state;

    if (!error) return children;

    return (
      <Alert severity='error' variant='outlined' className={classes.alert}>
        {error.message ? error.message : error.toString()} {eventId && { eventId }}
      </Alert>
    );
  }
}

export default withStyles(styles)(ErrorBoundary);
