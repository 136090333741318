import { fetchChecklist } from 'axiosInstances';
import { Checklist } from 'axiosInstances/instances/CheckListResponse';
import ChecklistSection from 'components/Sections/Checklist/ChecklistSection';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import { AppContext } from 'context/App/AppContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import { SectionContext } from 'context/Section/SectionContext';
import { useContext, useEffect, useState } from 'react';

export default function useGetChecklistItems(section: ChecklistSection) {
  const { appState, dispatch: appStateDispatch } = useContext(AppContext);
  const { projectState } = useContext(ProjectContext);
  const { apartmentState } = useContext(ApartmentContext);
  const { sectionState, dispatch } = useContext(SectionContext);
  const { token, startParameters } = appState;
  const { organisationId, tenantId } = startParameters;
  const apartmentId = String(apartmentState.selectedApartment?.id);
  const projectId = String(projectState.selectedProject?.id);
  const [checklistItems, setChecklistItems] = useState<Checklist | null>(null);
  const [fetchingChecklistItems, setFetchingChecklistItem] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const fetch = async () => {
    if (!section.id || fetchingChecklistItems) return;

    setFetchingChecklistItem(true);

    try {
      const data = await fetchChecklist({
        token: String(token),
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        projectId,
        apartmentId,
        sectionId: section.id
      });
      setChecklistItems(data);
      appStateDispatch({ ...appState, newItemInspectionType: data.newItemInspectionType });
    } catch {
      setError(true);
    } finally {
      setFetchingChecklistItem(false);
      if (sectionState.update) dispatch({ ...sectionState, update: false, showBackButton: false });
    }
  };

  useEffect(() => {
    fetch();
  }, [section.id, sectionState.update]); // eslint-disable-line

  return { checklistItems, fetchingChecklistItems, error };
}
