import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => {
  return createStyles({
    item: {
      position: 'relative',

      '&.signable': {
        '& svg, path': {
          color: 'red'
        }
      }
    }
  });
});
