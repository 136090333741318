import { ISection } from '../Section';

export interface ContextType {
  tenantId: string;
  domain: string;
  id: number;
  name: string;
  itemType: string;
}

export interface AttachmentType {
  description?: string;
  createdOn?: string;
  url: string;
  projectId?: number;
  context?: ContextType;
  apartmentId?: null | number;
  id: number;
  name: string;
  type?: AttachmentFileType;
  tag?: string;
  xfdf?: string;
  mimeType: string;
  sectionId?: string;
  phaseId?: string;
  signed?: boolean;
  signable?: boolean;
  sections?: ISection[];
}

/* eslint-disable */
export enum AttachmentFileType {
  PDF = 'pdf',
  IMAGE = 'image',
  VIDEO = 'video',
  SIGNABLE = 'signable',
  SIGNED = 'signed',
  OTHER = 'other'
}
/* eslint-enable */
