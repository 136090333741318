import { Select } from '@groupbuilderoy/gb-components-library';
import { useTheme } from '@material-ui/core';
import { ProjectContext } from 'context/Project/ProjectContext';
import { SectionContext } from 'context/Section/SectionContext';
import useT from 'hooks/useT';
import { useTrackers } from 'hooks/useTrackers';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { EventAction } from 'services/EventAction';
import { EventCategory } from 'services/EventCategory';

export default function ProjectSelect() {
  const { triggerEvent } = useTrackers();
  const theme = useTheme();
  const t = useT();
  const { projectState, dispatch } = useContext(ProjectContext);
  const { sectionState, dispatch: sectionDispacth } = useContext(SectionContext);
  const params = useParams<{ projectId?: string }>();

  if (!projectState.projects.length) return null;

  const selectedProjectId =
    params.projectId || projectState.selectedProject?.id || projectState.projects[0].id;

  const handleProjectChange = async (projectId: number) => {
    const project = projectState.projects.find((project) => project.id === projectId);

    if (!project || project.id === projectState.selectedProject?.id) return;

    sectionDispacth({ ...sectionState, sections: [] });
    dispatch({
      ...projectState,
      selectedProject: project,
      loadDetails: true
    });
    triggerEvent({
      action: EventAction.CHANGE_PROJECT,
      category: EventCategory.USER
    });
  };

  return (
    <Select
      data-testid='project-selector'
      caption={t('project')}
      initialValue={selectedProjectId}
      itemList={projectState.projects}
      identifierKey={'id'}
      renderKey={'name'}
      onChange={handleProjectChange}
      selectedItemColor={theme.palette.navBarSecondary?.main || 'white'}
      toLink={'/project'}
    />
  );
}
