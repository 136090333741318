import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    dropzone: {
      position: 'relative',
      marginTop: '20px',
      width: '100%',
      minHeight: '113px',
      display: 'flex',
      border: '2px dashed #CECCC4',
      borderRadius: '5px',
      cursor: 'pointer',
      color: 'rgba(0, 0, 0, .55)',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',

      '&:hover': {
        borderColor: 'black'
      }
    },
    label: {
      position: 'absolute',
      backgroundColor: 'white',
      top: '-11px',
      left: '8px',
      padding: '0 6px',
      fontSize: '.75rem'
    },
    name: {
      '& span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '1.3rem',
        maxWidth: '400px'
      }
    },
    uploadIcon: {
      width: '1.2em',
      height: '1.2em'
    },
    uploadDescription: {
      margin: 0
    },
    list: {
      margin: 0,
      maxWidth: '500px'
    }
  })
);
