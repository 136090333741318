import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    threadsContainer: {
      paddingRight: '0.5rem',
      paddingTop: '1rem',
      flexGrow: 1
    },
    buttonArea: {
      alignSelf: 'self-start'
    },
    createMessageButton: {
      borderRadius: 0,
      padding: '0.75rem 3rem 0.75rem'
    },
    createMessageButtonText: {
      fontSize: theme.typography.overline.fontSize,
      fontWeight: theme.typography.fontWeightLight,
      textTransform: 'none'
    } as CSSProperties
  })
);
