import ReactGA4 from 'react-ga4';
import { AppContext } from 'context/App/AppContext';
import { useContext } from 'react';
import { GAEvent } from 'services/tracking';

interface TrackersProps {
  pageView: (_path: string) => void;
  triggerEvent: (_gaEvent: GAEvent, _trackers?: string[]) => void;
}

/**
 * useTrackers hook
 *
 * @returns - TrackersProps
 */
export const useTrackers = (): TrackersProps => {
  const { appState } = useContext(AppContext);

  const pageView = (path: string) =>
    ReactGA4.send({ hitType: 'pageview', page: path, title: 'Page' });

  const triggerEvent = (gaEvent: GAEvent) => {
    ReactGA4.event(gaEvent.action, {
      category: gaEvent.category,
      label: gaEvent.label,
      organisation: appState.startParameters.organisationId,
      project: appState.startParameters.projectId,
      apartment: appState.startParameters.apartmentId
    });
  };

  return {
    pageView,
    triggerEvent
  };
};
