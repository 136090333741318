import Section, { SectionType } from '../Section';

export default class ChecklistSection extends Section {
  constructor(props: any) {
    super(props);

    this.type = SectionType.CHECKLIST;
    this.actionButtonTxtKey = 'view';
    this.alertProps.error.txtKey = 'checklist.alert.failedToFetch';
    this.alertProps.noItems.txtKey = 'checklist.alert.noChecklistItems';
  }
}
