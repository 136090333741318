import { createContext, Dispatch } from 'react';
import { PhaseState } from './interfaces';

export const initialPhaseState: PhaseState = {
  phases: []
};

export const PhaseContext = createContext<{
  phaseState: PhaseState;
  dispatch: Dispatch<PhaseState>;
}>({ phaseState: initialPhaseState, dispatch: () => initialPhaseState });
