/**
 * Add more actions if needed
 */
/* eslint-disable */
export enum EventAction {
  CHANGE_PROJECT = 'Change project',
  CHANGE_APARTMENT = 'Change apartment',
  CLICK_3D = 'Click 3D',
  NOTIFICATION_LINK_CLICKED = 'Notification link clicked',
  PHASE_CLICKED = 'Phase clicked',
  SECTION_CLICKED = 'Section clicked',
  SECTION_BACK_CLICKED = 'Section back clicked'
}
