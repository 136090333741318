import { useContext } from 'react';
import { getAttachmentFileType, openAttachmentModal } from 'utils/sections';
import useStyles from './styles';
import { getDateFnsLocale } from 'i18n';
import { format } from 'date-fns';
import { UserRight } from 'context/App/types';
import useT from 'hooks/useT';

import { ListItemTag } from 'components/ListItemTag/ListItemTag';
import { ProjectContext } from 'context/Project/ProjectContext';
import { AttachmentViewerContext } from 'context/AttachmentViewer/AttachmentViewerContext';
import { DetailedListItem, IconKeys } from '@groupbuilderoy/gb-components-library';
import { AttachmentFileType } from 'components/Sections/Attachments/interfaces';

export default function AttachmentListItem({ attachment, onClick }: any) {
  const { filename, createdOn, signable, url, mimeType } = attachment;
  const classes = useStyles();
  const { dispatch } = useContext(AttachmentViewerContext);
  const { projectState } = useContext(ProjectContext);
  const text = useT();

  const isImage = mimeType && mimeType.includes('image');
  const getIcon = () => {
    const type = getAttachmentFileType(attachment);

    switch (type) {
      case AttachmentFileType.SIGNABLE:
      case AttachmentFileType.SIGNED:
        return IconKeys.signable;
      case AttachmentFileType.IMAGE:
        return IconKeys.image;
      case AttachmentFileType.PDF:
        return IconKeys.pdf;
      case AttachmentFileType.VIDEO:
        return IconKeys.video;
      default:
        return IconKeys.document;
    }
  };

  return (
    <DetailedListItem
      className={`${classes.item} ${signable ? 'signable' : ''}`}
      icon={isImage ? undefined : getIcon()}
      title={filename}
      subtitle={format(new Date(createdOn as string), 'MMM d, yyyy', {
        locale: getDateFnsLocale()
      })}
      image={isImage ? url : undefined}
      onClick={
        onClick && isImage
          ? onClick
          : () => {
              openAttachmentModal(attachment, dispatch);
            }
      }
    >
      {signable && projectState.userRights?.check(UserRight.signDocument) && (
        <ListItemTag text={text('attachments.sign')} />
      )}
    </DetailedListItem>
  );
}
