import React, { useMemo, useReducer } from 'react';
import { PhaseContextProviderProps, PhaseContextReducerType, PhaseState } from './interfaces';
import { initialPhaseState, PhaseContext } from './PhaseContext';

const reduce = (previousContext: PhaseState, contentextUpdate: PhaseState): PhaseState => {
  return {
    ...previousContext,
    ...contentextUpdate
  };
};

const PhaseContextProvider: React.FC<PhaseContextProviderProps> = ({ children }) => {
  const [phaseState, dispatch] = useReducer<PhaseContextReducerType>(reduce, initialPhaseState);

  const contextValue = useMemo(() => ({ phaseState, dispatch }), [phaseState, dispatch]);

  return <PhaseContext.Provider value={contextValue}>{children}</PhaseContext.Provider>;
};

export default PhaseContextProvider;
