import Section, { SectionType } from '../Section';

export default class AttachmentsSection extends Section {
  constructor(props: any) {
    super(props);

    this.type = SectionType.ATTACHMENTS;
    this.alertProps.error.txtKey = 'attachments.fetchFailed';
    this.alertProps.noItems.txtKey = 'attachments.noAttachments';
    this.listMaxCount = 4;
  }
}
