import React, { useMemo, useReducer } from 'react';
import {
  ModalMessageContextProviderProps,
  ModalMessageContextReducerType,
  ModalMessageState
} from './interfaces';
import { initialModalMessageState, ModalMessageContext } from './ModalMessageContext';

const reduce = (
  previousContext: ModalMessageState,
  updatedContext: ModalMessageState
): ModalMessageState => {
  return {
    ...previousContext,
    ...updatedContext
  };
};

const ModalMessageContextProvider: React.FC<ModalMessageContextProviderProps> = ({ children }) => {
  const [modalMessageState, dispatch] = useReducer<ModalMessageContextReducerType>(
    reduce,
    initialModalMessageState
  );

  const contextValue = useMemo(
    () => ({ modalMessageState, dispatch }),
    [modalMessageState, dispatch]
  );

  return (
    <ModalMessageContext.Provider value={contextValue}>{children}</ModalMessageContext.Provider>
  );
};

export default ModalMessageContextProvider;
