import { getUserDetailsByBearerToken, tokenExchange } from 'axiosInstances';
import { StartParametersInterface } from 'context/App/interfaces';
import StartParameter from './startParameters';

export const login = async (startParameters: StartParametersInterface) => {
  const { organisationId, tenantId, accessToken, authToken } = startParameters;
  const gbToken = authToken || sessionStorage.getItem(StartParameter.gbToken);

  if (gbToken) {
    const user = await getUserDetailsByBearerToken(
      String(organisationId),
      String(tenantId),
      String(gbToken)
    );
    if (!sessionStorage.getItem(StartParameter.authToken)) {
      sessionStorage.setItem(StartParameter.authToken, gbToken);
    }
    if (!sessionStorage.getItem(StartParameter.gbToken)) {
      sessionStorage.setItem(StartParameter.gbToken, gbToken);
    }
    return { token: gbToken || authToken, user: user };
  }

  try {
    const { token, user } = await tokenExchange(
      String(organisationId),
      String(tenantId),
      String(accessToken)
    );

    // if (user && user.auth0Id) {
    //   // @ts-ignore
    //   delete user.auth0Id; // TODO: this should not come from backend in first place
    // }

    sessionStorage.setItem(StartParameter.gbToken, token);
    sessionStorage.setItem(StartParameter.user, JSON.stringify(user));
    sessionStorage.removeItem(StartParameter.accessToken);

    return { token, user };
  } catch (e) {
    throw e;
  }
};

export const logout = (organisationId: string | undefined): boolean => {
  const portalUrl = process.env.REACT_APP_PORTAL_URL
    ? process.env.REACT_APP_PORTAL_URL
    : global.env
    ? global.env.PORTAL_URL
    : '';

  if (!portalUrl || !portalUrl.length) {
    console.warn('Could not determine portal url for logout');
    return false;
  }

  sessionStorage.clear();

  window.location.href = `${portalUrl}/${organisationId}?logout=true`;

  return true;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const integratedAppPaths = ['/3D', '/selections'];

export const organizationsWithExtendedHeaderText = ['kaamos'];
