import { fetchFolderSections } from 'axiosInstances';
import { ISection } from 'components/Sections/Section';
import { AppContext } from 'context/App/AppContext';
import { PhaseContext } from 'context/Phase/PhaseContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import { SectionContext } from 'context/Section/SectionContext';
import { useContext, useEffect, useState } from 'react';
import { SectionWithContent, createSectionsWithContent } from 'utils/sections';

const useGetSectionFolder = (parentSectionId: number) => {
  const { sectionState, dispatch } = useContext(SectionContext);
  const [folderSections, setFolderSections] = useState<SectionWithContent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { appState } = useContext(AppContext);
  const { projectState } = useContext(ProjectContext);
  const { phaseState } = useContext(PhaseContext);

  const { organisationId, tenantId } = appState.startParameters;
  const projectId = projectState.selectedProject?.id;
  const gbToken = appState.token;

  const fetch = async () => {
    if (!projectId || projectState.loading || !parentSectionId) return;
    setLoading(true);
    try {
      const sections = await fetchFolderSections({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        gbToken: String(gbToken),
        parentSectionId: String(parentSectionId)
      });
      const newFolderSections = createSectionsWithContent(
        sections as ISection[]
      ) as SectionWithContent[];
      setFolderSections(newFolderSections);
      const updatedSections = [...newFolderSections].filter(
        (newSection) =>
          !sectionState.sections.some((existingSection) => existingSection.id === newSection.id)
      );
      if (updatedSections.length > 0) {
        dispatch({
          ...sectionState,
          loading: false,
          update: false,
          sections: [...updatedSections, ...sectionState.sections]
        });
      }
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, [parentSectionId, phaseState.selectedPhase?.id]); // eslint-disable-line

  return { folderSections, loading, error };
};

export default useGetSectionFolder;
