import styles from './styles';

interface IntegratedAppProps extends React.HTMLProps<HTMLDivElement> {
  appName: string;
  url: string;
}
export default function IntegratedApp({ appName, url, ...rest }: IntegratedAppProps) {
  const classes = styles();
  return (
    <div className={classes.iframeContainer} data-testid={`${appName}-container`} {...rest}>
      <iframe
        id='integratedAppIframe'
        src={url}
        className={classes.iframe}
        data-testid={`${appName}-iframe`}
      />
    </div>
  );
}
