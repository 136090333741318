import React, { useMemo, useReducer } from 'react';
import {
  CalendarEventsContextProviderProps,
  CalendarEventsContextReducerType,
  CalendarEventsState
} from './interfaces';
import { initialCalendarEventsState, CalendarEventsContext } from './CalendarEventsContext';

const reduce = (
  previousContext: CalendarEventsState,
  contentextUpdate: CalendarEventsState
): CalendarEventsState => {
  return {
    ...previousContext,
    ...contentextUpdate
  };
};

const CalendarEventsContextProvider: React.FC<CalendarEventsContextProviderProps> = ({
  children
}) => {
  const [calendarEventsState, dispatch] = useReducer<CalendarEventsContextReducerType>(
    reduce,
    initialCalendarEventsState
  );

  const contextValue = useMemo(
    () => ({ calendarEventsState, dispatch }),
    [calendarEventsState, dispatch]
  );

  return (
    <CalendarEventsContext.Provider value={contextValue}>{children}</CalendarEventsContext.Provider>
  );
};

export default CalendarEventsContextProvider;
