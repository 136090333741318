import { Room } from 'context/Apartment/interfaces';

export interface ChatMessageType {
  content: string;
  discussionEnd: number;
  id: number;
  isFromUser: number;
  isInternal: number;
  sendTime: number;
  userID: number;
  userName: string;
  name: string;
}

/* eslint-disable */
export enum ChatStatusType {
  OPEN = 0,
  CLOSED = 1
}
export enum BulletinReadStatusType {
  UNREAD = 0,
  READ = 1
}
/* eslint-enable */

export interface ChatRoomThreadType {
  room: Room;
  messages: ChatMessageType[];
}

export interface BulletinType {
  attachmentFileDisplayName: null | string;
  attachmentFilePath: null | string;
  attachmentFilename: null | string;
  attachmentId: null | string;
  content: string;
  received: BulletinReadStatusType;
  sendTime: number;
  userName: string;
  id: number;
  name: string;
}
