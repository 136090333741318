// TODO: move this to gb-components-library

import { Snackbar } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';

interface FlashAlertProps {
  message: string;
  open: boolean;
  type?: Color;
  onClose: () => void;
}

const FLASH_DURATION = 5000;

const FlashNotification = ({ message, type, open, onClose }: FlashAlertProps) => {
  return (
    <Snackbar
      data-testid='flash-notification'
      open={open}
      autoHideDuration={FLASH_DURATION}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={type || ('error' as Color)}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default FlashNotification;
