import { createContext, Dispatch } from 'react';
import { CalendarEventsState } from './interfaces';

export const initialCalendarEventsState: CalendarEventsState = {
  CalendarEventsSection: null,
  selectedCalendarEvent: null,
  user: null
};

export const CalendarEventsContext = createContext<{
  calendarEventsState: CalendarEventsState;
  dispatch: Dispatch<CalendarEventsState>;
}>({
  calendarEventsState: initialCalendarEventsState,
  dispatch: () => initialCalendarEventsState
});
