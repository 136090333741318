import {
  GetOwnerUserForApartment,
  MetaDataApartment,
  MetaDataProject,
  addUserDetail,
  editUserDetail,
  getUserDetailByEmail,
  getUserDetailPicture,
  postUserDetailPicture
} from 'axiosInstances';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import { AppContext } from 'context/App/AppContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import { UserDetailsFormData } from 'routes/UserDetails/UserDetails';

export interface OwnerInterface {
  id: string;
  auth0Id: string;
  dbId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  title: string;
  picture?: string;
  username: string;
  userId: number;
  isSSO?: boolean;
  state: string;
  city: string;
  country: string;
  zipCode: string;
  streetAddress: string;
  highestRole: number;
}

export interface AddOwnerInterface {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  picture?: string | null;
}

const userGetApartmentOwners = () => {
  const userLevel = 3;
  const { appState } = useContext(AppContext);
  const { organisationId, tenantId, projectId, apartmentId, authToken } = appState.startParameters;
  const { apartmentState } = useContext(ApartmentContext);
  const { selectedApartment } = apartmentState;
  const [owners, setOwners] = useState<OwnerInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetch = useCallback(async () => {
    try {
      if (!selectedApartment) return;
      setLoading(true);
      const res = await GetOwnerUserForApartment({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        gbToken: String(authToken),
        projectId: String(projectId),
        apartmentId: String(selectedApartment.id),
        userLevel: userLevel
      });
      setOwners(res);

      // Fetch owner pictures in parallel and update the state incrementally
      res.forEach(async (owner: OwnerInterface) => {
        if (owner.picture) {
          const imageBlob = await getUserDetailPicture({
            organisationId: String(organisationId),
            tenantId: String(tenantId),
            gbToken: String(authToken),
            auth0Id: String(owner.auth0Id)
          });
          const imageObjectURL = imageBlob ? URL.createObjectURL(imageBlob) : undefined;
          setOwners((prevOwners) =>
            prevOwners.map((prevOwner) =>
              prevOwner.auth0Id === owner.auth0Id
                ? { ...prevOwner, picture: imageObjectURL }
                : prevOwner
            )
          );
        }
      });
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [organisationId, tenantId, authToken, projectId, apartmentId, userLevel, selectedApartment]);

  const updateApartmentOwner = async (bodyData: UserDetailsFormData) => {
    try {
      if (!appState.token) {
        return;
      }
      setLoading(true);
      const updateData = { ...bodyData };
      delete updateData.profilePicture;

      const res = await editUserDetail({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        gbToken: String(authToken),
        auth0Id: String(bodyData.auth0Id),
        email: String(bodyData.email),
        userDetailData: updateData
      });
      const newOwnerData: OwnerInterface = {
        auth0Id: res.auth0Id ?? '',
        firstName: res.firstName ?? '',
        lastName: res.lastName ?? '',
        email: res.email ?? '',
        phoneNumber: res.phoneNumber ?? '',
        streetAddress: res.streetAddress ?? '',
        city: res.city ?? '',
        state: res.state ?? '',
        zipCode: res.zipCode ?? '',
        country: res.country ?? '',
        id: res.id,
        dbId: res.dbId,
        title: res.title,
        username: res.username,
        userId: res.userId,
        highestRole: res.highestRole
      };
      if (res.picture) {
        const imageBlob = await getUserDetailPicture({
          organisationId: String(organisationId),
          tenantId: String(tenantId),
          gbToken: String(appState.token),
          auth0Id: String(newOwnerData.auth0Id)
        });
        newOwnerData.picture = imageBlob ? URL.createObjectURL(imageBlob) : undefined;
      }
      setOwners((prev) => prev.map((o) => (o.auth0Id === newOwnerData.auth0Id ? newOwnerData : o)));
      return newOwnerData;
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const getUserMetaDataByEmail = async ({
    gbToken,
    organisationId,
    tenantId,
    email
  }: {
    organisationId: string;
    tenantId: string;
    gbToken: string;
    email: string;
  }) => {
    const data = await getUserDetailByEmail({
      organisationId: String(organisationId),
      tenantId: String(tenantId),
      gbToken: String(gbToken),
      email: String(email)
    });
    if (data == null) {
      return [];
    }
    const metaProjects: MetaDataProject[] = data.user.userRoleMetadata.projects;
    return metaProjects;
  };

  const addNewApartmentToMetaData = ({
    projects,
    projectId,
    newApartment
  }: {
    projects: MetaDataProject[];
    projectId: string;
    newApartment: MetaDataApartment;
  }) => {
    const newMetaProject: MetaDataProject = {
      id: Number(projectId),
      apartments: [newApartment]
    };
    if (projects.length <= 0) {
      return [newMetaProject];
    }
    const project = projects.find((p) => p.id.toString() === projectId);
    if (project) {
      const updatedProjects = projects.map((p) =>
        p.id.toString() === projectId ? { ...p, apartments: [...p.apartments, newApartment] } : p
      );
      return updatedProjects;
    } else {
      return [...projects, newMetaProject];
    }
  };

  const removeApartmentFromMetaData = ({
    projects,
    projectId,
    apartmentId
  }: {
    projects: MetaDataProject[];
    projectId: string;
    apartmentId: string;
  }) => {
    if (projects.length <= 0) {
      return [];
    }
    const project = projects.find((p) => p.id.toString() === projectId);

    if (project) {
      const updatedApartments = project.apartments.filter(
        (apartment) => apartment.id.toString() !== apartmentId
      );
      if (updatedApartments.length === 0) {
        return projects.filter((p) => p.id.toString() !== projectId);
      }
      const updatedProjects = projects.map((p) =>
        p.id.toString() === projectId ? { ...p, apartments: updatedApartments } : p
      );
      return updatedProjects;
    } else {
      return projects;
    }
  };

  const addApartmentOwner = async (bodyData: AddOwnerInterface, pictureFile?: File) => {
    try {
      if (!appState.token) {
        return;
      }
      if (!selectedApartment) return;
      setLoading(true);

      const dataBody = {
        firstName: bodyData.firstName,
        lastName: bodyData.lastName,
        phoneNo: bodyData.phoneNumber
      };
      const newApartment: MetaDataApartment = {
        id: selectedApartment.id,
        level: userLevel
      };
      const currentProjects = await getUserMetaDataByEmail({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        gbToken: String(authToken),
        email: String(bodyData.email)
      });
      const newProjects = addNewApartmentToMetaData({
        projects: currentProjects,
        projectId: String(projectId),
        newApartment
      });
      const res = await addUserDetail({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        gbToken: String(authToken),
        projects: newProjects,
        email: String(bodyData.email),
        userDetailData: dataBody
      });
      const newOwnerData: OwnerInterface = {
        auth0Id: res.auth0Id ?? '',
        firstName: res.firstName ?? '',
        lastName: res.lastName ?? '',
        email: res.email ?? '',
        phoneNumber: res.phoneNumber ?? '',
        streetAddress: res.streetAddress ?? '',
        city: res.city ?? '',
        state: res.state ?? '',
        zipCode: res.zipCode ?? '',
        country: res.country ?? '',
        id: res.id,
        dbId: res.dbId,
        title: res.title,
        username: res.username,
        userId: res.userId,
        highestRole: res.highestRole
      };

      if (pictureFile) {
        await uploadProfilePicture(pictureFile, newOwnerData.auth0Id);

        const imageBlob = await getUserDetailPicture({
          organisationId: String(organisationId),
          tenantId: String(tenantId),
          gbToken: String(appState.token),
          auth0Id: String(newOwnerData.auth0Id)
        });
        const imageObjectURL = imageBlob ? URL.createObjectURL(imageBlob) : undefined;

        newOwnerData.picture = imageObjectURL;
      }

      setOwners((prev) => [...prev, newOwnerData]);
      return newOwnerData;
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const deleteApartmentOwner = async (owner: OwnerInterface) => {
    try {
      if (!appState.token) {
        return;
      }
      if (!selectedApartment) return;
      setLoading(true);

      const currentProjects = await getUserMetaDataByEmail({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        gbToken: String(authToken),
        email: String(owner.email)
      });
      const updatedProjects = removeApartmentFromMetaData({
        projects: currentProjects,
        projectId: String(projectId),
        apartmentId: String(selectedApartment.id)
      });
      const userDetailData = {
        firstName: owner.firstName,
        lastName: owner.lastName,
        phoneNo: owner.phoneNumber
      };

      // remove the apartment from the user metaData,
      const res = await addUserDetail({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        gbToken: String(authToken),
        projects: updatedProjects,
        email: String(owner.email),
        userDetailData
      });
      setOwners((prev) => prev.filter((o) => o.auth0Id !== owner.auth0Id));

      return res;
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const uploadProfilePicture = async (file: File, ownerAuth0Id: string) => {
    try {
      if (!appState.token) {
        return;
      }
      const res = await postUserDetailPicture({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        gbToken: String(authToken),
        auth0Id: String(ownerAuth0Id),
        file
      });
      return res;
    } catch (error) {
      console.error('Error uploading profile picture', error);
    }
  };

  useEffect(() => {
    fetch();
  }, [projectId, selectedApartment, organisationId, tenantId]); // eslint-disable-line

  return {
    owners,
    loading,
    error,
    updateApartmentOwner,
    addApartmentOwner,
    deleteApartmentOwner,
    uploadProfilePicture
  };
};

export default userGetApartmentOwners;
