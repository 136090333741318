import { postFormtItem } from 'axiosInstances';
import { useContext } from 'react';
import styles from './styles';
import { Alert, Color } from '@material-ui/lab';
import Progress from 'components/Progress/Progress';
import useT from 'hooks/useT';
import { AppContext } from 'context/App/AppContext';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import Questionnaire from 'components/Questionnaire/Questionnaire';
import { FieldType, FormState } from 'components/Form/interfaces';
import { FormsContext } from 'context/Forms/FormsContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import { AnswerType } from '../FormsAccordions/interfaces';

export default function CreateOrViewForm() {
  const classes = styles();
  const t = useT();
  const { appState } = useContext(AppContext);
  const { apartmentState } = useContext(ApartmentContext);
  const { projectState } = useContext(ProjectContext);
  const { selectedApartment } = apartmentState;
  const { selectedProject } = projectState;
  const { formsState, dispatch } = useContext(FormsContext);

  const selectedForm = formsState.selectedForm;

  // add title and description to form fields
  const nameAndDescriptionFields = {
    '0': {
      value:
        formsState.selectedForm?.name || formsState.selectedFormType?.defaultFilledFormName || '',
      additionalInfo: '',
      localizationKey: t('forms.title'),
      type: FieldType.STRING,
      required: true,
      validationErrorKey: 'validation.fieldCannotBeEmpty',
      hidden: appState.tenantSettings?.organisationId === 'srv'
    }
  };

  const parsedFilledFormFields = formsState.selectedFormType?.questions.reduce((acc, question) => {
    const answer = selectedForm?.answers.find((answer) => answer.questionId === question.id);
    acc[(question.id + 2).toString()] = {
      id: question.id,
      value: answer?.value || '',
      additionalInfo: question.description,
      localizationKey: question.question,
      type:
        {
          [AnswerType.Consent]: FieldType.CHECKBOX,
          [AnswerType.Text]: FieldType.STRING,
          [AnswerType.Number]: FieldType.NUMERIC,
          [AnswerType.Boolean]: FieldType.BOOLEAN
        }[question.answerType] || FieldType.STRING,
      questionId: question.id,
      required: question.isMandatory,
      validationErrorKey: 'validation.fieldCannotBeEmpty',
      groupId: question.groupId
    };
    return acc;
  }, {} as any);

  const parsedFilledFormGroups = formsState.selectedFormType?.questionGroups.reduce(
    (acc, group) => {
      const groupQuestions = formsState.selectedFormType?.questions.filter(
        (question) => question.groupId === group.id
      );
      const groupAnswers = selectedForm?.answers.filter((answer) =>
        groupQuestions?.map((question) => question.id).includes(answer.questionId)
      );
      const groupFields = groupQuestions?.reduce((acc, question) => {
        const answer = groupAnswers?.find((answer) => answer.questionId === question.id);
        acc.push({
          id: question.id,
          value: answer?.value || '',
          additionalInfo: question.description,
          localizationKey: question.question,
          type:
            {
              [AnswerType.Consent]: FieldType.CHECKBOX,
              [AnswerType.Text]: FieldType.STRING,
              [AnswerType.Number]: FieldType.NUMERIC,
              [AnswerType.Boolean]: FieldType.BOOLEAN
            }[question.answerType] || FieldType.STRING,
          questionId: question.id,
          required: question.isMandatory,
          validationErrorKey: 'validation.fieldCannotBeEmpty',
          groupId: question.groupId
        });
        return acc;
      }, [] as any);
      const parsedGroup = {
        id: group.id,
        name: group.name,
        fields: groupFields
      };
      return [...acc, parsedGroup];
    },
    [] as any
  );

  const additionalFields = {
    additionalNotes: {
      value: formsState.selectedForm?.note || '',
      additionalInfo: '',
      localizationKey: t('forms.additionalNotes'),
      type: FieldType.LONG_STRING
    }
  };

  const renderAlert = (messageKey: string, severity: Color) => {
    const message = t('checklist.alert.' + messageKey);
    return (
      <Alert className={classes.formAlert} variant='outlined' severity={severity}>
        {message}
      </Alert>
    );
  };

  if (!selectedApartment || apartmentState.loading) return <Progress />;
  else if (!selectedApartment?.rooms || !selectedApartment.rooms?.length)
    return renderAlert('noRooms', 'info');

  return (
    <Questionnaire
      hideSubmit={!!selectedForm}
      isQuestionnarie={true}
      localizationKeys={{
        title: '',
        submit: selectedForm ? 'update' : 'submit',
        submitSuccess: selectedForm ? 'forms.alert.updateSuccess' : 'forms.alert.creationSuccess',
        submitError: selectedForm ? 'forms.alert.updateFailed' : 'forms.alert.creationFailed'
      }}
      localizationOverrides={{
        title: formsState.selectedFormType?.name || '',
        description: formsState.selectedFormType?.description || ''
      }}
      fields={{
        ...(formsState.selectedFormType?.defaultFilledFormName
          ? { ...nameAndDescriptionFields, ...parsedFilledFormFields }
          : parsedFilledFormFields),
        ...additionalFields
      }}
      fieldsWithGroup={parsedFilledFormGroups}
      onSubmit={async (filledForm: FormState) => {
        const { organisationId, tenantId } = appState.startParameters;
        if (
          !organisationId ||
          !tenantId ||
          !selectedProject ||
          !selectedApartment ||
          !formsState.selectedFormType
        )
          return;

        const answers = Object.values(filledForm).slice(1, -1);
        const formattedAnswers = answers.map((answer) => ({
          questionId: answer.questionId,
          value:
            answer.type === FieldType.CHECKBOX || answer.type === FieldType.BOOLEAN
              ? answer.value === 'true'
              : answer.value
        }));

        await postFormtItem({
          token: String(appState.token),
          organisationId,
          tenantId,
          projectId: selectedProject.id,
          apartmentId: selectedApartment.id.toString(),
          fields: {
            name: filledForm['0'].value,
            description: formsState.selectedFormType.description,
            formId: formsState.selectedFormType.id,
            note: filledForm['additionalNotes'].value,
            answers: formattedAnswers
          }
        });

        dispatch({
          ...formsState,
          selectedForm: null,
          selectedFormType: null
        });
      }}
    />
  );
}
