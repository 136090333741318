import React, { useMemo, useReducer } from 'react';
import {
  AttachmentViewerContextProviderProps,
  AttachmentViewerContextReducerType,
  AttachmentViewerState
} from './interfaces';
import { initialAttachmentViewerState, AttachmentViewerContext } from './AttachmentViewerContext';

const reduce = (
  previousContext: AttachmentViewerState,
  contentextUpdate: AttachmentViewerState
): AttachmentViewerState => {
  return {
    ...previousContext,
    ...contentextUpdate
  };
};

const AttachmentViewerContextProvider: React.FC<AttachmentViewerContextProviderProps> = ({
  children
}) => {
  const [attachmentViewerState, dispatch] = useReducer<AttachmentViewerContextReducerType>(
    reduce,
    initialAttachmentViewerState
  );

  const contextValue = useMemo(
    () => ({ attachmentViewerState, dispatch }),
    [attachmentViewerState, dispatch]
  );

  return (
    <AttachmentViewerContext.Provider value={contextValue}>
      {children}
    </AttachmentViewerContext.Provider>
  );
};

export default AttachmentViewerContextProvider;
