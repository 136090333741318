import { createContext, Dispatch } from 'react';
import { ApartmentState } from './interfaces';

export const initialApartmentState: ApartmentState = {
  apartments: [],
  loading: true
};

export const ApartmentContext = createContext<{
  apartmentState: ApartmentState;
  dispatch: Dispatch<ApartmentState>;
}>({ apartmentState: initialApartmentState, dispatch: () => initialApartmentState });
