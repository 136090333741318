import { Grid, Typography, TextField, OutlinedInputProps } from '@material-ui/core';
import styles from './styles';

export interface QuestionFieldProps {
  question: string;
  answer: string | number;
  handleChangeAnswer: any;
  fieldName: string;
  isRequired: boolean;
  inputProps?: Partial<OutlinedInputProps> | undefined;
  type?: string;
}

const QuestionField = ({
  question,
  answer,
  handleChangeAnswer,
  fieldName,
  isRequired,
  inputProps,
  type
}: QuestionFieldProps) => {
  const classes = styles();
  return (
    <Grid item xs={12} style={{ marginBottom: '10px' }}>
      <Typography className={classes.questionText}>{question}</Typography>
      <TextField
        type={type || 'text'}
        name={fieldName}
        variant='outlined'
        fullWidth
        value={answer}
        required={isRequired}
        onChange={(e) => handleChangeAnswer(e, fieldName)}
        InputLabelProps={{
          shrink: true,
          style: {
            color: 'black'
          }
        }}
        InputProps={inputProps}
        multiline
        maxRows={3}
      />
    </Grid>
  );
};

export default QuestionField;
