import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    main: {
      zIndex: 9,
      top: 'auto',
      bottom: 0,
      height: '3rem',
      alignSelf: 'start',
      backgroundColor: theme.palette.navBarPrimary?.main
    },
    links: {
      paddingTop: '0.75rem',
      alignSelf: 'flex-start',
      '& > div > a': {
        color: theme.palette.navBarSecondary?.main
      }
    },
    link: {}
  })
);
