import { Quill } from 'react-quill';
const Inline = Quill.import('blots/inline');
const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');

export const previewIdentifier = 'preview';
export const attachmentIdentifier = 'attachment';
export const colorPickerIdentifier = 'colorpicker';

export class PreviewElement extends Inline {
  static create() {
    const node = super.create();
    node.setAttribute('class', previewIdentifier);
    node.setAttribute('contenteditable', 'false');
    return node;
  }
}

PreviewElement.blotName = previewIdentifier;
PreviewElement.tagName = 'div';

export class PlainClipboard extends Clipboard {
  onPaste(e: any) {
    e.preventDefault();

    const range = this.quill.getSelection();
    const text = e.clipboardData.getData('text/plain');
    const delta = new Delta().retain(range.index).delete(range.length).insert(text);
    const index = text.length + range.index;
    const length = 0;

    this.quill.updateContents(delta, 'silent');
    this.quill.setSelection(index, length, 'silent');
    this.quill.scrollIntoView();
  }
}

export default PlainClipboard;

export class AttachmentElement extends Inline {
  static create() {
    const node = super.create() as Element;
    node.setAttribute('class', attachmentIdentifier);
    node.setAttribute('contenteditable', 'false');
    return node;
  }
}

AttachmentElement.blotName = attachmentIdentifier;
AttachmentElement.tagName = 'attachment';

export class ColorPickerElement extends Inline {
  static create() {
    const node = super.create() as Element;
    node.setAttribute('class', colorPickerIdentifier);
    node.setAttribute('contenteditable', 'false');
    return node;
  }
}

ColorPickerElement.blotName = colorPickerIdentifier;
ColorPickerElement.tagName = 'colorpicker';

const Image = Quill.import('formats/image');
const Video = Quill.import('formats/video');
const ATTRIBUTES = [
  'alt',
  'height',
  'width',
  'class',
  'style' // Had to add this line because the style was inlined
];

export class CustomImage extends Image {
  static formats(domNode: any) {
    return ATTRIBUTES.reduce((formats, attribute) => {
      const copy: any = { ...formats };

      if (domNode.hasAttribute(attribute)) {
        copy[attribute] = domNode.getAttribute(attribute);
      }

      return copy;
    }, {});
  }

  format(name: any, value: any) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

export class CustomVideo extends Video {
  static formats(domNode: any) {
    return ATTRIBUTES.reduce((formats, attribute) => {
      const copy: any = { ...formats };

      if (domNode.hasAttribute(attribute)) {
        copy[attribute] = domNode.getAttribute(attribute);
      }

      return copy;
    }, {});
  }

  format(name: any, value: any) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
