import { useEffect, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import useStyles from './styles';
import useT from 'hooks/useT';
import { useHistory } from 'react-router-dom';
import useGetUserDetails from 'hooks/useGetUserDetails';
import Progress from 'components/Progress/Progress';
import UserFormFields from './UserFormFields';
import UserProfilePicture from './UserProfilePicture';
import SnackBarNotification, {
  SnackbarState
} from 'components/ApartmentOwners/SnackBarNotification/SnackBarNotification';

export interface UserDetailsFormData {
  auth0Id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  streetAddress?: string;
  city?: string;
  stateProvince?: string;
  postCode?: string;
  country?: string;
  profilePicture?: string | null;
}

const UserDetails = () => {
  const dialogClosingTime = 3000;
  const classes = useStyles();
  const t = useT();
  const history = useHistory();
  const { userDetail, updateUserDetails, loading, uploadProfilePicture } = useGetUserDetails();
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
  const [formData, setFormData] = useState<UserDetailsFormData>(userDetail);
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: '',
    isSuccess: true
  });

  useEffect(() => {
    if (userDetail) {
      setFormData(userDetail);
    }
  }, [userDetail]);

  const handleSave = async () => {
    if (validateFields()) {
      localStorage.setItem('userDetails', JSON.stringify(formData));
      const updateStatus = await updateUserDetails(formData);
      if (updateStatus) {
        handleOpenSnackbar(t('userDetails.snackboxMessage'), true);
      } else {
        handleOpenSnackbar(t('userDetails.snackboxEditingFailed'), false);
      }
    } else {
      handleOpenSnackbar(t('userDetails.validationFailed'), false);
    }
  };
  const onCancel = () => {
    history.push('/');
  };

  const handleOpenSnackbar = (message: string, isSuccess: boolean) => {
    setSnackbar({
      open: true,
      message,
      isSuccess
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prevState) => ({ ...prevState, open: false }));
  };

  const validateFields = () => {
    const requiredFields: (keyof UserDetailsFormData)[] = ['firstName', 'lastName', 'email'];

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneNumberRegex = /^\+?[0-9]{10,15}$/;
    const newErrors: any = {};
    let isValid = true;

    if (!formData) {
      setErrors(
        requiredFields.reduce((acc, field) => {
          acc[field] = true;
          return acc;
        }, {} as any)
      );
      return false;
    }

    requiredFields.forEach((field) => {
      const value = formData?.[field];
      if (!value?.trim()) {
        newErrors[field] = true;
        isValid = false;
      }
    });

    if (formData.email && !emailRegex.test(formData.email)) {
      newErrors.email = true;
      isValid = false;
    }

    if (formData.phoneNumber) {
      if (!phoneNumberRegex.test(formData.phoneNumber)) {
        newErrors.phoneNumber = true;
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  useEffect(() => {
    const savedData = localStorage.getItem('userDetails');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  if (loading) return <Progress />;

  return (
    <div className={classes.root} style={{ marginTop: '-30px' }}>
      <Grid container spacing={2} alignItems='center' style={{ marginBottom: '0px' }}>
        <Grid item xs={6}>
          <h1 className={classes.header}>{t('userDetails.mainTitle')}</h1>
          <h4 style={{ color: 'grey' }}>{t('userDetails.secondTitle')}</h4>
        </Grid>
        <Grid item xs={6} container justifyContent='flex-end' alignItems='center' spacing={1}>
          <Grid item>
            <Button
              color='secondary'
              variant='contained'
              onClick={onCancel}
              data-testid={'cancelID'}
            >
              {t('userDetails.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={handleSave} variant='contained' color='primary' data-testid='saveBtn'>
              {t('userDetails.save')}
            </Button>
            <SnackBarNotification
              open={snackbar.open}
              message={snackbar.message}
              isSuccess={snackbar.isSuccess}
              onClose={handleCloseSnackbar}
              dialogClosingTime={dialogClosingTime}
            />
          </Grid>
        </Grid>
      </Grid>
      <div style={{ borderBottom: '1px solid #ccc', marginBottom: '30px' }}></div>
      <UserFormFields
        formData={formData}
        errors={errors}
        setFormData={setFormData}
        setErrors={setErrors}
      />
      <UserProfilePicture
        formData={formData}
        setFormData={setFormData}
        handleOpenSnackbar={() =>
          handleOpenSnackbar(t('userDetails.snackboxImageErrorMessage'), false)
        }
        uploadProfilePicture={uploadProfilePicture}
      />
    </div>
  );
};
export default UserDetails;
