import {
  AppBar,
  Slide,
  useScrollTrigger,
  Typography,
  CssBaseline,
  Grid,
  Link
} from '@material-ui/core';
import { AttachmentFileType } from 'components/Sections/Attachments/interfaces';
import { AttachmentViewerContext } from 'context/AttachmentViewer/AttachmentViewerContext';
import useT from 'hooks/useT';
import React, { useContext } from 'react';
import { useStyles } from './styles';
import { AppContext } from 'context/App/AppContext';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children?: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction='up' in={!trigger}>
      {children}
    </Slide>
  );
}

export default function FooterHartela(props: Props) {
  const { appState } = useContext(AppContext);
  const customizationSettings = appState.tenantSettings?.themeCustomizationSettings;
  const classes = useStyles({ hartela: appState.tenantSettings?.organisationId === 'hartela' })();
  const copyright = String.fromCharCode(169);
  const t = useT();

  const { attachmentViewerState, dispatch } = useContext(AttachmentViewerContext);
  // TODO: create env variable out of this
  const legalUrl = 'https://gbuilder.blob.core.windows.net/legal';

  return (
    <>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar position='fixed' className={classes.main}>
          <div
            style={
              appState.tenantSettings?.organisationId === 'hartela'
                ? { display: 'flex', flexDirection: 'row', margin: 'auto 0' }
                : {}
            }
          >
            {appState.tenantSettings?.organisationId === 'hartela' && customizationSettings && (
              <Grid item>
                <img
                  style={{
                    position: 'absolute',
                    marginLeft: '1rem',
                    filter: 'invert(1)',
                    margin: 'auto 0',
                    maxHeight: '40px'
                  }}
                  src={customizationSettings.assets?.logo}
                />
              </Grid>
            )}
            <Grid
              container
              spacing={4}
              direction='row'
              justifyContent='center'
              className={classes.links}
            >
              <Grid item>
                <Link
                  className={classes.link}
                  href={`${legalUrl}/GBuilder%20Terms%20of%20Use.pdf`}
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    dispatch({
                      ...attachmentViewerState,
                      open: true,
                      document: {
                        type: AttachmentFileType.PDF,
                        url: 'https://gbuilder.blob.core.windows.net/legal/GBuilder%20Terms%20of%20Use.pdf'
                      },
                      titleKey: 'footer.termsOfService'
                    });
                  }}
                >
                  <Typography>{t('footer.termsOfService')}</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  className={classes.link}
                  href={`${legalUrl}/GBuilder%20Privacy%20Policy.pdf`}
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    dispatch({
                      ...attachmentViewerState,
                      open: true,
                      document: {
                        type: AttachmentFileType.PDF,
                        url: 'https://gbuilder.blob.core.windows.net/legal/GBuilder%20Privacy%20Policy.pdf'
                      },
                      titleKey: 'footer.privacyPolicy'
                    });
                  }}
                >
                  <Typography>{t('footer.privacyPolicy')}</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  className={classes.link}
                  href='https://gbuilder.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Typography>{copyright}GBuilder</Typography>
                </Link>
              </Grid>
            </Grid>
          </div>
        </AppBar>
      </HideOnScroll>
    </>
  );
}
