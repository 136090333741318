import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: '0',
      padding: '0',
      position: 'relative',
      zIndex: 10
    },
    userActions: {
      color: theme.palette.navBarSecondary?.main || 'inherit',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }
  })
);
