import React from 'react';
import { Grid, TextField, MenuItem, GridSize } from '@material-ui/core';
import { countries } from './countries';
import useT from 'hooks/useT';
import { UserDetailsFormData } from './UserDetails';

interface UserFormFieldsProps {
  formData: UserDetailsFormData;
  errors: { [key: string]: boolean };
  setFormData: React.Dispatch<React.SetStateAction<UserDetailsFormData>>;
  setErrors: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
}
interface Field {
  space: number;
  name: string;
  handleBlur?: any;
  required?: boolean;
  readOnly?: boolean;
}
const UserFormFields: React.FC<UserFormFieldsProps> = ({ formData, errors, setFormData }) => {
  const t = useT();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'phoneNumber') {
      const cleanedValue = value.replace(/(?!^\+)[^0-9]/g, '');
      setFormData((prevData) => ({
        ...prevData,
        [name]: cleanedValue
      }));
    } else if (name === 'postCode') {
      const cleanedValue = value.replace(/[^0-9a-zA-Z]/g, '');
      setFormData((prevData) => ({
        ...prevData,
        [name]: cleanedValue
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        console.error('Invalid email format');
      }
    }
  };

  const fields: Field[] = [
    { space: 6, name: 'firstName', required: true },
    { space: 6, name: 'lastName', required: true },
    { space: 12, name: 'email', handleBlur: handleBlur, required: true, readOnly: true },
    { space: 12, name: 'phoneNumber', required: false },
    { space: 12, name: 'streetAddress', required: false },
    { space: 6, name: 'city', required: false },
    { space: 6, name: 'stateProvince', required: false },
    { space: 6, name: 'postCode', required: false }
  ];

  return (
    <Grid container spacing={2}>
      {fields.map((field: Field, index) => {
        return (
          <Grid key={index} item xs={field.space as GridSize} style={{ marginBottom: '15px' }}>
            <TextField
              name={field.name}
              label={t(`userDetails.${field.name}`)}
              variant='outlined'
              fullWidth
              required={field.required}
              value={formData[field.name as keyof UserDetailsFormData]}
              onChange={handleInputChange}
              onBlur={field.handleBlur}
              inputProps={{
                'data-testid': `${field.name}ID`,
                readOnly: field.readOnly || false
              }}
              disabled={field.readOnly || false}
              InputLabelProps={{ shrink: true }}
              error={!!errors[`${field.name}`]}
            />
          </Grid>
        );
      })}

      <Grid item xs={6} style={{ marginBottom: '15px' }}>
        <TextField
          name='country'
          label={t('userDetails.country')}
          variant='outlined'
          fullWidth
          required
          value={formData.country}
          onChange={handleInputChange}
          select
          InputLabelProps={{ shrink: true }}
          inputProps={{ 'data-testid': 'countryID' }}
          error={!!errors.country}
        >
          {countries.map((country, index) => (
            <MenuItem key={index} value={country.name}>
              {country.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default UserFormFields;
