import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Progress from 'components/Progress/Progress';
import useT from 'hooks/useT';
import Section from '../Section';
import styles from './styles';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  loading?: boolean;
  title?: string;
  subtitle?: string;
  section?: Section;
  error?: boolean;
}

const SectionWrapper: React.FC<Props> = (props: Props) => {
  const { title, subtitle, loading, error, children, className, section } = props;
  const classes = styles();
  const t = useT();

  const renderContent = () => {
    if (loading) return <Progress />;
    else if (error || !children) {
      const alertProps = error ? section?.alertProps.error : section?.alertProps.noItems;

      return (
        <Alert variant='outlined' severity={alertProps?.severity} className='alert'>
          {t(alertProps?.txtKey as string)}
        </Alert>
      );
    }

    return children;
  };

  return (
    <div className={className}>
      {title && (
        <div className={classes.heading}>
          <Typography variant='h5'>{title}</Typography>

          {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
        </div>
      )}

      {renderContent()}
    </div>
  );
};

export default SectionWrapper;
