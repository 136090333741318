import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => {
  return createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      margin: '1rem 0'
    }
  });
});
