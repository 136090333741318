import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    buttonArea: {
      alignSelf: 'self-start'
    },
    createMessageButton: {
      borderRadius: 0,
      padding: '0.75rem 3rem 0.75rem',
      marginBottom: '0.5rem'
    },
    createMessageButtonText: {
      fontSize: theme.typography.overline.fontSize,
      fontWeight: theme.typography.fontWeightLight,
      textTransform: 'none'
    } as CSSProperties,
    threadOverviewHeader: {
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.common.white,
      zIndex: 10
    }
  })
);
