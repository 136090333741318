import Avatar from '@material-ui/core/Avatar';
import useStyles from './styles';

const OwnerAvatar = ({
  firstName,
  lastName,
  imageUrl,
  pictureSize,
  fontSize
}: {
  firstName: string;
  lastName: string;
  imageUrl?: string;
  pictureSize?: string;
  fontSize?: string;
}) => {
  const classes = useStyles({ pictureSize, fontSize });
  const firstLetterOfFirstName = firstName[0];
  const firstLetterOfLastName = lastName[0];
  const initials = `${firstLetterOfFirstName}${firstLetterOfLastName}`.toUpperCase();
  if (imageUrl) {
    return <Avatar className={classes.pictureUrlStyle} src={imageUrl}></Avatar>;
  }
  return <Avatar className={classes.avatar}>{initials}</Avatar>;
};

export default OwnerAvatar;
