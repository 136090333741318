import { Grid, Typography } from '@material-ui/core';
import useT from 'hooks/useT';
import { useContext } from 'react';
import { ChatStateContext } from 'store/chatState/ChatStateProvider';
import RoomThread from './RoomThread/RoomThread';
import styles from './styles';

export function RoomThreads() {
  const classes = styles();
  const { chatState } = useContext(ChatStateContext);
  const t = useT();

  return chatState.threads.length !== 0 ? (
    <>
      <Grid item className={classes.threadsContainer}>
        {chatState.threads.map((thread, index) => {
          return thread.messages.length ? (
            <RoomThread key={`room-thread-${index}`} {...thread} />
          ) : null;
        })}
      </Grid>
    </>
  ) : (
    <Grid>
      <Typography>{t('chat.noChatMessages')}</Typography>
    </Grid>
  );
}
