import Section, { SectionType } from '../Section';

export default class MaterialSelectionsSection extends Section {
  content?: string;

  constructor(props: any) {
    super(props);

    this.type = SectionType.MATERIAL_SELECTIONS;
    this.actionButtonTxtKey = 'materialSelection.openLink';
    this.content = props.description;
    this.description = '';
  }
}
