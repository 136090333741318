import { DetailedListItem, IconKeys } from '@groupbuilderoy/gb-components-library';
import SectionCard from 'components/Sections/SectionCard/SectionCard';
import useGetContacts from 'hooks/useGetContacts';
import ContactsSection from './ContactsSection';

interface Props {
  section: ContactsSection;
}

const ContactsCard = ({ section }: Props) => {
  const { loading, error, contacts } = useGetContacts(section.id);

  return (
    <SectionCard
      section={section}
      loading={loading}
      error={error}
      noActionButton={!contacts.length}
    >
      {contacts.length && (
        <ul>
          {contacts.slice(0, section.listMaxCount).map((contact: any, index: number) => {
            const { name, jobTitle, imageUrlSmall, phoneNumber, email } = contact;

            return (
              <DetailedListItem
                key={index}
                image={imageUrlSmall || undefined}
                icon={!imageUrlSmall ? IconKeys.contact : undefined}
                title={name as string}
                subtitle={jobTitle || undefined}
                phone={phoneNumber || undefined}
                email={email || undefined}
              />
            );
          })}
        </ul>
      )}
    </SectionCard>
  );
};

export default ContactsCard;
