import { useContext } from 'react';
import { Grid, Card, CardContent, Typography, CardActionArea } from '@material-ui/core';
import useStyles from './styles';
import ApartmentIcon from '@material-ui/icons/Apartment';
import HomeIcon from '@material-ui/icons/Home';
import { ProjectContext } from 'context/Project/ProjectContext';
import { OwnerSearchInterface } from 'hooks/useGetOwnerSearch';

interface ApartmentDetailCardProps {
  apartmentOwner: OwnerSearchInterface;
  index: number;
  handleSelectApartment: any;
}

const ApartmentDetailCard = ({
  apartmentOwner,
  index,
  handleSelectApartment
}: ApartmentDetailCardProps) => {
  const { projectState } = useContext(ProjectContext);
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6}>
      <Card
        className={classes.apartmentCard}
        onClick={() => handleSelectApartment(apartmentOwner)}
        data-testid='apartment-card'
      >
        <CardActionArea style={{ height: '100%' }}>
          <CardContent className={classes.ownerDetails}>
            <div className={classes.projectNameItem}>
              <ApartmentIcon className={classes.apartmentItemIcon} />
              <Typography
                className={classes.body2Title}
                data-testid={`project-${index}`}
                color='textPrimary'
              >
                {
                  projectState.projects.find((project) => project.id === apartmentOwner.projectId)
                    ?.name
                }
              </Typography>
            </div>
            <div className={classes.apartmentNameItem}>
              <HomeIcon className={classes.apartmentItemIcon} />
              <Typography
                className={classes.body2Title}
                data-testid={`apartment-${index}`}
                color='textPrimary'
              >
                {apartmentOwner.apartmentName}
              </Typography>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default ApartmentDetailCard;
