import { AttachmentType } from 'components/Sections/Attachments/interfaces';
import { Attachment } from 'components/Sections/Section';
import { AttachmentViewerContext } from 'context/AttachmentViewer/AttachmentViewerContext';
import { useContext, useEffect } from 'react';
import { getAttachmentFileType, stripContentAttachmentName } from 'utils/sections';
import styles from './styles';

export interface Props {
  content: string;
  attachments?: Attachment[];
}

export default function HtmlContentViewer({ content, attachments }: Props) {
  const classes = styles();
  const { attachmentViewerState, dispatch } = useContext(AttachmentViewerContext);

  const setupAttachmentLinks = () => {
    const attachmentsInContent = document.querySelectorAll('attachment');

    attachmentsInContent.forEach((attachmentElement) => {
      const attachmentName = stripContentAttachmentName(String(attachmentElement.textContent));
      attachmentElement.textContent = attachmentName;

      const attachment = attachments?.find((a) => a.name === attachmentName);

      if (!attachment) return;

      (attachmentElement as HTMLElement).onclick = () => {
        dispatch({
          ...attachmentViewerState,
          open: true,
          document: {
            type: getAttachmentFileType({ mimeType: attachment.mimeType } as AttachmentType),
            url: String(attachment?.url),
            name: attachmentName
          }
        });
      };
    });
  };

  useEffect(() => {
    setupAttachmentLinks();
  }, []);

  return <div className={classes.viewer} dangerouslySetInnerHTML={{ __html: content }} />;
}
