export interface FormQuestion {
  id: number;
  tenantId: string;
  formId: number;
  question: string;
  description: string;
  answerType: AnswerType;
  isMandatory: boolean;
  defaultNumberValue: number | null;
  defaultTextValue: string | null;
  unit: string | null;
  sortNo: number;
  questionOptions: any[]; // You can replace 'any' with a more specific type if needed
  groupId: number | null;
}

export enum AnswerType {
  Text = 'text',
  Boolean = 'bool',
  Number = 'number',
  Consent = 'consent'
}

export interface FormQuestionGroup {
  id: number;
  name: string;
  questions: FormQuestion[];
}

export interface FilledFormAnswer {
  id: number;
  tenantId: string;
  formFilledId: number;
  questionId: number;
  question: string;
  questionDescription?: string;
  numberValue: number | null;
  value: string | null;
  unit: string | null;
}

export interface FilledForm {
  id: number;
  tenantId: string;
  name: string;
  description: string;
  note: string;
  state: string;
  formId: number;
  apartmentId: number;
  answers: FilledFormAnswer[];
  parentName?: string;
}

export interface Form {
  id: number;
  tenantId: string;
  name: string;
  description: string | null;
  defaultFilledFormName: string;
  hasTimeConstraint: boolean;
  state: string;
  questions: FormQuestion[];
  questionGroups: FormQuestionGroup[];
  filledForms: FilledForm[];
}

export interface FormsSection {
  name: string;
  description: string | null;
  forms: Form[];
}
