import { Grid, Typography } from '@material-ui/core';
import useT from 'hooks/useT';
import { useContext } from 'react';
import { ChatStateContext } from 'store/chatState/ChatStateProvider';
import Bulletin from './Bulletin/Bulletin';

import styles from './styles';

export default function Bulletins() {
  const classes = styles();
  const { chatState } = useContext(ChatStateContext);
  const t = useT();

  return chatState.bulletins.length !== 0 ? (
    <>
      <Grid item className={classes.threadsContainer}>
        {chatState.bulletins.map((bulletin, index) => {
          return <Bulletin key={`bulletin-list-item-${index}`} {...(bulletin as any)} />;
        })}
      </Grid>
    </>
  ) : (
    <Grid>
      <Typography>{t('chat.noChatMessages')}</Typography>
    </Grid>
  );
}
