import { baseUrl } from 'axiosInstances';
import { AppContext } from 'context/App/AppContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import i18next from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { CustomerJourneyApi } from 'services/CustomerJourneyApi';

export default function useApi(): CustomerJourneyApi {
  const { appState } = useContext(AppContext);
  const { projectState } = useContext(ProjectContext);
  const { token, startParameters } = appState;
  const { organisationId, tenantId } = startParameters;
  const [projectId, setProjectId] = useState<number>(Number(projectState.selectedProject?.id));

  useEffect(() => {
    if (!projectState.selectedProject) return;

    setProjectId(Number(projectState.selectedProject.id));
  }, [projectState.selectedProject?.id]);

  return new CustomerJourneyApi({
    apiUrl: baseUrl,
    token: String(token),
    organisation: String(organisationId),
    tenant: String(tenantId),
    projectId,
    lang: i18next.language
  });
}
