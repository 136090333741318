import {
  DialogContent,
  DialogTitle,
  Dialog,
  Grid,
  Typography,
  IconButton,
  Link
} from '@material-ui/core';
import useStyles from './styles';
import CloseIcon from '@material-ui/icons/Close';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import { useContext } from 'react';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import { useHistory } from 'react-router-dom';
import { OwnerSearchInterface } from 'hooks/useGetOwnerSearch';
import { useTrackers } from 'hooks/useTrackers';
import { EventAction } from 'services/EventAction';
import { EventCategory } from 'services/EventCategory';
import OwnerAvatar from 'components/ApartmentOwners/OwnerAvatar/OwnerAvatar';
import ApartmentDetailCard from './ApartmentDetailCard';
interface OwnerDialogInterface {
  handleCloseDialog: () => void;
  openDialog: boolean;
  owner: OwnerSearchInterface;
  apartmentOwners: OwnerSearchInterface[];
}

const ApartmentDialog = ({
  handleCloseDialog,
  openDialog,
  owner,
  apartmentOwners
}: OwnerDialogInterface) => {
  const classes = useStyles();
  const history = useHistory();
  const { triggerEvent } = useTrackers();
  const { apartmentState, dispatch } = useContext(ApartmentContext);

  const handleSelectApartment = (apartmentOwner: OwnerSearchInterface) => {
    const apartment = apartmentState.apartments.find(
      (apartment) => apartment.id === apartmentOwner.apartmentId
    );
    if (!apartment || apartment.id === apartmentState.selectedApartment?.id) return;
    dispatch({
      ...apartmentState,
      selectedApartment: apartment,
      loadDetails: true
    });
    triggerEvent({
      action: EventAction.CHANGE_APARTMENT,
      category: EventCategory.USER
    });
    const path = `project/${apartment.projectId}/apartment/${apartment.id}`;
    history.push(path);
  };

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.dialogTitleInfoContainer}>
          <div className={classes.dialogTitleUserInfo}>
            <Typography className={classes.nameTitle}>
              {owner.firstName} {owner.lastName}
            </Typography>
            <div className={classes.contactDetails}>
              <PhoneIcon
                className={classes.contactDetailsIcon}
                fontSize='small'
                data-testid='phone-icon'
              />
              <Link href={`tel:${owner.phoneNumber}`} className={classes.linkTitle}>
                <Typography>{owner.phoneNumber}</Typography>
              </Link>
            </div>
            <div className={classes.contactDetails}>
              <EmailIcon
                className={classes.contactDetailsIcon}
                fontSize='small'
                data-testid='email-icon'
              />

              <Link href={`mailto:${owner.email}`} className={classes.linkTitle}>
                <Typography>{owner.email}</Typography>
              </Link>
            </div>
          </div>
          <div>
            <OwnerAvatar
              firstName={owner.firstName}
              lastName={owner.lastName}
              imageUrl={owner.picture}
              pictureSize='80px'
              fontSize='1.5rem'
            />
          </div>
          <IconButton
            onClick={handleCloseDialog}
            className={classes.iconButton}
            data-testid='close-button'
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={4}>
          {apartmentOwners
            .filter((apartmentOwner) => apartmentOwner.email === owner.email)
            .map((apartmentOwner, index) => (
              <ApartmentDetailCard
                apartmentOwner={apartmentOwner}
                index={index}
                handleSelectApartment={handleSelectApartment}
                key={index}
              />
            ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default ApartmentDialog;
