import { createContext, Dispatch } from 'react';
import { ModalMessageState } from './interfaces';

export const initialModalMessageState: ModalMessageState = {
  title: '',
  description: '',
  isOpen: false,
  onApprove: () => {},
  onCancel: () => {},
  icon: undefined
};

export const ModalMessageContext = createContext<{
  modalMessageState: ModalMessageState;
  dispatch: Dispatch<ModalMessageState>;
}>({
  modalMessageState: initialModalMessageState,
  dispatch: () => initialModalMessageState
});
