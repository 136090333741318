import { Grid } from '@material-ui/core';
import useT from 'hooks/useT';
import { localFormatDateTime } from 'utils/localFormatDateTime';
import { LocalFormatDateTimeType } from '../../interfaces';

const CalendarEventDateDisplay = ({
  startTime,
  endTime,
  title
}: {
  startTime: string;
  endTime: string;
  title: string;
}) => {
  const t = useT();
  // const formattedStartDate = format(new Date(startTime), 'MMMM d, yyyy', {
  //   locale: getDateFnsLocale()
  // });
  // const formattedEndDate = format(new Date(endTime), 'MMMM d, yyyy', {
  //   locale: getDateFnsLocale()
  // });
  // const formattedStartTime = format(new Date(startTime), 'HH:mm a', { locale: getDateFnsLocale() });
  // const formattedEndTime = format(new Date(endTime), 'HH:mm a', { locale: getDateFnsLocale() });

  const formattedStartDate = localFormatDateTime(new Date(startTime), LocalFormatDateTimeType.Date);
  const formattedEndDate = localFormatDateTime(new Date(endTime), LocalFormatDateTimeType.Date);
  const formattedStartTime = localFormatDateTime(new Date(startTime), LocalFormatDateTimeType.Time);
  const formattedEndTime = localFormatDateTime(new Date(endTime), LocalFormatDateTimeType.Time);

  return (
    <Grid item xs={12}>
      <strong>{t(`${title}`)}:</strong> {formattedStartDate} {formattedStartTime} -{' '}
      {new Date(startTime).setHours(0, 0, 0, 0) === new Date(endTime).setHours(0, 0, 0, 0)
        ? formattedEndTime
        : `${formattedEndDate} ${formattedEndTime}`}
    </Grid>
  );
};

export default CalendarEventDateDisplay;
