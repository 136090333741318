import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    date: {
      paddingTop: theme.spacing(1),
      fontSize: theme.typography.overline.fontSize,
      fontWeight: theme.typography.fontWeightLight
    } as CSSProperties,
    senderName: {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightMedium
    } as CSSProperties,
    content: {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightLight
    } as CSSProperties,
    root: {
      '&.right': {
        textAlign: 'right'
      }
    }
  })
);
