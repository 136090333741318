import React, { useMemo, useReducer } from 'react';
import {
  ApartmentContextProviderProps,
  ApartmentContextReducerType,
  ApartmentState
} from './interfaces';
import { initialApartmentState, ApartmentContext } from './ApartmentContext';

const reduce = (
  previousContext: ApartmentState,
  contentextUpdate: ApartmentState
): ApartmentState => {
  return {
    ...previousContext,
    ...contentextUpdate
  };
};

const ApartmentContextProvider: React.FC<ApartmentContextProviderProps> = ({ children }) => {
  const [apartmentState, dispatch] = useReducer<ApartmentContextReducerType>(
    reduce,
    initialApartmentState
  );

  const contextValue = useMemo(() => ({ apartmentState, dispatch }), [apartmentState, dispatch]);

  return <ApartmentContext.Provider value={contextValue}>{children}</ApartmentContext.Provider>;
};

export default ApartmentContextProvider;
