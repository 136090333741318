import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import { useContext, useEffect, useState } from 'react';

export default function useBasePath(): string {
  const { projectState } = useContext(ProjectContext);
  const { apartmentState } = useContext(ApartmentContext);
  const { selectedProject } = projectState;
  const { selectedApartment } = apartmentState;
  const [path, setPath] = useState<string>('');

  useEffect(() => {
    if (!selectedApartment || !selectedProject) return;

    setPath(`/project/${selectedProject?.id}/apartment/${selectedApartment?.id}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApartment?.id, selectedProject?.id]);

  return path;
}
