import { useContext, useEffect, useRef, useState } from 'react';
import useStyles from './styles';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import Progress from 'components/Progress/Progress';
import { AttachmentViewerContext } from 'context/AttachmentViewer/AttachmentViewerContext';
import { AttachmentType } from 'components/Sections/Attachments/interfaces';

const viewerConfig = {
  path: '/webviewer/lib',
  licenseKey: process.env.PDFTRON_LICENSE_KEY,
  fullAPI: true,
  disabledElements: ['ribbons', 'textFieldToolGroupButton', 'header', 'toolsHeader']
};

interface SignableDocumentProps {
  document: AttachmentType;
}

export default function EmbeddedDocument({ document }: SignableDocumentProps): JSX.Element {
  const { url, name } = document;
  const classes = useStyles();
  const viewerDiv = useRef<HTMLDivElement | null>(null);
  const [instance, setInstance] = useState<WebViewerInstance | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { attachmentViewerState, dispatch } = useContext(AttachmentViewerContext);

  const setDownloadLink = async (instance: WebViewerInstance) => {
    const { documentViewer } = instance.Core;

    const data = await documentViewer.getDocument().getFileData();
    const arr = new Uint8Array(data);
    const url = URL.createObjectURL(new Blob([arr], { type: 'application/pdf' }));

    dispatch({
      ...attachmentViewerState,
      downloadLink: url,
      showDownloadButton: true
    });
  };

  const documentLoaded = (instance: WebViewerInstance) => {
    setLoading(false);
    setDownloadLink(instance);
  };

  useEffect(() => {
    if (instance || !viewerDiv.current || loading) return;

    setLoading(true);

    WebViewer(viewerConfig, viewerDiv.current).then((instance: WebViewerInstance) => {
      setInstance(instance);

      const { Core, UI } = instance;
      const { documentViewer } = Core;

      UI.loadDocument(url, { filename: name });

      documentViewer.addEventListener('documentLoaded', async () => documentLoaded(instance));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      {loading && <Progress />}

      <div
        ref={viewerDiv}
        data-testid='viewer-div'
        className={`${classes.pdfViewer} ${loading ? ' loading' : ''}`}
      />
    </>
  );
}
