import { AxiosResponse } from 'axios';
import { instance } from 'axiosInstances';
import { BulletinType, ChatMessageType } from 'components/Chat/types';
import { MessageDataType, PostMessageResponseType } from './types';
import { createConfig } from './utils';

interface FetchRoomMessagesParams {
  gbToken: string;
  roomId: string;
}

interface FetchRoomMessagesResponse {
  data: ChatMessageType[];
}

export const fetchRoomMessages = ({
  gbToken,
  roomId
}: FetchRoomMessagesParams): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<AxiosResponse<FetchRoomMessagesResponse>> =>
  instance.get(`/v1/private/rooms/${roomId}/messages`, createConfig({ token: gbToken }));
interface FetchBulletinsParams {
  gbToken: string;
  apartmentId: string;
}

interface FetchBulletinsResponse {
  data: BulletinType[];
}

export const fetchBulletins = ({
  gbToken,
  apartmentId
}: FetchBulletinsParams): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<AxiosResponse<FetchBulletinsResponse>> =>
  instance.get(
    `/v1/private/apartments/${apartmentId}/mass-messages`,
    createConfig({ token: gbToken })
  );

export const readBulletin = async (apartmentId: string, massMessageId: string) => {
  const response = await instance.put(
    `/v3/private/apartments/${apartmentId}/mass-messages/${massMessageId}/received`
  );

  return response.data;
};

interface PostRoomMessagesParams {
  gbToken: string;
  roomId: string;
  messageData: MessageDataType;
}

export const postRoomMessages = async ({
  gbToken,
  roomId,
  messageData
}: PostRoomMessagesParams): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<PostMessageResponseType> => {
  const { data } = await instance.post(
    `/v1/private/rooms/${roomId}/messages`,
    messageData,
    createConfig({ token: gbToken })
  );
  return data.data;
};
