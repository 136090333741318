import { AppContext } from 'context/App/AppContext';
import { TOptions } from 'i18next';
import { useContext } from 'react';
import { t } from '../utils/translation';

const useT = () => {
  const { appState } = useContext(AppContext);

  return (translationString: string, options?: TOptions) =>
    t(translationString, appState.startParameters.organisationId || 'gbuilder', options);
};

export default useT;
