import useGetChecklistItems from 'hooks/useGetChecklistItems';
import useT from 'hooks/useT';
import { Alert, Color } from '@material-ui/lab';
import styles from '../styles';
import { ChecklistAccordion } from '@groupbuilderoy/gb-components-library';
import { useContext, useState } from 'react';
import { ProjectContext } from 'context/Project/ProjectContext';
import { UserRight } from 'context/App/types';
import { approveChecklistItem } from 'axiosInstances';
import { AppContext } from 'context/App/AppContext';
import useStyles from './styles';
import { SectionContext } from 'context/Section/SectionContext';
import SectionWrapper from 'components/Sections/SectionWrapper/SectionWrapper';
import ChecklistSection from '../ChecklistSection';
import { IssueState } from 'axiosInstances/instances/CheckListResponse';
import { openAttachmentModal } from 'utils/sections';
import { AttachmentType } from 'components/Sections/Attachments/interfaces';
import { AttachmentViewerContext } from 'context/AttachmentViewer/AttachmentViewerContext';

interface Props {
  section: ChecklistSection;
}

export default function ChecklistAccordions({ section }: Props) {
  const { dispatch: dispatchAttachmentViewerState } = useContext(AttachmentViewerContext);
  const [approveError, setApproveError] = useState<string | null>(null);
  const [approveSuccess, setApproveSuccess] = useState(false);
  const { appState } = useContext(AppContext);
  const { projectState } = useContext(ProjectContext);
  const { sectionState, dispatch } = useContext(SectionContext);
  const [approvingItemId, setApprovingItemId] = useState<number>(-1);
  const classes = useStyles();
  const mainClasses = styles();
  const {
    checklistItems: checklist,
    fetchingChecklistItems,
    error
  } = useGetChecklistItems(section);
  const t = useT();
  const approvingRights = projectState.userRights?.check(UserRight.checklistUpdate);

  const getAccordionTableLabels = (status: IssueState) => {
    const labels = ['details', 'room', 'date', 'photos', 'comments'];
    if (status === IssueState.COMPLETED) labels.push('approve');

    const localizedLabels = {} as any;

    labels.forEach((label) => {
      localizedLabels[label] = t('checklist.accordions.' + label);
    });
    return localizedLabels;
  };

  const approveItemClicked = async (roomId: number, itemId: number) => {
    setApproveError(null);
    setApproveSuccess(false);
    setApprovingItemId(itemId);

    try {
      await approveChecklistItem(String(appState.token), roomId, itemId);

      dispatch({ ...sectionState, update: true });
      setApproveSuccess(true);
    } catch (e) {
      console.error(e);
      setApproveError(t('checklist.alert.approvalFailed'));
    } finally {
      setApprovingItemId(-1);
    }
  };

  const renderAlert = (text: string, severity: Color) => {
    return (
      <Alert variant='outlined' severity={severity} className={mainClasses.alert}>
        {text}
      </Alert>
    );
  };

  return (
    <SectionWrapper loading={fetchingChecklistItems} error={error} section={section}>
      {approveError && renderAlert(approveError, 'error')}
      {approveSuccess && renderAlert(t('checklist.alert.approvalSuccess'), 'success')}

      {checklist?.itemsWithStatus?.map((item: any, index: number) => {
        return (
          <ChecklistAccordion
            type={'outlined' as any}
            key={index}
            className={classes.accordion}
            data-testid='accordion'
            caption={t(`checklist.status.${item.status}`)}
            items={item.items}
            status={item.status}
            colorOverride={
              appState.tenantSettings?.organisationId === 'srv' ? '#f7f7f7' : undefined
            }
            labels={getAccordionTableLabels(item.status)}
            approve={
              approvingRights && item.status === IssueState.COMPLETED
                ? {
                    label: t('checklist.accordions.approve'),
                    approvingItemId: approvingItemId,
                    onClick: (roomId: number, itemId: number) => {
                      approveItemClicked(roomId, itemId);
                    }
                  }
                : undefined
            }
            onOpenImage={(image: string) => {
              const newAttachment: AttachmentType = {
                id: 1,
                url: image,
                name: '',
                mimeType: 'image'
              };
              openAttachmentModal(newAttachment, dispatchAttachmentViewerState);
            }}
          />
        );
      })}
    </SectionWrapper>
  );
}
