import useStyles from './styles';
import OwnershipView from 'components/ApartmentOwners/OwnershipView';
import useT from 'hooks/useT';
import { useContext, useEffect, useState } from 'react';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import { Button } from '@material-ui/core';
import OwnerDialog from 'components/ApartmentOwners/OwnerDialog/OwnerDialog';
import Add from '@material-ui/icons/Add';
import userGetApartmentOwners, { OwnerInterface } from 'hooks/useGetApartmentOwners';
import Progress from 'components/Progress/Progress';

const ApartmentOwners = () => {
  const classes = useStyles();
  const { apartmentState } = useContext(ApartmentContext);
  const { selectedApartment } = apartmentState;
  const t = useT();
  const [openDialog, setOpenDialog] = useState(false);
  const { owners, loading } = userGetApartmentOwners();
  const [ownerUsers, setOwnerUsers] = useState<OwnerInterface[]>([]);

  useEffect(() => {
    setOwnerUsers(owners);
  }, [owners]);

  const handleDialogClick = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  if (loading) return <Progress />;
  return (
    <div className={classes.container}>
      <h1 className={classes.header}>
        {t('keyWaiver.apartment')} {selectedApartment?.name}
      </h1>
      <Button
        data-testid='add-btn'
        onClick={handleDialogClick}
        className={classes.addButton}
        variant='contained'
        color='primary'
      >
        <Add style={{ marginRight: '4px' }} fontSize='small' />
        {t('keyWaiver.addNew')}
      </Button>
      <OwnershipView ownerUsers={ownerUsers} setOwnerUsers={setOwnerUsers} />
      <OwnerDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        isEditing={false}
        setOwnerUsers={setOwnerUsers}
      />
    </div>
  );
};

export default ApartmentOwners;
