import { useContext } from 'react';
import { useHistory } from 'react-router';
import useBasePath from 'hooks/useBasePath';
import { Tabs } from '@groupbuilderoy/gb-components-library';
import { AppContext } from 'context/App/AppContext';
import { PhaseContext } from 'context/Phase/PhaseContext';
import { SectionContext } from 'context/Section/SectionContext';
import { useTrackers } from 'hooks/useTrackers';
import { EventCategory } from 'services/EventCategory';
import { EventAction } from 'services/EventAction';

function PhaseSelect() {
  const { triggerEvent } = useTrackers();
  const history = useHistory();
  const basePath = useBasePath();
  const { appState } = useContext(AppContext);
  const { phaseState, dispatch } = useContext(PhaseContext);
  const { sectionState, dispatch: dispatchSection } = useContext(SectionContext);

  const handlePhaseChange = async (phaseId: number) => {
    const phase = phaseState.phases.find((phase) => phase.id === phaseId);

    if (!phase || phase.id === phaseState.selectedPhase?.id) return;

    history.push(`${basePath}/phase/${phase.id}`);

    dispatch({ ...phaseState, selectedPhase: phase });
    dispatchSection({ ...sectionState, sections: [], loading: true });
    triggerEvent({
      action: EventAction.PHASE_CLICKED,
      category: EventCategory.USER
    });
  };
  const tabs = phaseState.phases.map((phase) => {
    return {
      id: phase.id,
      caption: phase.name,
      icon: undefined,
      iconUrl: phase.icon
    };
  });
  const selectedTabIndex = tabs.findIndex((tab) => tab.id === phaseState.selectedPhase?.id);

  if (tabs.length === 1) return null;
  return (
    <>
      {tabs.length ? (
        <Tabs
          tabs={tabs}
          onClick={handlePhaseChange}
          initialSelectedTab={selectedTabIndex !== -1 ? selectedTabIndex : undefined}
          iconsFolderUrl={`/Icons/PhaseIcons/${appState.tenantSettings?.cjCompanyResourcesFolder}`}
        />
      ) : null}
    </>
  );
}

export default PhaseSelect;
