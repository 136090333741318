import { Typography } from '@material-ui/core';
import SectionCard from 'components/Sections/SectionCard/SectionCard';
import { AppContext } from 'context/App/AppContext';
import { useGetCJ2Url } from 'hooks/useGetCJ2Url';
import useT from 'hooks/useT';
import { useContext } from 'react';
import MaterialSelectionsSection from './MaterialSelectionSection';
import defaultImage from './buildingMaterialsDefault.jpg';
import { ProjectContext } from 'context/Project/ProjectContext';
import { useHistory } from 'react-router-dom';

interface Props {
  section: MaterialSelectionsSection;
}

const MaterialSelectionCard = ({ section }: Props) => {
  const t = useT();
  const { appState } = useContext(AppContext);
  const { projectState } = useContext(ProjectContext);
  const history = useHistory();
  const cj2Image = appState.tenantSettings?.cjMaterialSelectionImage;
  const CJ2Url = useGetCJ2Url();

  return (
    <SectionCard
      section={section}
      onActionButtonClick={() => {
        appState.tenantSettings?.customerJourney2_isActive &&
        projectState.projectSettings?.['cj2IntegratedIntoCj3']
          ? history.push('/selections')
          : window.open(CJ2Url, '_blank')?.focus();
      }}
    >
      <img width='100%' alt={t('buildingMaterials')} src={cj2Image || defaultImage} />

      <Typography style={{ margin: '10px 0' }}>{section.content}</Typography>
    </SectionCard>
  );
};

export default MaterialSelectionCard;
