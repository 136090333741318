import Section, { Attachment, ICustomContentSection, SectionType } from '../Section';

export default class CustomContentSection extends Section implements ICustomContentSection {
  content: string;
  preview: string;
  attachments: Attachment[];

  constructor(props: any) {
    super(props);

    this.type = SectionType.CUSTOM_CONTENT;
    this.alertProps.error.txtKey = 'customContent.fetchFailed';
    this.alertProps.noItems.txtKey = 'customContent.noContent';
    this.content = props.content;
    this.preview = props.preview;
    this.attachments = props.attachments;
  }
}
