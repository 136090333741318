import { StartParametersInterface } from 'context/App/interfaces';

/* eslint-disable */
export enum StartParameter {
  tenantId = 'tenantId',
  projectId = 'projectId',
  apartmentId = 'apartmentId',
  organisationId = 'organisationId',
  lan = 'lan',
  accessToken = 'accessToken',
  authToken = 'authToken',
  gbToken = 'gbToken',
  user = 'user',
  editingMode = 'editingMode'
}
/* eslint-enable */

// Parameters to be stored in session storage
const sessionParametersArray: string[] = [
  StartParameter.tenantId,
  StartParameter.projectId,
  StartParameter.apartmentId,
  StartParameter.organisationId,
  StartParameter.accessToken,
  StartParameter.authToken,
  StartParameter.editingMode
];

const mandatoryParametersArray: string[] = [
  StartParameter.tenantId,
  StartParameter.projectId,
  StartParameter.apartmentId,
  StartParameter.organisationId,
  StartParameter.authToken
];

export const i18nextLng = 'i18nextLng';

// Check that all mandatory params are defined
// Reports missing parameters to console
const checkMandatoryStartParams = (params: { [k: string]: string }) => {
  const isUndefinedOrEmpty = (param: string) => !param || param.length == 0;

  mandatoryParametersArray.forEach((mp) => {
    if (isUndefinedOrEmpty(params[mp]))
      console.error(
        `Mandatory start/URL param '${mp}' missing or it has invalid value: ${params[mp]}`
      );
  });
};

export const getStartParameters: () => StartParametersInterface = () => {
  const urlParams = Object.fromEntries(new URLSearchParams(window.location.search.substring(1)));

  if (urlParams[StartParameter.lan]) {
    localStorage.setItem(i18nextLng, urlParams[StartParameter.lan]);
  }

  const newParams = !!Object.keys(urlParams).length;

  const sessionStorageParams: { [x: string]: string } | null = newParams
    ? null
    : sessionParametersArray.reduce((lsParams, cur) => {
        const param = sessionStorage.getItem(cur);
        if (param) lsParams[cur] = param;
        return lsParams;
      }, {} as { [x: string]: string });

  if (newParams) {
    sessionParametersArray.forEach((param) => sessionStorage.removeItem(param));
  }

  for (const [key, value] of Object.entries(urlParams)) {
    if (!sessionParametersArray.includes(key)) {
      delete urlParams[key];
    } else sessionStorage.setItem(key, value);
  }

  const startParams = { ...sessionStorageParams, ...urlParams };

  // Check that mandatory params are available and print to console if it isnt so
  checkMandatoryStartParams(startParams);

  let path = window.location.pathname;
  if ((path && !path.length) || path === '/') {
    path += `project/${startParams.projectId}`;
  }

  window.history.replaceState(null, '', `${path}`);

  return startParams;
};

export default StartParameter;
