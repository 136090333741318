import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(5)
  },
  formContainer: {
    marginBottom: theme.spacing(5)
  },
  textField: {
    marginBottom: theme.spacing(5)
  },
  fileDropzone: {
    textAlign: 'center'
  },
  uploadIcon: {
    fontSize: 48,
    marginBottom: theme.spacing(5)
  },
  fileList: {
    marginTop: theme.spacing(5)
  },
  listItem: {
    marginBottom: theme.spacing(5)
  },
  buttonGroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '24px',
    paddingLeft: '16px'
  },
  rightMargin: {
    marginRight: '10px',
    fontSize: theme.typography.overline.fontSize
  },
  button: {
    fontSize: theme.typography.overline.fontSize
  },
  avatar: {
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    objectFit: 'cover'
  },
  buttonDelete: {
    justifyContent: 'flex-start',
    position: 'absolute'
  },
  titleStyle: {
    color: theme.palette.sectionsHeader?.main
  }
}));

export default useStyles;
