import { useContext } from 'react';
import { AppContext } from 'context/App/AppContext';
import i18next from 'i18next';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import IntegratedApp from 'components/IntegratedApp/IntegratedApp';
import { ProjectContext } from 'context/Project/ProjectContext';

export default function ThreeD() {
  const { appState } = useContext(AppContext);
  const { projectState } = useContext(ProjectContext);
  const { apartmentState } = useContext(ApartmentContext);
  const { selectedApartment } = apartmentState;
  const { token, startParameters, tenantSettings } = appState;
  const { projectId, tenantId, organisationId } = startParameters;

  const bimVaultUrl =
    projectState.projectSettings?.['bimvaultVersion'] === 'BIMVAULT2' &&
    tenantSettings?.bimvault2Url
      ? tenantSettings?.bimvault2Url
      : tenantSettings?.bimVaultApiUrl;
  const threeDUrl =
    tenantSettings?.gb4dHdUrl +
    '?projectId=' +
    projectId +
    '&apartmentId=' +
    selectedApartment?.id +
    '&bim=' +
    bimVaultUrl +
    '&schema=' +
    tenantId +
    '&company=' +
    organisationId +
    '&api=' +
    tenantSettings?.publicCoreApiUrl +
    '&stage=standalone' +
    '&lan=' +
    i18next.language +
    '&authToken=' +
    token;

  return <IntegratedApp appName='3D-ui' url={threeDUrl} />;
}
