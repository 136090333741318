import FolderSection from './FolderSection';
import SectionCard from 'components/Sections/SectionCard/SectionCard';
import { sectionIcons } from './IconsType';
import { FaFolderOpen } from 'react-icons/fa';
import { SectionType } from '../Section';
import { Button } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTrackers } from 'hooks/useTrackers';
import { useContext } from 'react';
import { SectionContext } from 'context/Section/SectionContext';
import { EventAction } from 'services/EventAction';
import { EventCategory } from 'services/EventCategory';
import styles from './styles';
import useGetSectionFolder from 'hooks/UseGetSectionFolder';
import useBasePath from 'hooks/useBasePath';
import { PhaseContext } from 'context/Phase/PhaseContext';

interface Props {
  section: FolderSection;
}

const FolderCard = ({ section }: Props) => {
  const classes = styles();
  const { loading } = useGetSectionFolder(section.id);
  const { sectionState, dispatch } = useContext(SectionContext);
  const childrenSections = sectionState.sections.filter(
    (s) => s.parentSectionId?.toString() === section.id.toString()
  );
  const { triggerEvent } = useTrackers();
  const { url } = useRouteMatch();
  const basePath = useBasePath();
  const history = useHistory();
  const { phaseState } = useContext(PhaseContext);
  const phaseUrl = !url.includes('phase')
    ? `${basePath}/phase/${phaseState.selectedPhase?.id}`
    : url;
  const newUrl = !phaseUrl.includes('section')
    ? `${phaseUrl}/section/${section.id}`
    : `${phaseUrl}/${section.id}`;
  const onActionButtonClick = () => {
    dispatch({
      ...sectionState,
      showBackButton: true
    });
    history.push(newUrl);
    triggerEvent({
      action: EventAction.SECTION_CLICKED,
      category: EventCategory.USER
    });
  };
  const navigateToTheLink = (subSectionId: number) => {
    history.push(`${newUrl}/${subSectionId}`);
    dispatch({
      ...sectionState,
      showBackButton: true
    });
    triggerEvent({
      action: EventAction.SECTION_CLICKED,
      category: EventCategory.USER
    });
  };

  return (
    <SectionCard section={section} loading={loading} onActionButtonClick={onActionButtonClick}>
      {childrenSections?.length > 0 && (
        <div className={classes.folderItems}>
          {childrenSections.map((s) => {
            const sectionNumber = s.type ?? SectionType.CUSTOM_CONTENT;
            const IconComponent = sectionIcons[sectionNumber as SectionType] || FaFolderOpen;

            return (
              <Button
                key={s.id}
                onClick={() => navigateToTheLink(s.id)}
                className={classes.buttonStyle}
                variant='text'
                disableRipple
                disableFocusRipple
                style={{
                  backgroundColor: 'transparent',
                  transition: 'background-color 0.3s'
                }}
              >
                <div className={`${classes.iconStyle} ${classes.circleIcon}`}>
                  <IconComponent
                    data-testid={`icon-${s.id}`}
                    className={classes.iconStyle}
                    size={21}
                  />
                </div>
                <div className={classes.titleStyle}>
                  <span className={classes.description}>{s.name}</span>
                </div>
              </Button>
            );
          })}
        </div>
      )}
    </SectionCard>
  );
};

export default FolderCard;
