import { formatBytes, stripContentAttachmentName } from 'utils/sections';
import { AttachmentElement, attachmentIdentifier } from './CustomElement';
import { Quill } from 'react-quill';
import { forwardRef, useEffect } from 'react';
import { Attachment } from 'components/Sections/Section';
import useQuillHelper from 'hooks/useQuillHelper';

type AttachmentHandlerProps = {
  attachments: Attachment[];
  quill: any;
  onChange: (_files: Attachment[]) => void;
};

Quill.register(AttachmentElement);

const AttachmentHandler = forwardRef<HTMLInputElement, AttachmentHandlerProps>(
  ({ attachments, quill, onChange }, ref) => {
    const quillHelper = useQuillHelper(quill);

    const handleAddAttachments = (event: any) => {
      const newFiles: File[] = [];

      (Object.values(event.target.files) as File[]).forEach((file) => {
        let filename = `${file.name}`;
        let attachmentFile = file;
        const fileSize = formatBytes(file.size);

        attachments?.forEach((attachment) => {
          if (attachment.name === file.name) {
            // Making sure that the file name is unique
            const fliSplit = file.name.split('.');
            filename = fliSplit[0] + '(1).' + fliSplit[1];
            attachmentFile = new File([file], filename, { type: file.type });
          }
        });

        newFiles.push(attachmentFile);

        quillHelper.insertTextToTag(`${filename} (${fileSize})`, attachmentIdentifier);
        quillHelper.insertHtml('<br><br>');
      });

      const newAttachments = newFiles.map((file) => ({ name: file.name, file } as Attachment));

      onChange([...attachments, ...newAttachments]);
    };

    const setupAttachmentsRemoval = () => {
      getEditorContentAttachments().forEach((element: any) => {
        if (element.hasOwnProperty('onclick')) return;

        element.onclick = (e: any) => (e.target as HTMLElement).parentElement?.remove();
      });
    };

    const setupAttachments = () => {
      const editorAttachments = getEditorContentAttachments();

      if (!attachments.length && !editorAttachments.length) return;

      const existingAttachments: Attachment[] = [];

      attachments.forEach((attachment) => {
        editorAttachments.forEach((editorAttachment) => {
          const attachmentName = stripContentAttachmentName(String(editorAttachment.textContent));
          if (attachmentName === attachment.name) {
            existingAttachments.push(attachment);
          }
        });
      });

      onChange(existingAttachments);
    };

    const getEditorContentAttachments = (): NodeList => {
      return document.querySelectorAll('.' + attachmentIdentifier);
    };

    useEffect(() => {
      setupAttachmentsRemoval();
      setupAttachments();
    }, [getEditorContentAttachments().length]);

    return (
      <input
        ref={ref}
        id='attachment-input'
        type='file'
        style={{ display: 'none' }}
        onChange={handleAddAttachments}
        multiple={true}
      />
    );
  }
);

AttachmentHandler.displayName = 'AttachmentHandler';

export default AttachmentHandler;
