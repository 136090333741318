import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    container: {
      paddingBottom: '40px'
    },
    heading: {
      margin: '.5rem 0 1.5rem'
    },
    closeButton: {
      position: 'absolute',
      right: '.5rem',
      top: '.5rem',
      zIndex: 1
    }
  })
);
