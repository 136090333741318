export interface IssueStatus {
  id: IssueState;
  name: string;
  shortName: string;
  isDefault: boolean;
  color: string;
  order: number;
}

/* eslint-disable */
export enum IssueState {
  NEW = 'STATE_NEW',
  ACCEPTED = 'STATE_ACCEPTED',
  COMPLETED = 'STATE_FIXED',
  APPROVED = 'STATE_APPROVED',
  NO_ACTION_NEEDED = 'STATE_NO_ACTION_NEEDED',
  WILL_BE_FIXED = 'STATE_WILL_BE_FIXED',
  UNDER_INVESTIGATION = 'STATE_UNDER_INVESTIGATION'
}
/* eslint-enable */

export interface ChecklistItem {
  id: number;
  name: string;
  roomId: number;
  roomName: string;
  description: null | string;
  assignedUserId: number | null;
  commentToCustomer: string | null;
  created: number;
  deadline: number | null;
  createdByUserId?: number;
  status: IssueState;
  modified: number | null;
  issueType: number;
  inspectionType: number;
  images?: any[];
}

export interface Checklist {
  summary: any[];
  itemsWithStatus: any[];
  newItemInspectionType: number;
}

export default interface ChecklistResponse {
  data: Checklist;
}
