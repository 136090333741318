import { format } from 'date-fns';
import styles from './styles';
import useGetDeadlines from 'hooks/useGetDeadlines';
import { getDateFnsLocale } from 'i18n';
import useT from 'hooks/useT';
import useGetAttachments from 'hooks/useGetAttachments';
// import { useHistory } from 'react-router-dom';
import useBasePath from 'hooks/useBasePath';
import { useContext, useState } from 'react';
import { UserRight } from 'context/App/types';
import { Notification } from '@groupbuilderoy/gb-components-library';
import { useEffect } from 'react';
import { DeadlineType } from './types';
import { ProjectContext } from 'context/Project/ProjectContext';
import { useGetCJ2Url } from 'hooks/useGetCJ2Url';
import { AttachmentType } from 'components/Sections/Attachments/interfaces';
import { useTrackers } from 'hooks/useTrackers';
import { EventAction } from 'services/EventAction';
import { EventCategory } from 'services/EventCategory';

const MAX_COUNT = 2;

const Deadlines = () => {
  const { triggerEvent } = useTrackers();
  const classes = styles();
  const deadlines = useGetDeadlines();
  const { attachments } = useGetAttachments();
  const t = useT();
  // const history = useHistory();
  const basePath = useBasePath();
  const { projectState } = useContext(ProjectContext);
  const [shownDeadlines, setDeadlines] = useState(deadlines.slice(0, MAX_COUNT));
  const CJ2Url = useGetCJ2Url();

  useEffect(() => {
    // For some reason, API returns the same deadlines multiple times, so until that is fixed, we need to ensure that we dont show the same deadline twice
    setDeadlines(
      deadlines
        .reduce((deadlines, current) => {
          if (!deadlines.some((d) => d.id === current.id)) {
            deadlines.push(current);
          }
          return deadlines;
        }, [] as DeadlineType[])
        .slice(0, MAX_COUNT)
    );
  }, [deadlines.length]); // eslint-disable-line

  const getSignableAttachments = () => {
    if (!attachments || !projectState.userRights?.check(UserRight.signDocument)) return [];

    return attachments.filter((a) => a.signable);
  };

  const signableAttachments = getSignableAttachments();

  const closeDeadline = (deadlineId: number) => {
    setDeadlines(shownDeadlines.filter((d) => d.id !== deadlineId));
  };

  return shownDeadlines.length > 0 || signableAttachments.length ? (
    <div className={classes.notifications}>
      {!!shownDeadlines.length &&
        shownDeadlines?.map((deadline, index) => {
          const date = new Date(deadline.endTime);
          return (
            <Notification
              style={{ margin: '0' }}
              id={deadline.id}
              key={`notification-${index}`}
              badgeCaption={t('warning')}
              caption={`${deadline.name}`}
              description={deadline.description || ''}
              deadline={format(date, 'YYY-MM-dd HH:mm', {
                locale: getDateFnsLocale()
              })}
              containerColor={'#FFFCF5'}
              borderColor={'#FEC84B'}
              badgeColors={{
                badgeColor: '#DC6803',
                captionColor: 'white',
                descriptionColor: '#B54708',
                containerColor: '#fdf3d4'
              }}
              actionCaption={t('selectMaterials')}
              actionOnClick={() => {
                window.open(CJ2Url, '_blank')?.focus();
                triggerEvent({
                  action: EventAction.NOTIFICATION_LINK_CLICKED,
                  category: EventCategory.USER
                });
              }}
              onClose={closeDeadline}
            />
          );
        })}
      {signableAttachments.slice(0, MAX_COUNT).map((attachment: AttachmentType, index: number) => {
        const { sections } = attachment;
        if (!sections) return null;

        return (
          <Notification
            style={{ margin: '0' }}
            id={attachment.id}
            key={`notification-${index}`}
            badgeCaption={t('new')}
            caption={`${attachment.name}`}
            description={attachment.description || t('attachments.signableDocument')}
            containerColor={'#FCFCFD'}
            borderColor={'#D0D5DD'}
            badgeColors={{
              badgeColor: '#344054',
              captionColor: 'white',
              descriptionColor: '#344054',
              containerColor: '#d7e7f7'
            }}
            actionCaption={t('signDocument')}
            actionOnClick={() => {
              // TO-DO: Must use history router instead of reloading the webpage.
              window.location.replace(
                `${basePath}/phase/${sections[0].phaseId}/section/${sections[0].sectionId}`
              );
            }}
            onClose={closeDeadline}
          />
        );
      })}
    </div>
  ) : null;
};

export default Deadlines;
