import { createStyles, makeStyles } from '@material-ui/core/styles';

const spacing = '20px';

export default makeStyles(() =>
  createStyles({
    inputField: {
      marginTop: spacing,
      width: '100%'
    },
    buttonRow: {
      marginTop: spacing,
      display: 'flex',
      justifyContent: 'end'
    },
    formAlert: {
      marginBottom: '10px'
    }
  })
);
