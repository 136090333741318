import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  snackBar: {
    padding: '8px',
    display: 'flex',
    flexDirection: 'row',
    color: 'white'
  },
  snackBarContainer: {
    backgroundColor: theme.palette.success.main,
    borderRadius: 10,
    marginBottom: '30px',
    padding: 4
  },
  snackBarErrorContainer: {
    backgroundColor: theme.palette.error.main,
    borderRadius: 10,
    marginBottom: '30px',
    padding: 4
  }
}));

export default useStyles;
