import { attachmentIdentifier } from 'components/HtmlContent/CustomElement';

const useQuillHelper = (quill: any) => {
  const getEditor = () => {
    if (quill && quill.current) return (quill.current as any).getEditor();
  };

  const getCurrentCaretPosition = () => {
    const range = getEditor().getSelection();

    return range ? range.index : 0;
  };

  const insertTextToTag = (text: string, tag: any) => {
    const caretPosition = getCurrentCaretPosition();

    getEditor().insertText(caretPosition, text);
    getEditor().formatText(caretPosition, text.length, tag, 'block');
  };

  const insertHtml = (text: string, caretPosition?: number) => {
    const _caretPosition = caretPosition || getCurrentCaretPosition();

    getEditor().clipboard.dangerouslyPasteHTML(_caretPosition, text);
  };

  const getContent = () => {
    if (!getEditor()) return '';

    return getEditor().root.innerHTML;
  };

  const getAttachments = (): NodeList => {
    return document.querySelectorAll('.' + attachmentIdentifier);
  };

  return {
    getEditor,
    getCurrentCaretPosition,
    insertTextToTag,
    insertHtml,
    getContent,
    getAttachments
  };
};

export default useQuillHelper;
