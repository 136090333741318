import { createContext, Dispatch } from 'react';
import { FormsState } from './interfaces';

export const initialFormsState: FormsState = {
  formsSection: null,
  selectedFormType: null,
  selectedForm: null,
  creatingNewForm: false
};

export const FormsContext = createContext<{
  formsState: FormsState;
  dispatch: Dispatch<FormsState>;
}>({
  formsState: initialFormsState,
  dispatch: () => initialFormsState
});
