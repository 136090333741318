import { ProjectContext } from 'context/Project/ProjectContext';
import { useContext } from 'react';
import { UserRight } from 'context/App/types';
import ImagePresenter from './ImagePresenter';
import SignableDocument from './SignableDocument';
import { AttachmentFileType } from 'components/Sections/Attachments/interfaces';
import EmbeddedDocument from './EmbeddedDocument';

const ModalEmbed = ({ document }: any) => {
  const { url, name, mimeType, type } = document;
  const { projectState } = useContext(ProjectContext);

  const FallbackEmbed =
    window.innerWidth <= 768 ? (
      <EmbeddedDocument data-testid='embed-default' document={document} />
    ) : (
      <embed
        src={url}
        type={mimeType}
        title={name}
        data-testid='embed-default'
        style={{ height: '100%', width: '100%', objectFit: 'contain' }}
      />
    );

  switch (type) {
    case AttachmentFileType.IMAGE:
      return <ImagePresenter src={url} alt={name} />;
    case AttachmentFileType.VIDEO:
      return (
        <video controls data-testid='embed-video'>
          <source src={url} type={mimeType} />
          {FallbackEmbed}
        </video>
      );
    case AttachmentFileType.SIGNABLE:
    case AttachmentFileType.SIGNED:
      if (
        type === AttachmentFileType.SIGNABLE &&
        !projectState.userRights?.check(UserRight.signDocument)
      )
        return FallbackEmbed;

      return <SignableDocument document={document} />;
    default:
      return FallbackEmbed;
  }
};

export default ModalEmbed;
