import { Box, Grid, Modal, Typography } from '@material-ui/core';
import { useContext } from 'react';
import useStyles from './styles';
import ChatRoom from './ChatRoom/ChatRoom';
import useChatMessages from './useChatMessages';
import ThreadOverview from './ThreadOverview/ThreadOverview';
import useT from 'hooks/useT';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'context/App/AppContext';

export default function Chat() {
  const refreshChat = useChatMessages();
  const classes = useStyles();
  const { appState } = useContext(AppContext);
  const t = useT();
  const history = useHistory();

  const mainImage = appState.tenantSettings?.customerJourney2_mainImage;

  const handleClose = () => {
    history.push('/');
  };

  return (
    <Modal open={true} onClose={handleClose}>
      <Grid
        data-testid='chat-ui'
        direction='column'
        container
        wrap='nowrap'
        className={classes.chatContainer}
        spacing={0}
        alignItems='stretch'
      >
        <Grid item className={classes.headerImageContainer}>
          <img className={classes.headerImage} src={mainImage} alt='Open laptop' />
          <Box className={classes.headerTexts}>
            <Box className={classes.headerTextsWrapper}>
              <Typography variant='h3' className={classes.headerTitle}>
                {t('chat.messages')}
              </Typography>
              <Typography variant='body1' className={classes.headerDescription}>
                {t('chat.chatDescription')}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item className={classes.chatSection}>
          <ThreadOverview refreshChat={refreshChat} />
          <ChatRoom refreshChat={refreshChat} />
        </Grid>
      </Grid>
    </Modal>
  );
}
