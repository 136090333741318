import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default makeStyles((theme: Theme) => {
  return createStyles({
    icon: {
      color: theme.palette.common.white
    },
    header: {
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightMedium
    } as CSSProperties,
    text: {
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightLight
    } as CSSProperties,
    button: {
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightRegular
    } as CSSProperties,
    paper: {
      position: 'relative',
      zIndex: 3
    },
    deadlinesContainer: {
      marginBottom: theme.typography.pxToRem(16)
    },
    linkChip: {
      cursor: 'pointer'
    },
    notifications: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem'
    }
  });
});
