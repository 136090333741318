import { Grid, Icon, Box } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import OwnerCard from './OwnerCard/OwnerCard';
import useT from 'hooks/useT';
import styles from './styles';
import { OwnerInterface } from 'hooks/useGetApartmentOwners';
import { Dispatch, SetStateAction } from 'react';

interface ownerShipInterface {
  ownerUsers: OwnerInterface[];
  setOwnerUsers: Dispatch<SetStateAction<OwnerInterface[]>>;
}

const OwnershipView = ({ ownerUsers, setOwnerUsers }: ownerShipInterface) => {
  const t = useT();
  const classes = styles();

  return (
    <>
      <Box className={classes.box}>
        <div className={classes.ownershipHeader}>
          <Icon>
            <PersonIcon className={classes.iconStyle} />
          </Icon>
          <h2 className={classes.title}>{t('keyWaiver.ownershipTitle')}</h2>
        </div>
        <Box sx={{ flexGrow: 1, padding: '4px' }}>
          <Grid container spacing={4}>
            {ownerUsers?.length > 0 &&
              ownerUsers.map((owner) => (
                <Grid item key={owner.id} xs={12} sm={6} md={4}>
                  <OwnerCard owner={owner} setOwnerUsers={setOwnerUsers} />
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default OwnershipView;
