import { Grid, GridSize } from '@material-ui/core';
import SectionWrapper from 'components/Sections/SectionWrapper/SectionWrapper';
import FolderSection from './FolderSection';
import { SectionWithContent } from 'utils/sections';
import { SectionType } from '../Section';
import styles from './styles';
import { SectionContext } from 'context/Section/SectionContext';
import { useContext } from 'react';
import { Button, IconKeys } from '@groupbuilderoy/gb-components-library';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useT from 'hooks/useT';
import { useEditingMode } from 'hooks/useEditingMode';
import useGetSectionFolder from 'hooks/UseGetSectionFolder';

interface Props {
  section: FolderSection;
}

const FolderPage = ({ section }: Props) => {
  const classes = styles();
  const { sectionState } = useContext(SectionContext);
  useGetSectionFolder(section.id);
  const childrenSections = sectionState.sections.filter(
    (s) => s.parentSectionId?.toString() === section.id.toString()
  );
  const t = useT();
  const { url } = useRouteMatch();
  const history = useHistory();
  const newSectionUrl = `${url}/create`;
  const { editingModeEnabled } = useEditingMode();

  return (
    <SectionWrapper
      title={section.name}
      subtitle={section.description}
      loading={sectionState.loading}
      section={section}
      style={{ position: 'relative' }}
    >
      {editingModeEnabled && (
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            caption={t('newSection')}
            onClick={() => history.push(newSectionUrl)}
            startIcon={IconKeys.add}
          />
        </div>
      )}
      <Grid container spacing={3}>
        {childrenSections.map((folderSection: SectionWithContent, index: number) => {
          if (!folderSection.card) return null;
          const width = folderSection.type === 7 ? 3 : folderSection.width;
          const lg = (folderSection.width ? width * 4 : 4) as GridSize;
          const md = folderSection.width > 1 ? 12 : 6;
          return (
            <Grid
              item
              key={index}
              xs={12}
              md={md}
              lg={lg}
              className={section.type === SectionType.CUSTOM_CONTENT ? undefined : classes.section}
            >
              {folderSection.card}
            </Grid>
          );
        })}
      </Grid>
    </SectionWrapper>
  );
};

export default FolderPage;
