import React, { useMemo, useReducer } from 'react';
import { FormsContextProviderProps, FormsContextReducerType, FormsState } from './interfaces';
import { initialFormsState, FormsContext } from './FormsContext';

const reduce = (previousContext: FormsState, contentextUpdate: FormsState): FormsState => {
  return {
    ...previousContext,
    ...contentextUpdate
  };
};

const FormsContextProvider: React.FC<FormsContextProviderProps> = ({ children }) => {
  const [formsState, dispatch] = useReducer<FormsContextReducerType>(reduce, initialFormsState);

  const contextValue = useMemo(() => ({ formsState, dispatch }), [formsState, dispatch]);

  return <FormsContext.Provider value={contextValue}>{children}</FormsContext.Provider>;
};

export default FormsContextProvider;
