import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = ({ hartela }: any) => {
  return makeStyles((theme: Theme) =>
    createStyles({
      main: {
        zIndex: 9,
        top: 'auto',
        bottom: 0,
        height: hartela ? '6rem' : '3rem',
        alignSelf: 'start',
        backgroundColor: hartela ? 'black' : theme.palette.navBarPrimary?.main
      },
      mainBig: {
        zIndex: 9,
        top: 'auto',
        bottom: 0,
        height: '6rem',
        backgroundColor: 'black',
        alignSelf: 'start'
      },
      links: {
        margin: 'auto 0',
        alignSelf: 'flex-start',
        '& > div > a': {
          color: hartela ? 'white' : theme.palette.navBarSecondary?.main
        },
        '& > div': {
          margin: 'auto 0'
        }
      },
      link: { margin: 'auto 0' }
    })
  );
};
