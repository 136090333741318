import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    accordion: {
      '&:not(:first-child)': {
        marginTop: '15px'
      }
    }
  })
);
