import AttachmentListItem from 'components/Attachments/AttachmentList/AttachmentListItem';
import SectionCard from 'components/Sections/SectionCard/SectionCard';
import useGetAttachments from 'hooks/useGetAttachments';
import AttachmentsSection from './AttachmentsSection';

interface Props {
  section: AttachmentsSection;
}

const AttachmentsCard = ({ section }: Props) => {
  const { loading, attachments, error } = useGetAttachments(
    String(section.id),
    section.listMaxCount
  );

  return (
    <SectionCard
      section={section}
      loading={loading}
      error={error}
      noActionButton={attachments.length < section.listMaxCount}
    >
      {attachments.length && (
        <ul>
          {attachments.slice(0, section.listMaxCount).map((attachment, index) => {
            return <AttachmentListItem key={`attachment-${index}`} attachment={attachment} />;
          })}
        </ul>
      )}
    </SectionCard>
  );
};

export default AttachmentsCard;
