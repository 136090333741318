import { ModalMessageContext } from 'context/ModalMessage/ModalMessageContext';
import { useContext, useState } from 'react';
import GBModal from './GBModal';

export default function ModalMessage(): JSX.Element | null {
  const { modalMessageState, dispatch } = useContext(ModalMessageContext);
  const { title, description, isOpen, approveText, cancelText, onApprove, onCancel, icon } =
    modalMessageState;
  const [loading, setLoading] = useState<boolean>(false);

  const handleApprove = async () => {
    if (!loading) {
      setLoading(true);
      await onApprove();
      setLoading(false);
    }
  };

  const handleClose = async () => {
    await onCancel;
    dispatch({
      ...modalMessageState,
      isOpen: false
    });
  };

  return (
    <GBModal
      isOpen={isOpen}
      title={title}
      description={description}
      approveText={approveText}
      cancelText={cancelText}
      onApprove={handleApprove}
      onCancel={handleClose}
      icon={icon}
      approving={loading}
      closeModal={() =>
        dispatch({
          ...modalMessageState,
          isOpen: false
        })
      }
    />
  );
}
