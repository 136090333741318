import { createProjectPhase } from 'axiosInstances';
import PhaseForm from 'components/PhaseForm/PhaseForm';
import { AppContext } from 'context/App/AppContext';
import { PhaseContext } from 'context/Phase/PhaseContext';
import { PhaseToSubmit } from 'context/Phase/interfaces';
import { ProjectContext } from 'context/Project/ProjectContext';
import { useContext } from 'react';

export default function CreatePhase() {
  const { appState } = useContext(AppContext);
  const { organisationId, tenantId } = appState.startParameters;
  const { projectState } = useContext(ProjectContext);
  const { selectedProject } = projectState;
  const { phaseState, dispatch } = useContext(PhaseContext);

  const addNewPhase = async (phase: PhaseToSubmit) => {
    const newPhase = await createProjectPhase({
      organisationId: String(organisationId),
      tenantId: String(tenantId),
      projectId: String(selectedProject?.id),
      gbToken: String(appState.token),
      phase
    });

    dispatch({
      ...phaseState,
      phases: [...phaseState.phases, newPhase]
    });
  };

  return (
    <PhaseForm
      onSubmit={(phase: PhaseToSubmit) => {
        addNewPhase(phase);
      }}
      orderNumber={phaseState.phases.length + 1}
    />
  );
}
