import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  TextField,
  Grid,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  GridSize
} from '@material-ui/core';
import FileDropzone from 'components/FileDropzone/FileDropzone';
import useStyles from './styles';
import useT from 'hooks/useT';
import userGetApartmentOwners, {
  AddOwnerInterface,
  OwnerInterface
} from 'hooks/useGetApartmentOwners';
import { UserDetailsFormData } from 'routes/UserDetails/UserDetails';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import SnackBarNotification, { SnackbarState } from '../SnackBarNotification/SnackBarNotification';

interface OwnerDialogInterface {
  handleCloseDialog: () => void;
  openDialog: boolean;
  isEditing: boolean;
  owner?: OwnerInterface;
  setOwnerUsers: Dispatch<SetStateAction<OwnerInterface[]>>;
}
interface OwnerFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  title: string;
  profilePicture?: string;
}
const emptyFormData = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  title: ''
};

interface Field {
  space: number;
  name: string;
  isDisable?: any;
}

const OwnerDialog = ({
  handleCloseDialog,
  openDialog,
  isEditing,
  owner,
  setOwnerUsers
}: OwnerDialogInterface) => {
  const dialogClosingTime = 3000;
  const t = useT();
  const { updateApartmentOwner, addApartmentOwner, deleteApartmentOwner, uploadProfilePicture } =
    userGetApartmentOwners();
  const [formData, setFormData] = useState<OwnerFormData>(emptyFormData);
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
  const [pictureFile, setPictureFile] = useState<File | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const classes = useStyles({});
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: '',
    isSuccess: true
  });
  useEffect(() => {
    if (owner) {
      const { firstName, lastName, email, phoneNumber, title, picture } = owner;
      const ownerData: OwnerFormData = {
        firstName,
        lastName,
        email,
        phoneNumber,
        title
      };
      if (picture) {
        ownerData.profilePicture = picture;
      }
      setFormData(ownerData);
    }
  }, [owner]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'phoneNumber') {
      const cleanedValue = value.replace(/[^0-9]/g, '');
      setFormData((prevData) => ({
        ...prevData,
        [name]: cleanedValue
      }));
    } else if (name === 'postCode') {
      const cleanedValue = value.replace(/[^0-9a-zA-Z]/g, '');
      setFormData((prevData) => ({
        ...prevData,
        [name]: cleanedValue
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleFileDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
      handleOpenSnackbar(t('userDetails.snackboxImageErrorMessage'), false);
      return;
    }
    reader.onload = () => {
      const imageUrl = reader.result as string;
      setFormData((prevData) => ({
        ...prevData,
        profilePicture: imageUrl
      }));
    };
    reader.readAsDataURL(file);
    setPictureFile(file);
  };

  const handleSave = async () => {
    if (validateFields()) {
      if (isEditing && owner) {
        const submitData: UserDetailsFormData = {
          auth0Id: owner.auth0Id,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: owner.email,
          phoneNumber: formData.phoneNumber,
          streetAddress: owner.streetAddress,
          city: owner.city,
          stateProvince: owner.state,
          postCode: owner.zipCode,
          country: owner.country
        };
        if (pictureFile) {
          await uploadProfilePicture(pictureFile, owner.auth0Id);
        }
        const updatedOwner = await updateApartmentOwner(submitData);
        if (!updatedOwner) {
          handleOpenSnackbar(t('userDetails.snackboxEditingFailed'), false);
          return;
        }
        setOwnerUsers((prev) =>
          prev.map((oldOwner) =>
            oldOwner.auth0Id == updatedOwner.auth0Id ? updatedOwner : oldOwner
          )
        );
        handleOpenSnackbar(t('userDetails.snackboxMessage'), true);
      } else {
        const submitData: AddOwnerInterface = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phoneNumber: formData.phoneNumber
        };
        const newOwner = !pictureFile
          ? await addApartmentOwner(submitData)
          : await addApartmentOwner(submitData, pictureFile);
        if (!newOwner) {
          handleOpenSnackbar(t('userDetails.snackboxCreatingFailed'), false);
          return;
        }
        setOwnerUsers((prev) => [...prev, newOwner]);
        handleOpenSnackbar(t('userDetails.snackboxMessage'), true);
      }
      setTimeout(() => {
        handleCloseDialog();
        if (!isEditing) {
          setFormData(emptyFormData);
        }
      }, dialogClosingTime);
    } else {
      handleOpenSnackbar(t('userDetails.validationFailed'), false);
    }
  };

  const showDeleteConfirmation = () => {
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    if (!owner) return;
    const res = await deleteApartmentOwner(owner);
    handleOpenSnackbar(t('userDetails.snackboxMessage'), true);
    setTimeout(() => {
      if (res) {
        setOwnerUsers((prev) => prev.filter((oldOwner) => oldOwner.auth0Id != owner.auth0Id));
      }
      handleCloseDialog();
      setFormData(emptyFormData);
    }, dialogClosingTime);
  };

  const handleOpenSnackbar = (message: string, isSuccess: boolean) => {
    setSnackbar({
      open: true,
      message,
      isSuccess
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prevState) => ({ ...prevState, open: false }));
  };

  const showEditButton = () => {
    return isEditing ? (
      <>
        <div className={classes.buttonDelete} />
        <Button
          onClick={showDeleteConfirmation}
          variant='contained'
          color='secondary'
          data-testid='delete-btn'
          className={classes.button}
        >
          {t('userDetails.delete')}
        </Button>
        <div />
      </>
    ) : (
      <div></div>
    );
  };

  const validateFields = () => {
    const newErrors: { [key: string]: boolean } = {};
    let isValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = true;
      isValid = false;
    }
    if (!formData.email.trim()) {
      newErrors.email = true;
      isValid = false;
    }
    if (!formData.phoneNumber.trim()) {
      newErrors.phoneNumber = true;
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const fields: Field[] = [
    { space: 6, name: 'firstName' },
    { space: 6, name: 'lastName' },
    { space: 12, name: 'email', isDisable: isEditing },
    { space: 12, name: 'phoneNumber' }
  ];

  if (owner && owner.isSSO) {
    return <></>;
  }

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle className={classes.titleStyle}>
        {isEditing ? t('keyWaiver.EditOwnerDetails') : t('keyWaiver.CreateNewOwner')}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ marginBottom: '15px' }}>
          {fields.map((field: Field, index) => {
            return (
              <Grid key={index} item xs={field.space as GridSize} style={{ marginBottom: '15px' }}>
                <TextField
                  name={field.name}
                  label={t(`userDetails.${field.name}`)}
                  variant='outlined'
                  fullWidth
                  value={formData[field.name as keyof OwnerFormData]}
                  onChange={handleInputChange}
                  InputLabelProps={{ shrink: true }}
                  required
                  error={!!errors[`${field.name}`]}
                  disabled={field.isDisable}
                />
              </Grid>
            );
          })}
          <Grid
            container
            spacing={2}
            alignItems='center'
            style={{ marginBottom: '15px', margin: '0 auto' }}
          >
            <Grid item xs={6} style={{ marginBottom: '15px', marginRight: '30px' }}>
              <div className={classes.fileDropzone}>
                <FileDropzone
                  onChange={handleFileDrop}
                  files={[]}
                  info={t('userDetails.dropzoneLabel')}
                  onDelete={() => {
                    throw new Error('Function not implemented.');
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              {formData.profilePicture && (
                <img src={formData.profilePicture} alt='Profile' className={classes.avatar} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.buttonGroup}>
        {showEditButton()}
        <div>
          <Button
            onClick={handleCloseDialog}
            className={classes.rightMargin}
            variant='contained'
            color='secondary'
            data-testid='cancel-btn'
          >
            {t('userDetails.cancel')}
          </Button>
          <Button
            onClick={handleSave}
            className={classes.button}
            variant='contained'
            color='primary'
            data-testid='save-btn'
          >
            {t('userDetails.save')}
          </Button>
        </div>
      </DialogActions>
      <SnackBarNotification
        open={snackbar.open}
        message={snackbar.message}
        isSuccess={snackbar.isSuccess}
        onClose={handleCloseSnackbar}
        dialogClosingTime={dialogClosingTime}
      />
      <ConfirmationDialog
        dialogProps={{
          open: deleteDialogOpen,
          onClose: () => setDeleteDialogOpen(false)
        }}
        onHandling={handleDelete}
      />
    </Dialog>
  );
};

export default OwnerDialog;
