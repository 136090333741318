import Progress from 'components/Progress/Progress';
import SectionForm from 'components/SectionForm/SectionForm';
import { ICustomContentSection, ISection } from 'components/Sections/Section';
import { SectionContext } from 'context/Section/SectionContext';
import useApi from 'hooks/useApi';
import useGetCustomContent from 'hooks/useGetCustomContent';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

export default function EditSection() {
  const api = useApi();
  const { sectionState } = useContext(SectionContext);
  const { sectionId } = useParams<{ sectionId?: string }>();
  const section = sectionState.sections?.find((section) => String(section.id) === sectionId);

  if (!section) return null;

  const { loading, customContent } = useGetCustomContent(section.id);
  const sectionWithContent: ICustomContentSection = {
    ...section,
    ...(customContent as ICustomContentSection)
  };

  return loading ? (
    <Progress />
  ) : (
    <SectionForm
      section={sectionWithContent}
      onSubmit={(section: ISection) => api.updateSection(section)}
    />
  );
}
