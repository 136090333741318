import { createStyles, makeStyles } from '@material-ui/core/styles';

const spacing = '20px';

export default makeStyles(() =>
  createStyles({
    form: {
      '& *': {
        fontFamily: 'Helvetica Neue,sans-serif !important'
      }
    },
    headingContainer: {
      margin: '1.5rem 0'
    },
    inputField: {
      marginTop: spacing,
      width: '100%'
    },
    buttonRow: {
      marginTop: spacing,
      display: 'flex',
      justifyContent: 'end'
    },
    formAlert: {
      marginBottom: '10px'
    },
    formAlertSrv: {
      marginBottom: '10px',
      color: 'black',
      background: '#FFE800',
      border: 'none',
      '& *': {
        color: 'black'
      }
    }
  })
);
