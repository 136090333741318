import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    messageRoot: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '0.8rem',
      padding: '0 0 0',
      outline: '1px solid '.concat(theme.palette.grey[300]),
      alignItems: 'center',
      cursor: 'pointer'
    },
    messagesContainer: {
      paddingLeft: '0.5rem',
      flexGrow: 1
    },
    messageTitle: {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightBold
    } as CSSProperties,
    messageSender: {
      fontSize: theme.typography.overline.fontSize,
      fontWeight: theme.typography.fontWeightMedium
    } as CSSProperties,
    messageArrow: {
      paddingRight: '1rem'
    }
  })
);
