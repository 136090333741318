import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    messageRoot: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '0.8rem',
      outline: '1px solid '.concat(theme.palette.grey[300]),
      alignItems: 'flex-start',
      cursor: 'pointer'
    },
    messageContainer: {
      padding: '1rem',
      flexGrow: 1
    },
    messageSender: {
      fontSize: theme.typography.overline.fontSize,
      fontWeight: theme.typography.fontWeightMedium
    } as CSSProperties,
    messageArrow: {
      paddingRight: '1rem',
      alignSelf: 'center'
    },
    message: {
      fontSize: theme.typography.body1.fontSize
    }
  })
);
