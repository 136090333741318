import HtmlContentViewer from 'components/HtmlContent/HtmlContentViewer';
import SectionCard from 'components/Sections/SectionCard/SectionCard';
import useGetCustomContent from 'hooks/useGetCustomContent';
import CustomContentSection from './CustomContentSection';

interface Props {
  section: CustomContentSection;
}

const CustomContentCard = ({ section }: Props) => {
  const { loading, customContent, error } = useGetCustomContent(section.id);
  const { preview, content, attachments } = customContent || {};

  return (
    <SectionCard
      section={section}
      loading={loading}
      error={error}
      noActionButton={!customContent?.preview}
      autoHeight
    >
      <HtmlContentViewer content={String(preview || content)} attachments={attachments} />
    </SectionCard>
  );
};

export default CustomContentCard;
