import { createContext, Dispatch } from 'react';
import { AttachmentViewerState } from './interfaces';

export const initialAttachmentViewerState: AttachmentViewerState = {
  open: false,
  attachment: null,
  document: null,
  downloadLink: undefined,
  documentUpdated: false
};

export const AttachmentViewerContext = createContext<{
  attachmentViewerState: AttachmentViewerState;
  dispatch: Dispatch<AttachmentViewerState>;
}>({
  attachmentViewerState: initialAttachmentViewerState,
  dispatch: () => initialAttachmentViewerState
});
