import React, { useEffect, useState } from 'react';
import { TextField, Grid, Typography, Button, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles';
import ApartmentDialog from './ApartmentDialog/ApartmentDialog';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import useT from 'hooks/useT';
import OwnerInforCard from './OwnerInforCard/OwnerInforCard';
import useGetOwnerSearch, { OwnerSearchInterface } from 'hooks/useGetOwnerSearch';
import Progress from 'components/Progress/Progress';

const ApartmentOwnersSearch = () => {
  const classes = styles();
  const t = useT();
  const moreItems = 12;
  const [searchTerm, setSearchTerm] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const { apartmentOwners, loading } = useGetOwnerSearch();
  const [filterOwners, setfilterOwners] = useState<OwnerSearchInterface[]>([]);
  const [visibleOwners, setVisibleOwners] = useState<OwnerSearchInterface[]>([]);
  const [dialogOwner, setDialogOwner] = useState<OwnerSearchInterface | null>(null);
  const [visibleOwnersCount, setVisibleOwnersCount] = useState(moreItems);

  useEffect(() => {
    if (apartmentOwners) {
      const filteredOwners = apartmentOwners.filter((owner) => {
        const fullname = owner.firstName + owner.lastName;
        return (
          fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
          owner.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setfilterOwners(filteredOwners);
      setVisibleOwners(filteredOwners.slice(0, visibleOwnersCount));
    }
  }, [apartmentOwners, searchTerm]);

  const handleDialogClick = (apartmentOwner: OwnerSearchInterface) => {
    setDialogOwner(apartmentOwner);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogOwner(null);
  };

  const handleSearchChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setVisibleOwnersCount(moreItems);
    setSearchTerm(event.target.value);
  };

  const handleShowMore = () => {
    if (visibleOwners.length < filterOwners.length) {
      setVisibleOwners(filterOwners.slice(0, visibleOwnersCount + moreItems));
      setVisibleOwnersCount((prevCount) => prevCount + moreItems);
    }
  };

  if (loading) return <Progress />;

  return (
    <div className={classes.root}>
      <div className={classes.searchBar}>
        <TextField
          label={t('ownerSearch.ownerSearchField')}
          variant='outlined'
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => {
                  setSearchTerm('');
                  setVisibleOwnersCount(moreItems);
                }}
              >
                <CloseIcon />
              </IconButton>
            )
          }}
          inputProps={{ 'data-testid': 'ownerSearchFieldID' }}
        />
      </div>
      {visibleOwners.length > 0 ? (
        <div className={classes.ownersContainer}>
          <Grid container spacing={2}>
            {visibleOwners.map((owner, index) => (
              <OwnerInforCard
                key={index}
                owner={owner}
                handleDialogClick={() => handleDialogClick(owner)}
                data-testid='ownerCard'
              />
            ))}
          </Grid>
          {visibleOwners.length < filterOwners.length && (
            <div className={classes.showMoreButton}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleShowMore}
                data-testid='ownerSearchBtnID'
              >
                {t('ownerSearch.showMore')}
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className={classes.noresults}>
          <SentimentDissatisfiedIcon className={classes.noResultsIcon} />
          <Typography variant='h6'>{t('ownerSearch.noMatchesFound')}</Typography>
        </div>
      )}
      {dialogOwner && (
        <ApartmentDialog
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          owner={dialogOwner}
          apartmentOwners={apartmentOwners}
        />
      )}
    </div>
  );
};

export default ApartmentOwnersSearch;
