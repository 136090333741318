import { fetchContacts } from 'axiosInstances';
import { AppContext } from 'context/App/AppContext';
import { PhaseContext } from 'context/Phase/PhaseContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import { useContext, useEffect, useState } from 'react';

export interface Contact {
  description: string | null;
  tenantId: string;
  partnerType: number;
  imageFileName: string | null;
  phoneNumber: string | null;
  email: string | null;
  streetAddress: string | null;
  city: string | null;
  postalCode: string | null;
  state: string | null;
  country: string | null;
  link: string | null;
  companyName: string | null;
  jobTitle: string | null;
  imageUrlSmall: string | null;
  imageUrlLarge: string | null;
  id: number | string;
  name: string | null;
}

const useGetContacts = (sectionId: number) => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { appState } = useContext(AppContext);
  const { phaseState } = useContext(PhaseContext);
  const { projectState } = useContext(ProjectContext);

  const { organisationId, tenantId } = appState.startParameters;
  const projectId = projectState.selectedProject?.id;
  const gbToken = appState.token;
  const phaseId = phaseState.selectedPhase?.id;

  const fetch = async () => {
    if (!projectId || projectState.loading) return;

    try {
      const contactsResponse = await fetchContacts({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        projectId: String(projectId),
        gbToken: String(gbToken)
      });

      const contacts = contactsResponse.data.data.phases.reduce((contactsFromPhases, phase) => {
        if (phase.id === phaseId) {
          const contactsFromPhase =
            phase.sections?.reduce((contactsFromSections, section) => {
              if (section.id === sectionId) contactsFromSections.push(...(section.contacts || []));
              return contactsFromSections;
            }, [] as Contact[]) || [];

          contactsFromPhases.push(...contactsFromPhase);
        }

        return contactsFromPhases;
      }, [] as Contact[]);

      setContacts(contacts);
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, [projectId]); // eslint-disable-line

  return { contacts, loading, error };
};

export default useGetContacts;
