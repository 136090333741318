import React, { useMemo, useReducer } from 'react';
import { SectionContextProviderProps, SectionContextReducerType, SectionState } from './interfaces';
import { initialSectionState, SectionContext } from './SectionContext';

const reduce = (previousContext: SectionState, contentextUpdate: SectionState): SectionState => {
  return {
    ...previousContext,
    ...contentextUpdate
  };
};

const SectionContextProvider: React.FC<SectionContextProviderProps> = ({ children }) => {
  const [sectionState, dispatch] = useReducer<SectionContextReducerType>(
    reduce,
    initialSectionState
  );

  const contextValue = useMemo(() => ({ sectionState, dispatch }), [sectionState, dispatch]);

  return <SectionContext.Provider value={contextValue}>{children}</SectionContext.Provider>;
};

export default SectionContextProvider;
