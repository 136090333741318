import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh'
    },
    searchBar: {
      margin: theme.spacing(2),
      position: 'relative'
    },
    ownersContainer: {
      flex: 1,
      overflowY: 'auto',
      padding: theme.spacing(2)
    },
    ownerCard: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'center',
      height: '90%',
      width: '100%'
    },
    ownerDetails: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 10
    },
    showMoreButton: {
      marginTop: theme.spacing(2),
      textAlign: 'center'
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.primary.main
    },
    textButton: {
      fontSize: theme.typography.overline.fontSize,
      color: theme.palette.primary.main
    },
    buttonContainer: {
      alignSelf: 'start',
      paddingLeft: 10
    },
    noresults: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(4),
      color: theme.palette.text.secondary
    },
    noResultsIcon: { fontSize: '4rem', marginBottom: theme.spacing(2) }
  })
);
