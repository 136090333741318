import { fetchAttachments } from '../axiosInstances';
import { useContext, useEffect, useState } from 'react';
import { AttachmentsResponse } from 'axiosInstances/instances/types';
import { AppContext } from 'context/App/AppContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import { AttachmentViewerContext } from 'context/AttachmentViewer/AttachmentViewerContext';
import { AttachmentType } from 'components/Sections/Attachments/interfaces';

export default function useGetAttachments(sectionId?: string, limit?: number) {
  const { appState } = useContext(AppContext);
  const { projectState } = useContext(ProjectContext);
  const { apartmentState } = useContext(ApartmentContext);
  const { attachmentViewerState } = useContext(AttachmentViewerContext);
  const { tenantId, organisationId } = appState.startParameters;
  const gbToken = appState.token;
  const projectId = projectState.selectedProject?.id;

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [attachments, setAttachments] = useState<AttachmentType[]>([]);

  useEffect(() => {
    if (projectState.loading || apartmentState.loading) return;

    fetchAttachments({
      projectId: String(projectId),
      apartmentId: String(apartmentState.selectedApartment?.id),
      tenantId: String(tenantId),
      organisationId: String(organisationId),
      gbToken: String(gbToken),
      sectionId: sectionId ? String(sectionId) : '',
      limit
    })
      .then((response: AttachmentsResponse) => {
        setAttachments(response.data);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(setError);
  }, [
    sectionId,
    projectState.loading,
    apartmentState.loading,
    attachmentViewerState.documentUpdated
  ]); // eslint-disable-line

  return { loading, attachments, error };
}
