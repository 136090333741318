import { postChecklistItem } from 'axiosInstances';
import { useContext } from 'react';
import styles from './styles';
import { Alert, Color } from '@material-ui/lab';
import Progress from 'components/Progress/Progress';
import useT from 'hooks/useT';
import { AppContext } from 'context/App/AppContext';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import { Room } from 'context/Apartment/interfaces';
import { IssueState } from 'axiosInstances/instances/CheckListResponse';
import Form from 'components/Form/Form';
import { FieldType, FormState } from 'components/Form/interfaces';

export default function CreateChecklistItem() {
  const classes = styles();
  const t = useT();
  const { appState } = useContext(AppContext);
  const { apartmentState } = useContext(ApartmentContext);
  const { selectedApartment } = apartmentState;

  const initialFormState = {
    room: {
      value: '',
      required: true,
      validationErrorKey: 'newChecklistItem.selectRoom',
      localizationKey: 'newChecklistItem.room',
      options: (selectedApartment?.rooms as Room[]).map((room: Room) => {
        return {
          value: room.id,
          name: room.name
        };
      }),
      type: FieldType.SELECT
    },
    name: {
      value: '',
      required: true,
      validationErrorKey: 'newChecklistItem.fillName',
      localizationKey: 'newChecklistItem.name',
      type: FieldType.STRING
    },
    description: {
      value: '',
      required: true,
      validationErrorKey: 'newChecklistItem.fillDescription',
      localizationKey: 'newChecklistItem.description',
      type: FieldType.LONG_STRING
    },
    images: {
      value: [],
      localizationKey: 'newChecklistItem.images',
      infoLocalizationKey: 'newChecklistItem.dropzone',
      type: FieldType.FILE_DROPZONE
    }
  };

  const renderAlert = (messageKey: string, severity: Color) => {
    const message = t('checklist.alert.' + messageKey);
    return (
      <Alert className={classes.formAlert} variant='outlined' severity={severity}>
        {message}
      </Alert>
    );
  };

  if (!selectedApartment || apartmentState.loading) return <Progress />;
  else if (!selectedApartment?.rooms || !selectedApartment.rooms?.length)
    return renderAlert('noRooms', 'info');

  return (
    <Form
      localizationKeys={{
        title: 'newChecklistItem.heading',
        submit: 'create',
        submitSuccess: 'checklist.alert.creationSuccess',
        submitError: 'checklist.alert.creationFailed'
      }}
      fields={initialFormState}
      onSubmit={async (formState: FormState) => {
        await postChecklistItem(String(appState.token), String(formState.room.value), {
          name: String(formState.name.value),
          description: String(formState.description.value),
          newStatus: IssueState.NEW,
          images: formState.images.value as File[],
          issueType: 8, // other
          inspectionType: Number(appState.newItemInspectionType)
        });
      }}
    />
  );
}
