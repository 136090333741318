import { Card, CardContent, Typography, Box, Icon, IconButton, Link } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import EditIcon from '@material-ui/icons/Edit';
import styles from './styles';
import { Dispatch, SetStateAction, useState } from 'react';
import OwnerDialog from '../OwnerDialog/OwnerDialog';
import { OwnerInterface } from 'hooks/useGetApartmentOwners';
import OwnerAvatar from '../OwnerAvatar/OwnerAvatar';

interface OwnerCardInterface {
  setOwnerUsers: Dispatch<SetStateAction<OwnerInterface[]>>;
  owner: OwnerInterface;
}

const OwnerCard = ({ owner, setOwnerUsers }: OwnerCardInterface) => {
  const classes = styles();
  const [isHovered, setIsHovered] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const handleEditClick = () => {
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  return (
    <>
      <Card
        className={classes.card}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        data-testid='owner-card'
      >
        <CardContent className={classes.cardContent}>
          {isHovered && !owner.isSSO && (
            <IconButton
              className={classes.createNewButton}
              onClick={handleEditClick}
              data-testid='edit-button'
              color='inherit'
            >
              <EditIcon color='inherit' />
            </IconButton>
          )}
          {owner?.firstName && owner?.lastName && (
            <OwnerAvatar
              firstName={owner.firstName}
              lastName={owner.lastName}
              imageUrl={owner.picture}
            />
          )}
          <Box ml={4} flexGrow={1}>
            {owner.lastName && owner.firstName ? (
              <div className={classes.boxDetails}>
                <Typography className={classes.nameStyle} data-testid='fullName'>
                  {owner.firstName} {owner.lastName}
                </Typography>
                <Typography className={classes.statusStyle}>{owner.title}</Typography>
              </div>
            ) : (
              <Typography className={classes.nameStyle} data-testid='fullName'>
                Owner
              </Typography>
            )}
            {owner.email && (
              <div className={classes.titleDetail}>
                <Icon>
                  <EmailIcon fontSize='small' className={classes.iconColor} />
                </Icon>
                <Link href={`mailto:${owner.email}`} className={classes.textStyle}>
                  {owner.email}
                </Link>
              </div>
            )}

            <div className={classes.titleDetail}>
              <Icon>
                <LocalPhoneIcon fontSize='small' className={classes.iconColor} />
              </Icon>
              {owner.phoneNumber ? (
                <Link href={`tel:${owner.phoneNumber}`} className={classes.textStyle}>
                  {owner.phoneNumber}
                </Link>
              ) : (
                <Link href={'#'} className={classes.textStyle}>
                  ---
                </Link>
              )}
            </div>
          </Box>
        </CardContent>
        <OwnerDialog
          openDialog={openEditDialog}
          handleCloseDialog={handleCloseEditDialog}
          isEditing={true}
          owner={owner}
          data-testid='edit-dialog'
          setOwnerUsers={setOwnerUsers}
        />
      </Card>
    </>
  );
};

export default OwnerCard;
