import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: '40px',
      position: 'relative'
    },
    heading: {
      margin: '.5rem 0 1.5rem'
    },
    closeButton: {
      position: 'absolute',
      right: '.5rem',
      top: '.5rem',
      zIndex: 1
    },
    header: {
      color: theme.palette.sectionsHeader?.main,
      fontSize: theme.typography.h5.fontSize
    },
    description: {
      color: theme.palette.grey[700],
      fontSize: theme.typography.subtitle1.fontSize
    },
    addButton: {
      position: 'absolute',
      right: 4,
      top: 4,
      fontSize: theme.typography.overline.fontSize
    } as CSSProperties
  })
);
