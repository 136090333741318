import { createContext, Dispatch } from 'react';
import { ProjectState } from './interfaces';

export const initialProjectState: ProjectState = {
  projects: [],
  loading: true
};

export const ProjectContext = createContext<{
  projectState: ProjectState;
  dispatch: Dispatch<ProjectState>;
}>({ projectState: initialProjectState, dispatch: () => initialProjectState });
