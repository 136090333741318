import { useContext } from 'react';
import { Select } from '@groupbuilderoy/gb-components-library';
import useT from 'hooks/useT';
import { useTheme } from '@material-ui/core';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import { useLocation, useParams } from 'react-router-dom';
import { integratedAppPaths } from 'utils/utils';
import { EventAction } from 'services/EventAction';
import { EventCategory } from 'services/EventCategory';
import { useTrackers } from 'hooks/useTrackers';

export default function ApartmentSelect() {
  const { triggerEvent } = useTrackers();
  const theme = useTheme();
  const t = useT();
  const { apartmentState, dispatch } = useContext(ApartmentContext);
  const { projectState } = useContext(ProjectContext);
  const { apartments, selectedApartment } = apartmentState;
  const params = useParams<{ apartmentId?: string }>();
  const location = useLocation();

  if (
    !apartments.length ||
    (projectState.loading && !projectState.loadDetails) ||
    (apartmentState.loading && !apartmentState.loadDetails)
  )
    return null;

  const selectedApartmentId =
    params.apartmentId || selectedApartment?.id || apartmentState.apartments[0].id;

  const handleApartmentChange = (apartmentId: number) => {
    const apartment = apartmentState.apartments.find((apartment) => apartment.id === apartmentId);

    if (!apartment || apartment.id === apartmentState.selectedApartment?.id) return;

    dispatch({
      ...apartmentState,
      selectedApartment: apartment,
      loadDetails: true
    });
    triggerEvent({
      action: EventAction.CHANGE_APARTMENT,
      category: EventCategory.USER
    });
  };

  return (
    <Select
      data-testid='apartment-selector'
      caption={t('apartment')}
      initialValue={selectedApartmentId}
      itemList={apartmentState.apartments}
      identifierKey={'id'}
      renderKey={'name'}
      onChange={handleApartmentChange}
      selectedItemColor={theme.palette.navBarSecondary?.main || 'white'}
      toLink={
        integratedAppPaths.includes(location.pathname)
          ? undefined
          : `/project/${projectState.selectedProject?.id}/apartment`
      }
    />
  );
}
