import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useCj2Loaded = () => {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    const iframe = document.getElementById('integratedAppIframe') as HTMLIFrameElement | null;
    const onLoad = () => setIframeLoaded(true);

    if (iframe) {
      iframe.addEventListener('load', onLoad);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener('load', onLoad);
      }
    };
  }, [useLocation().pathname]);

  return iframeLoaded;
};

export default useCj2Loaded;
