import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    ownerCard: {
      marginBottom: theme.spacing(2),
      padding: '2px 8px',
      height: '90%',
      width: '100%',
      background: 'white',
      border: '1px solid #EAECF0',
      boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
      borderRadius: '8px',
      '&:hover': {
        backgroundColor: theme.palette.grey[200]
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%'
      }
    },
    cardContent: {
      display: 'flex',
      alignItems: 'start',
      width: '100%',
      height: '100%',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      position: 'relative'
    },
    ownerInforSide: {
      display: 'flex',
      flexDirection: 'column',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingBottom: '4px',
      height: '100%'
    },
    ownerDetails: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    },
    link: {
      textDecoration: 'none',
      marginBottom: '3px',
      cursor: 'pointer'
    },
    textButton: {
      fontSize: theme.typography.caption.fontSize,
      color: theme.palette.sectionsHeader?.main,
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    buttonContainer: {
      alignSelf: 'center',
      borderRadius: '8px'
    },
    body2Title: {
      color: theme.palette.grey[700],
      fontSize: theme.typography.body2.fontSize,
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      overflowWrap: 'break-word',
      '&:hover': {
        textDecoration: 'underline'
      },
      cursor: 'pointer'
    },
    body1Title: {
      color: theme.palette.grey[700],
      fontSize: theme.typography.body1.fontSize
    },
    headerTitle: {
      color: theme.palette.sectionsHeader?.main,
      fontSize: theme.typography.h6.fontSize,
      '&:hover': {
        textDecoration: 'underline'
      },
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      overflowWrap: 'break-word'
    },
    avatarContainer: {
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    },
    detailItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '3px',
      marginTop: '3px'
    },
    iconCard: {
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main
    }
  })
);
