import { createContext, Dispatch } from 'react';
import { AppState } from './interfaces';

export const initialAppState: AppState = {
  loading: true,
  startParameters: {},
  newItemInspectionType: null
};

export const AppContext = createContext<{
  appState: AppState;
  dispatch: Dispatch<AppState>;
}>({ appState: initialAppState, dispatch: () => initialAppState });
