// This whole file should be moved to some common gb-library

export interface ApiProps {
  apiUrl: string;
  token: string;
  organisation: string;
  tenant: string;
  lang: string;
}

export interface ApiResponse {
  data: any;
  error: any;
}

export class ApiBase {
  protected url: string;

  private props: ApiProps;
  private headers: any;

  constructor(props: ApiProps) {
    this.props = props;

    this.url = this.props.apiUrl;
    this.headers = this.constructHeaders();
  }

  public async get(path: string, params?: any): Promise<ApiResponse> {
    const request = await fetch(this.url + this.constructPathWithParams(path, params), {
      headers: this.headers
    });
    const response = await request.json();

    this.validateResponse(response, request.status);

    return response;
  }
  public async post(path: string, body: any = {}): Promise<ApiResponse> {
    const request = await fetch(this.url + path, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: this.headers
    });
    const response = await request.json();

    this.validateResponse(response, request.status);

    return response;
  }

  public async postFormData(path: string, body: FormData): Promise<ApiResponse> {
    const headers = Object.assign({}, this.headers);
    delete headers['Content-Type'];

    const request = await fetch(this.url + path, {
      method: 'POST',
      body,
      headers
    });
    const response = await request.json();

    this.validateResponse(response, request.status);

    return response;
  }

  public async putFormData(path: string, body: FormData): Promise<ApiResponse> {
    const headers = Object.assign({}, this.headers);
    delete headers['Content-Type'];

    const request = await fetch(this.url + path, {
      method: 'PUT',
      body,
      headers
    });
    const response = await request.json();

    this.validateResponse(response, request.status);

    return response;
  }

  public async update(path: string, body: any = {}): Promise<ApiResponse> {
    const request = await fetch(this.url + path, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: this.headers
    });
    const response = await request.json();

    this.validateResponse(response, request.status);

    return response;
  }

  public async delete(path: string): Promise<ApiResponse> {
    const request = await fetch(this.url + path, {
      method: 'DELETE',
      headers: this.headers
    });
    const response = await request.json();

    this.validateResponse(response, request.status);

    return response;
  }

  private validateResponse(response: any, status: number) {
    if (status < 200 || status >= 400) {
      throw new Error('Request failed with status: ' + status);
    } else if (response.error) {
      throw new Error(response.error.message);
    } else if (!response.data) {
      throw new Error('Invalid response');
    }
  }

  private constructHeaders() {
    const { token, lang, tenant, organisation } = this.props;

    return {
      'Content-Type': 'application/json',
      'Accept-Language': lang,
      'gb-client': 'CJ3',
      Authorization: `Bearer ${token}`,
      organisation,
      tenant
    };
  }

  private constructPathWithParams(path: string, params: any): string {
    if (!params || !Object.keys(params).length) return path;

    const urlParams = new URLSearchParams(params);

    return `${path.startsWith('/') ? path : '/' + path}?${urlParams.toString()}`;
  }
}
