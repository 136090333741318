import shiroTrie from 'shiro-trie';
export default class UserRights {
  private shiroTrie: shiroTrie.ShiroTrie = shiroTrie.newTrie();

  constructor(rights: any) {
    this.shiroTrie.add(rights);
  }

  check(permission: string): boolean {
    return this.shiroTrie.check(permission);
  }
}
