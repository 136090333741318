import { Box, Button, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { useContext, useState } from 'react';
import { ChatStateContext } from 'store/chatState/ChatStateProvider';
import { RoomThreads } from './Tabs/RoomThreads/RoomThreads';
import { TabPanelProps } from './types';
import styles from './styles';
import { ChatStateAction } from 'store/chatState/actions';
import RefreshButton from '../RefreshButton/RefreshButton';
import Bulletins from './Tabs/Bulletins/Bulletins';
import useT from 'hooks/useT';

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      item
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </Grid>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

interface ThreadOverviewProps {
  refreshChat: () => void;
}

const ThreadOverview: React.FC<ThreadOverviewProps> = ({ refreshChat }: ThreadOverviewProps) => {
  const classes = styles();
  const [tabIndex, setTabIndex] = useState(0);
  const { chatState, dispatch } = useContext(ChatStateContext);
  const t = useT();

  // eslint-disable-next-line
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const onClickCreateNewChatThread = () => {
    dispatch({ type: ChatStateAction.SET_NEW_CHAT, data: true });
  };

  return !chatState.thread && !chatState.newChat ? (
    <>
      <Grid item className={classes.threadOverviewHeader}>
        <Tabs
          variant='fullWidth'
          value={tabIndex}
          onChange={handleChange}
          aria-label='message type sorting tabs'
        >
          <Tab label={t('chat.messages')} {...a11yProps(0)} />
          <Tab label={t('chat.bulletins')} {...a11yProps(1)} />
        </Tabs>
      </Grid>

      <TabPanel value={tabIndex} index={0}>
        <Grid className={classes.buttonArea}>
          {chatState.threads.some((thread) => thread.messages.length === 0) ? (
            <Button
              variant='contained'
              className={classes.createMessageButton}
              onClick={onClickCreateNewChatThread}
            >
              <Typography className={classes.createMessageButtonText}>
                {t('chat.createNewThread')}
              </Typography>
            </Button>
          ) : null}

          <RefreshButton refreshChat={refreshChat} />
        </Grid>
        <RoomThreads />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Bulletins />
      </TabPanel>
    </>
  ) : null;
};

export default ThreadOverview;
