import React from 'react';
import { Grid } from '@material-ui/core';
import useT from 'hooks/useT';
import { UserDetailsFormData } from './UserDetails';
import FileDropzone from 'components/FileDropzone/FileDropzone';
import useStyles from './styles';

interface UserProfilePictureProps {
  formData: UserDetailsFormData;
  setFormData: React.Dispatch<React.SetStateAction<UserDetailsFormData>>;
  handleOpenSnackbar: any;
  uploadProfilePicture: any;
}

const UserProfilePicture: React.FC<UserProfilePictureProps> = ({
  formData,
  setFormData,
  handleOpenSnackbar,
  uploadProfilePicture
}) => {
  const t = useT();
  const classes = useStyles();

  const handleFileDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
      handleOpenSnackbar();
      return;
    }
    reader.onload = () => {
      const imageUrl = reader.result as string;
      setFormData((prevData) => ({
        ...prevData,
        profilePicture: imageUrl
      }));
    };
    reader.readAsDataURL(file);
    await uploadProfilePicture(file);
  };

  return (
    <Grid container spacing={2} alignItems='center' style={{ marginBottom: '15px' }}>
      <Grid item xs={3} style={{ paddingLeft: '16px' }}>
        <h3 className={classes.header}>{t('userDetails.profilePicture')}</h3>
        <h5 style={{ color: 'grey' }}>{t('userDetails.profilePictureInfo')}</h5>
      </Grid>
      <Grid item xs={3} style={{ paddingLeft: '16px' }}>
        {formData.profilePicture && (
          <img
            src={formData.profilePicture}
            alt='Profile'
            className={classes.profilePicture}
            data-testid={'profimage'}
          />
        )}
      </Grid>
      <Grid item xs={6} style={{ marginBottom: '15px', paddingRight: '16px' }}>
        <FileDropzone
          onChange={handleFileDrop}
          data-testid='cj3-dropzone-input'
          files={[]}
          info={t('userDetails.dropzoneLabel')}
          onDelete={() => {
            throw new Error('Function not implemented.');
          }}
        />
      </Grid>
    </Grid>
  );
};

export default UserProfilePicture;
