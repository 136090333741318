import React, { useMemo, useReducer } from 'react';
import { AppContext, initialAppState } from './AppContext';
import { AppState, AppContextProviderProps, AppContextReducerType } from './interfaces';

const reduce = (previousContext: AppState, contentextUpdate: AppState): AppState => {
  return {
    ...previousContext,
    ...contentextUpdate
  };
};

const AppContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {
  const [appState, dispatch] = useReducer<AppContextReducerType>(reduce, initialAppState);

  const contextValue = useMemo(() => ({ appState, dispatch }), [appState, dispatch]);

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
