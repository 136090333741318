import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      paddingTop: '1rem',
      paddingBottom: '1rem'
    },
    subjectArea: {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightLight
    } as CSSProperties,
    roomName: {
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.fontWeightRegular
    } as CSSProperties,
    messageContainer: {
      paddingLeft: '2rem',
      paddingRight: '2rem'
    },
    chatHeader: {
      position: 'sticky',
      top: 0,
      background: 'white',
      paddingBottom: theme.spacing(2)
    }
  })
);
