import { Grid } from '@material-ui/core';
import SectionWrapper from 'components/Sections/SectionWrapper/SectionWrapper';
import ContactsSection from 'components/Sections/Contacts/ContactsSection';
import useGetContacts from 'hooks/useGetContacts';
import ContactListItem from './ContactListItem/ContactListItem';

interface Props {
  section: ContactsSection;
}

const ContactsPage = ({ section }: Props) => {
  const { contacts, loading, error } = useGetContacts(section.id);

  return (
    <SectionWrapper
      title={section.name}
      subtitle={section.description}
      loading={loading}
      error={error}
      section={section}
    >
      {contacts.length && (
        <Grid container spacing={4}>
          {contacts.map((contact, index) => {
            return (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <ContactListItem key={`checklist-item-${index}`} {...contact} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </SectionWrapper>
  );
};

export default ContactsPage;
