import { Button, Grid, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { useContext } from 'react';
import { ChatStateContext } from 'store/chatState/ChatStateProvider';

import { Refresh } from '@material-ui/icons';
import { format } from 'date-fns';
import { getDateFnsLocale } from 'i18n';
import useT from 'hooks/useT';

const useStyles = makeStyles({
  button: {
    color: (theme) => theme.palette.primary.light
  },
  icon: {
    paddingRight: (theme: Theme) => theme.spacing(1)
  },
  lastUpdateTime: (theme: Theme) =>
    ({
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.body2.fontSize
    } as any)
});

interface RefreshButtonProps {
  refreshChat: () => void;
}

const RefreshButton: React.FC<RefreshButtonProps> = ({ refreshChat }: RefreshButtonProps) => {
  const { chatState } = useContext(ChatStateContext);
  const theme = useTheme();
  const t = useT();
  const classes = useStyles(theme);

  return (
    <Grid container direction='row' alignItems='center' spacing={1}>
      <Grid item>
        <Button variant='contained' disabled={chatState.isRefreshing} onClick={refreshChat}>
          <Refresh className={classes.icon} />
          {t('chat.refreshMessages')}
        </Button>
      </Grid>
      <Grid item>
        <Typography className={classes.lastUpdateTime}>{`${t('chat.lastUpdated')}: ${format(
          new Date(chatState.lastRefresh),
          'YYY-MM-dd HH:mm:ss',
          {
            locale: getDateFnsLocale()
          }
        )}`}</Typography>
      </Grid>
    </Grid>
  );
};

export default RefreshButton;
