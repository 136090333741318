import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => {
  return createStyles({
    attachmentsPage: {
      '& div': {
        '& img': {
          maxHeight: '80%'
        }
      }
    }
  });
});
