import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      margin: '15px 0'
    },
    editor: {
      // FONT SELECTOR
      '& .ql-picker-item, .ql-picker-label': {
        '&[data-value="Helvetica"]': {
          '&::before': {
            content: '"Helvetica" !important'
          }
        },
        '&[data-value="Arial"]': {
          '&::before': {
            content: '"Arial" !important',
            fontFamily: '"Arial" !important'
          }
        },
        '&[data-value="Roboto"]': {
          '&::before': {
            content: '"Roboto" !important',
            fontFamily: '"Roboto" !important'
          }
        },
        '&[data-value="Ubuntu"]': {
          '&::before': {
            content: '"Ubuntu" !important',
            fontFamily: '"Ubuntu" !important'
          }
        },
        '&[data-value="OpenSans"]': {
          '&::before': {
            content: '"Open Sans" !important',
            fontFamily: '"Open Sans" !important'
          }
        },
        '&[data-value="Montserrat"]': {
          '&::before': {
            content: '"Montserrat" !important',
            fontFamily: '"Montserrat" !important'
          }
        },
        '&[data-value="Lato"]': {
          '&::before': {
            content: '"Lato" !important',
            fontFamily: '"Lato" !important'
          }
        },
        '&[data-value="Poppins"]': {
          '&::before': {
            content: '"Poppins" !important',
            fontFamily: '"Poppins" !important'
          }
        },
        '&[data-value="Inter"]': {
          '&::before': {
            content: '"Inter" !important',
            fontFamily: '"Inter" !important'
          }
        },
        '&[data-value="12px"]': {
          '&::before': {
            content: '"12px" !important'
          }
        },
        '&[data-value="14px"]': {
          '&::before': {
            content: '"14px" !important'
          }
        },
        '&[data-value="16px"]': {
          '&::before': {
            content: '"16px" !important'
          }
        },
        '&[data-value="18px"]': {
          '&::before': {
            content: '"18px" !important'
          }
        },
        '&[data-value="20px"]': {
          '&::before': {
            content: '"20px" !important'
          }
        },
        '&[data-value="24px"]': {
          '&::before': {
            content: '"24px" !important'
          }
        },
        '&[data-value="32px"]': {
          '&::before': {
            content: '"32px" !important'
          }
        },
        '&[data-value="48px"]': {
          '&::before': {
            content: '"48px" !important'
          }
        }
      },
      // END FONT SELECTOR

      '& .ql-toolbar': {
        border: 'none'
      },

      '& .ql-container': {
        borderRadius: '8px',
        borderTop: '1px solid #ccc !important'
      },

      '& .ql-editor': {
        whiteSpace: 'normal !important',
        minHeight: '400px',
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '16px',
        paddingRight: '40px',
        resize: 'vertical',

        '& p, span': {
          fontFamily: 'inherit !important',
          fontWeight: 'inherit !important',
          color: 'inherit !important',
          textDecoration: 'none !important'
        },
        '& .preview': {
          fontStyle: 'italic',
          userSelect: 'none'
        },

        '& .attachment': {
          position: 'relative',
          pointerEvents: 'none',
          userSelect: 'none',
          display: 'inline-block',
          paddingBottom: '10px',

          '&:after': {
            position: 'absolute',
            top: '-4px',
            right: '-17px',
            pointerEvents: 'auto',
            cursor: 'pointer',
            content: 'url(/Icons/close.svg)',
            // TODO: filter should come from theme
            filter:
              'invert(72%) sepia(3%) saturate(6134%) hue-rotate(333deg) brightness(77%) contrast(89%)'
          }
        },
        // FONTS
        '& .ql-font-Roboto': {
          fontFamily: 'Roboto !important'
        },
        '& .ql-font-Arial': {
          fontFamily: 'Arial !important'
        },
        '& .ql-font-Helvetica': {
          fontFamily: 'Helvetica !important'
        },
        '& .ql-font-Ubuntu': {
          fontFamily: 'Ubuntu !important'
        },
        '& .ql-font-OpenSans': {
          fontFamily: 'Open Sans !important'
        },
        '& .ql-font-Montserrat': {
          fontFamily: 'Montserrat !important'
        },
        '& .ql-font-Lato': {
          fontFamily: 'Lato !important'
        },
        '& .ql-font-Poppins': {
          fontFamily: 'Poppins !important'
        },
        '& .ql-font-Inter': {
          fontFamily: 'Inter !important'
        }

        // END FONTS
      },

      '& .ql-editing': {
        zIndex: 1
      },

      '& button:hover, .ql-active, .ql-picker': {
        color: `${theme.palette.primary.main} !important`,

        '& path, line, rect, span, svg': {
          stroke: `${theme.palette.primary.main} !important`
        },
        '& span': {
          color: `black !important`,

          '&:hover': {
            color: `${theme.palette.primary.main} !important`
          }
        }
      },

      '& button.ql-preview': {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '.9rem',
        width: 'fit-content',

        '&:before': {
          content: '"</preview>"'
        }
      },

      '& button.ql-attachment': {
        backgroundSize: 'cover',
        backgroundImage: 'url(/Icons/attachment.svg)',
        marginLeft: '4px',
        width: '24px',
        height: '24px',

        // TODO: filter should come from theme
        '&:hover': {
          filter:
            'invert(72%) sepia(3%) saturate(6134%) hue-rotate(333deg) brightness(77%) contrast(89%)'
        }
      },

      '& button.ql-colorpicker': {
        backgroundSize: 'cover',
        backgroundImage: 'url(/Icons/colorpicker.svg)',
        marginLeft: '4px',
        width: '20px',
        height: '20px',
        marginTop: '2px',

        // TODO: filter should come from theme
        '&:hover': {
          filter:
            'invert(72%) sepia(3%) saturate(6134%) hue-rotate(333deg) brightness(77%) contrast(89%)'
        }
      },

      '& .ql-blank:before': {
        fontStyle: 'normal',
        color: theme.palette.text.disabled
      },

      '&.error > .ql-container': {
        border: '1px solid red !important'
      }
    },
    errorText: {
      position: 'absolute',
      margin: '3px 14px 0'
    },
    viewer: {
      '& iframe': {
        maxWidth: '90%'
      },
      '& *': {
        margin: 0
      },
      '& ul, ol': {
        margin: 'inherit !important',
        paddingInlineStart: '40px !important'
      },
      '& .ql-indent-1': {
        marginLeft: '40px !important'
      },
      '& .ql-indent-2': {
        marginLeft: '80px !important'
      },
      '& img': {
        maxWidth: '100%',
        height: 'auto'
      },
      '& a': {
        color: theme.palette.primary.main
      },
      '& .attachment': {
        color: theme.palette.primary.main,
        display: 'inline-block',
        paddingBottom: '5px',
        cursor: 'pointer',

        '&:hover': {
          textDecoration: 'underline'
        }
      },

      // FONTS
      '& .ql-font-Roboto': {
        fontFamily: 'Roboto !important'
      },
      '& .ql-font-Arial': {
        fontFamily: 'Arial !important'
      },
      '& .ql-font-Helvetica': {
        fontFamily: 'Helvetica !important'
      },
      '& .ql-font-Ubuntu': {
        fontFamily: 'Ubuntu !important'
      },
      '& .ql-font-OpenSans': {
        fontFamily: 'Open Sans !important'
      },
      '& .ql-font-Montserrat': {
        fontFamily: 'Montserrat !important'
      },
      '& .ql-font-Lato': {
        fontFamily: 'Lato !important'
      },
      '& .ql-font-Poppins': {
        fontFamily: 'Poppins !important'
      },
      '& .ql-font-Inter': {
        fontFamily: 'Inter !important'
      }
      // END FONTS
    }
  })
);
