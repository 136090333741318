import React, { useMemo, useReducer } from 'react';
import { ProjectContextProviderProps, ProjectContextReducerType, ProjectState } from './interfaces';
import { initialProjectState, ProjectContext } from './ProjectContext';

const reduce = (previousContext: ProjectState, contentextUpdate: ProjectState): ProjectState => {
  return {
    ...previousContext,
    ...contentextUpdate
  };
};

const ProjectContextProvider: React.FC<ProjectContextProviderProps> = ({ children }) => {
  const [projectState, dispatch] = useReducer<ProjectContextReducerType>(
    reduce,
    initialProjectState
  );

  const contextValue = useMemo(() => ({ projectState, dispatch }), [projectState, dispatch]);

  return <ProjectContext.Provider value={contextValue}>{children}</ProjectContext.Provider>;
};

export default ProjectContextProvider;
