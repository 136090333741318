import { useContext, useState } from 'react';
import {
  CalendarReservation,
  DialogType,
  EditedReservation,
  EventType,
  InsertedReservation
} from '../../interfaces';
import { AppContext } from 'context/App/AppContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import {
  deleteCalendarReservation,
  postCalendarReservation,
  putCalendarReservation
} from 'axiosInstances';
import useT from 'hooks/useT';
import { CalendarEventsContext } from 'context/CalendarEvents/CalendarEventsContext';
import CalendarEventButtons from './CalendarEventButtons';
import EventDialogDisplay from './EventDialogDisplay';
import SnackBarNotification, { SnackbarState } from '../SnackBarNotification/SnackBarNotification';

interface EventButtonProps {
  event: EventType;
  userReservation: CalendarReservation | null;
}

const EventButton = ({ event, userReservation }: EventButtonProps) => {
  const t = useT();
  const { appState } = useContext(AppContext);
  const { projectState } = useContext(ProjectContext);
  const { apartmentState } = useContext(ApartmentContext);
  const selectedApartment = apartmentState.selectedApartment;
  const { selectedProject } = projectState;
  const { calendarEventsState, dispatch } = useContext(CalendarEventsContext);
  const [openDialog, setOpenDialog] = useState<DialogType | null>(null);
  const dialogClosingTime = 3000;
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: '',
    isSuccess: true
  });

  const handleCloseDialog = () => {
    setOpenDialog(null);
  };

  const handleOpenDialog = (dialog: DialogType) => {
    setOpenDialog(dialog);
  };

  const handleOpenSnackbar = (message: string, isSuccess: boolean) => {
    setSnackbar({
      open: true,
      message,
      isSuccess
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prevState) => ({ ...prevState, open: false }));
  };

  const addNewReservation = async (newReservation: InsertedReservation) => {
    if (!newReservation || projectState.loading || apartmentState.loading) return;

    try {
      if (!appState.token) return;

      const { organisationId, tenantId } = appState.startParameters;
      const res = await postCalendarReservation({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        gbToken: String(appState.token),
        projectId: String(selectedProject?.id),
        eventId: String(event.id),
        reservationData: newReservation
      });
      const eventReservation: CalendarReservation = res.data;

      if (calendarEventsState.CalendarEventsSection?.events) {
        const updatedEvents =
          calendarEventsState.CalendarEventsSection?.events?.map((e: EventType) =>
            e.id === event.id ? { ...e, reservations: [eventReservation, ...e.reservations] } : e
          ) ?? [];

        dispatch({
          ...calendarEventsState,
          CalendarEventsSection: {
            ...calendarEventsState.CalendarEventsSection,
            events: updatedEvents
          }
        });
      }
      handleOpenSnackbar(t(`calendarSection.snackboxSavedMessage`), true);
    } catch (e) {
      console.error(e);
      handleOpenSnackbar(t('calendarSection.createErrorMessage'), false);
    }
  };

  const updateExistingReservation = async (
    reservationId: number,
    newReservation: EditedReservation
  ) => {
    if (!newReservation || projectState.loading || apartmentState.loading) return;

    try {
      if (!appState.token) return;

      const { organisationId, tenantId } = appState.startParameters;
      const res = await putCalendarReservation({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        gbToken: String(appState.token),
        projectId: String(selectedProject?.id),
        eventId: String(event.id),
        reservationId: String(reservationId),
        updatedReservationData: newReservation
      });
      const eventReservation: CalendarReservation = res.data;

      if (calendarEventsState.CalendarEventsSection?.events) {
        const updatedEvents =
          calendarEventsState.CalendarEventsSection?.events.map((e: EventType) => {
            if (e.id === event.id) {
              const updatedReservations = e.reservations.map((r) =>
                r.id === reservationId ? eventReservation : r
              );
              return { ...e, reservations: updatedReservations };
            }
            return e;
          }) ?? [];

        dispatch({
          ...calendarEventsState,
          CalendarEventsSection: {
            ...calendarEventsState.CalendarEventsSection,
            events: updatedEvents
          }
        });
      }
      handleOpenSnackbar(t(`calendarSection.snackboxSavedMessage`), true);
    } catch (e) {
      console.error(e);
      handleOpenSnackbar(t('calendarSection.editErrorMessage'), false);
    }
  };

  const deleteExistingReservation = async (reservationId: number) => {
    if (projectState.loading || apartmentState.loading) return;

    try {
      if (!appState.token) return;

      const { organisationId, tenantId } = appState.startParameters;
      await deleteCalendarReservation({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        gbToken: String(appState.token),
        projectId: String(selectedProject?.id),
        eventId: String(event.id),
        reservationId: String(reservationId)
      });

      if (calendarEventsState.CalendarEventsSection?.events) {
        const updatedEvents =
          calendarEventsState.CalendarEventsSection?.events.map((e: EventType) => {
            if (e.id === event.id) {
              const updatedReservations = e.reservations.filter((r) => r.id !== reservationId);
              return { ...e, reservations: updatedReservations };
            }
            return e;
          }) ?? [];

        dispatch({
          ...calendarEventsState,
          CalendarEventsSection: {
            ...calendarEventsState.CalendarEventsSection,
            events: updatedEvents
          }
        });
      }
      handleOpenSnackbar(t(`calendarSection.snackboxDeletedMessage`), true);
    } catch (e) {
      console.error(e);
      handleOpenSnackbar(t('calendarSection.deleteErrorMessage'), false);
    }
  };

  const handleNotParticipateEvent = async () => {
    if (selectedApartment && event.endTime && event.startTime) {
      const newRegistration: InsertedReservation = {
        apartmentId: selectedApartment?.id,
        endTime: event.endTime,
        numberOfPersonsAttending: 0,
        startTime: event.startTime,
        answer1: '',
        answer2: ''
      };

      await addNewReservation(newRegistration);
      handleOpenSnackbar(t(`calendarSection.snackboxSavedMessage`), true);
    }
  };

  return (
    <>
      <CalendarEventButtons
        event={event}
        userReservation={userReservation}
        handleOpenDialog={handleOpenDialog}
        handleNotParticipateEvent={handleNotParticipateEvent}
      />
      <EventDialogDisplay
        event={event}
        openDialog={openDialog}
        userReservation={userReservation}
        handleCloseDialog={handleCloseDialog}
        addNewReservation={addNewReservation}
        updateExistingReservation={updateExistingReservation}
        deleteExistingReservation={deleteExistingReservation}
      />
      <SnackBarNotification
        open={snackbar.open}
        message={snackbar.message}
        isSuccess={snackbar.isSuccess}
        onClose={handleCloseSnackbar}
        dialogClosingTime={dialogClosingTime}
      />
    </>
  );
};

export default EventButton;
