import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => {
  return createStyles({
    content: {
      padding: '24px !important'
    },
    title: {
      fontWeight: 500,
      padding: '0',
      marginBottom: '8px'
    },
    description: {
      fontWeight: 400,
      color: '#667085',
      padding: '0'
    },
    iconContainer: {
      height: '56px',
      width: '56px',
      background: '#FEF0C7',
      display: 'flex',
      borderRadius: '100%',
      justifyContent: 'center',
      border: '8px solid #FFFAEB',
      marginBottom: '20px',
      '& > svg': {
        color: '#DC6803',
        margin: 'auto'
      }
    },
    actions: {
      marginTop: '32px',
      padding: '0',
      '& > button': {
        width: '50%'
      }
    },
    closeIcon: {
      position: 'relative',
      left: '100%',
      marginTop: '-1rem',
      cursor: 'pointer'
    }
  });
});
