import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderRadius: '8px',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.palette.grey[200]
      }
    },
    createNewButton: {
      position: 'absolute',
      right: 8,
      top: 8,
      width: '1rem',
      height: '1rem',
      color: theme.palette.primary.main
    },
    cardContent: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      flexDirection: 'row',
      position: 'relative'
    },

    boxDetails: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'flex-start'
    },
    titleDetail: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '5px',
      marginTop: '5px',
      maxWidth: '85%'
    },
    waiverDocument: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    textStyle: {
      marginLeft: '10px',
      color: theme.palette.grey[800],
      fontSize: theme.typography.subtitle2.fontSize,
      maxWidth: '85%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    nameStyle: {
      color: theme.palette.sectionsHeader?.main,
      fontSize: theme.typography.h6.fontSize,
      maxWidth: '85%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    statusStyle: {
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.grey[800]
    },
    iconColor: {
      color: theme.palette.primary.main
    }
  })
);
