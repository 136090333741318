import { StyledPaper } from '@groupbuilderoy/gb-components-library';
import Form from 'components/Form/Form';
import { FormState, FieldType } from 'components/Form/interfaces';
import { SectionSettings } from 'components/Sections/Section';
import { AppContext } from 'context/App/AppContext';
import { PhaseFormProps, PhaseToSubmit } from 'context/Phase/interfaces';
import { SectionContext } from 'context/Section/SectionContext';
import { useContext } from 'react';

export default function PhaseForm({ phase, orderNumber, onSubmit, onDelete }: PhaseFormProps) {
  const { sectionState } = useContext(SectionContext);
  const settings = sectionState.settings as SectionSettings;
  const { maxNameLength } = settings.validation;
  const { appState } = useContext(AppContext);

  const phaseIconsArray = [
    'building_starts.png',
    'deal.png',
    'document.png',
    'interior_selection.png',
    'move_in.png',
    'planned.png',
    'punchlist.png',
    'newspaper.png',
    'user.png'
  ];
  const phaseIconOptions = phaseIconsArray.map((icon) => {
    const iconUrl = `/Icons/PhaseIcons/${appState.tenantSettings?.cjCompanyResourcesFolder}/${icon}`;
    return {
      value: iconUrl,
      name: icon
    };
  });

  const initialFormState = {
    name: {
      value: phase?.name || '',
      required: true,
      validationErrorKey: 'validation.fillName',
      type: FieldType.STRING,
      localizationKey: 'newSection.name',
      maxLength: maxNameLength
    },
    icon: {
      value: phase?.icon,
      defaultValue: true,
      required: true,
      validationErrorKey: 'validation.fillSectionType',
      type: FieldType.SELECT_ICON,
      options: phaseIconOptions,
      localizationKey: 'phase.phaseIcon',
      disabled: phaseIconOptions.length === 1
    }
  } as FormState;

  const onSubmitForm = async (formState: FormState) => {
    const { name, icon } = formState;
    const phaseToSubmit: PhaseToSubmit = {
      name: name.value.toString(),
      icon: icon.value.toString(),
      orderInProject: phase ? phase.orderinproject : orderNumber
    };
    if (phase) {
      phaseToSubmit.id = phase.id;
    }
    await onSubmit(phaseToSubmit);
    initialFormState.name.value = name.value;
    initialFormState.icon.value = icon.value;
  };

  const updateSections = () => {};

  return (
    <StyledPaper style={{ width: '100%' }}>
      <Form
        localizationKeys={{
          title: phase ? 'phase.titleEditPhase' : 'phase.titleNewPhase',
          submit: phase ? 'update' : 'create',
          submitSuccess: phase ? 'form.phase.updateSuccess' : 'form.phase.creationSuccess',
          submitError: phase ? 'form.phase.updateFailed' : 'form.phase.creationFailed'
        }}
        fields={initialFormState}
        onBackButtonClick={updateSections}
        onSubmit={onSubmitForm}
        onDelete={onDelete}
      />
    </StyledPaper>
  );
}
