import { AxiosRequestConfig } from 'axios';
import i18n from 'i18next';
export const baseUrl =
  process.env.REACT_APP_CORE_API_URL ||
  global.env.CORE_API_URL ||
  'https://api.beta.gbuilder.com/api';

interface ConfigOptions {
  token?: null | string;
  timeout?: number;
  //  eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers?: any;
  params?: unknown;
}

interface PathVariables {
  organisationId?: string;
  tenantId?: string;
  projectId?: string;
  apartmentId?: string;
}

//  eslint-disable-next-line
export const createConfig: (options: ConfigOptions) => AxiosRequestConfig = ({
  token,
  timeout,
  headers,
  params
}) => {
  return {
    baseURL: baseUrl,
    headers: {
      ...headers,
      'accept-language': i18n.language || 'en',
      Authorization: token ? `Bearer ${token}` : undefined,
      'gb-client': 'CJ3'
    },
    timeout,
    params
  };
};

export const createPrivatePath = (
  { tenantId, organisationId, projectId, apartmentId }: PathVariables,
  apiVersion = 'v6'
) => {
  let path = `/${apiVersion}/private`;

  if (organisationId) path += `/organisations/${organisationId}`;
  if (tenantId) path += `/tenants/${tenantId}`;
  if (projectId) path += `/projects/${projectId}`;
  if (apartmentId) path += `/apartments/${apartmentId}`;

  return path;
};
