import ReactDOM from 'react-dom';
import './fonts/baseFonts.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18n';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

import AppContextProvider from 'context/App/AppContextProvider';
import ProjectContextProvider from 'context/Project/ProjectContextProvider';
import CustomTheme from 'components/CustomTheme/CustomTheme';
import ApartmentContextProvider from 'context/Apartment/ApartmentContextProvider';
import AttachmentViewerContextProvider from 'context/AttachmentViewer/AttachmentViewerContextProvider';
import ModalMessageContextProvider from 'context/ModalMessage/ModalMessageContextProvider';

ReactDOM.render(
  <AppContextProvider>
    <ProjectContextProvider>
      <ApartmentContextProvider>
        <ModalMessageContextProvider>
          <AttachmentViewerContextProvider>
            <CustomTheme>
              <ErrorBoundary>
                <Router>
                  <App />
                </Router>
              </ErrorBoundary>
            </CustomTheme>
          </AttachmentViewerContextProvider>
        </ModalMessageContextProvider>
      </ApartmentContextProvider>
    </ProjectContextProvider>
  </AppContextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
