import { Theme, makeStyles } from '@material-ui/core/styles';

interface StylesProps {
  pictureSize?: string;
  fontSize?: string;
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: (props: StylesProps) => (props.pictureSize ? props.pictureSize : '50px'),
    height: (props: StylesProps) => (props.pictureSize ? props.pictureSize : '50px'),
    fontSize: (props: StylesProps) => (props.fontSize ? props.fontSize : '1rem')
  },
  pictureUrlStyle: {
    width: (props: StylesProps) => (props.pictureSize ? props.pictureSize : '50px'),
    height: (props: StylesProps) => (props.pictureSize ? props.pictureSize : '50px'),
    fontSize: (props: StylesProps) => (props.fontSize ? props.fontSize : '1rem')
  }
}));

export default useStyles;
