import { fetchApartmentInfo, getApartments } from '../axiosInstances/instances/app';
import { useContext, useEffect } from 'react';
import { AppContext } from 'context/App/AppContext';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import StartParameter from 'utils/startParameters';
import useT from './useT';
import { Apartment } from 'context/Apartment/interfaces';

const useSetupApartments = () => {
  const { appState } = useContext(AppContext);
  const { projectState } = useContext(ProjectContext);
  const { apartmentState, dispatch } = useContext(ApartmentContext);
  const { organisationId, tenantId, apartmentId } = appState.startParameters;
  const { selectedApartment } = apartmentState;
  const t = useT();

  const setupApartments = async () => {
    if (!projectState.selectedProject || apartmentState.loadDetails) return;

    dispatch({
      ...apartmentState,
      loading: true
    });

    try {
      const { apartments, selectedApartment } = await getApartments({
        gbToken: String(appState.token),
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        projectId: String(projectState.selectedProject.id),
        apartmentId: String(apartmentId)
      });

      if (!apartments.length) throw Error('No apartments found');

      dispatch({
        ...apartmentState,
        apartments,
        selectedApartment,
        loadDetails: true
      });
    } catch (e) {
      console.error(e);
      dispatch({
        ...apartmentState,
        error: t('alert.apartmentsFetchFailed')
      });
    }
  };

  const setupApartmentDetails = async () => {
    if (!apartmentState.loadDetails || !selectedApartment) return null;

    dispatch({
      ...apartmentState,
      loading: true
    });

    const apartment = selectedApartment;
    const projectId = projectState.selectedProject?.id;
    let apartmentWithInfo: Apartment = {} as Apartment;

    try {
      apartmentWithInfo = await fetchApartmentInfo({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        projectId: String(projectId),
        apartmentId: String(apartment.id),
        gbToken: String(appState.token),
        includeMessageStats: true
      });

      sessionStorage.setItem(StartParameter.apartmentId, String(apartment.id));
    } catch (e) {
      console.error(e);
      dispatch({
        ...apartmentState,
        error: t('alert.apartmentDetailsFetchFailed')
      });
    } finally {
      dispatch({
        ...apartmentState,
        selectedApartment: apartmentWithInfo,
        loading: false,
        loadDetails: false
      });
    }
  };

  useEffect(() => {
    setupApartments();
  }, [projectState.selectedProject?.id]); // eslint-disable-line

  useEffect(() => {
    setupApartmentDetails();
  }, [apartmentState.loadDetails]); // eslint-disable-line
};

export default useSetupApartments;
