import { Divider, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import BackButton from 'components/BackButton/BackButton';
import { useContext, useEffect, useRef, useState } from 'react';
import { ChatStateAction } from 'store/chatState/actions';
import { ChatStateContext } from 'store/chatState/ChatStateProvider';
import NewChatMessageForm from '../NewChatMessageForm/NewChatMessageForm';
import { ChatStatusType } from '../types';
import ChatMessage from './ChatMessage/ChatMessage';
import { PostMessageResponseType } from 'axiosInstances/instances/types';
import styles from './styles';
import useT from 'hooks/useT';
import { AppContext } from 'context/App/AppContext';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import { Room } from 'context/Apartment/interfaces';

interface ChatRoomProps {
  refreshChat: () => void;
}

const ChatRoom: React.FC<ChatRoomProps> = ({ refreshChat }: ChatRoomProps) => {
  const classes = styles();
  const t = useT();
  const { chatState, dispatch } = useContext(ChatStateContext);
  const { appState } = useContext(AppContext);
  const messagesEnd = useRef<HTMLDivElement | null>(null);

  const { thread, newChat } = chatState;
  const [isChatFormDisabled, setIsChatFormDisabled] = useState<boolean>(false);
  const { apartmentState } = useContext(ApartmentContext);
  const [newRoomId, setNewRoomId] = useState<null | string>(null);

  const goBack = () => {
    dispatch({ type: ChatStateAction.SET_CHAT_ROOM, data: undefined });
    if (newChat) dispatch({ type: ChatStateAction.SET_NEW_CHAT, data: false });
  };

  const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value) {
      setNewRoomId(event.target.value as string);
      setIsChatFormDisabled(false);
    } else {
      setIsChatFormDisabled(true);
    }
  };

  const onSendHandler = ({ posted }: PostMessageResponseType, message: string) => {
    refreshChat();
    if (chatState.newChat && posted) {
      goBack();
    } else if (message && posted && thread?.room.id) {
      dispatch({
        type: ChatStateAction.SEND_MESSAGE_TO_THREAD,
        data: {
          message,
          roomId: thread.room.id,
          userId: appState.user?.userId || -1,
          username: appState.user?.name || ''
        }
      });
    }
  };

  useEffect(() => {
    if (messagesEnd.current) {
      messagesEnd.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [thread?.messages.length]);

  return thread || newChat ? (
    <Grid item container direction='column' className={classes.messageContainer} wrap='nowrap'>
      <Grid item className={classes.chatHeader}>
        <BackButton customClickHandler={goBack} />
        <Typography className={classes.subjectArea}>
          {newChat ? t('chat.selectSubjectArea') : t('chat.subjectArea')}
        </Typography>
        {newChat ? (
          <Select
            variant='outlined'
            displayEmpty
            onChange={onChange}
            value={newRoomId}
            className={classes.roomName}
          >
            <MenuItem value=''>
              <em>{t('chat.selectArea')}</em>
            </MenuItem>
            {apartmentState.selectedApartment?.rooms
              ?.filter((room) => !room.messages)
              .map((room: Room) => {
                return (
                  <MenuItem key={`new-chat-room-id-${room.id}`} value={room.id}>
                    {room.name}
                  </MenuItem>
                );
              })}
          </Select>
        ) : (
          <Typography className={classes.roomName}>{thread?.room.name}</Typography>
        )}
        <Divider />
      </Grid>
      {thread?.messages?.length ? (
        <Grid item>
          {thread.messages.map((message, index) => {
            return message ? (
              <ChatMessage
                key={`message-list-item-${index}`}
                message={message}
                index={index}
                messages={thread.messages}
              />
            ) : null;
          })}
          <div ref={messagesEnd} />
        </Grid>
      ) : null}
      <Grid item>
        {thread?.messages[0].discussionEnd === ChatStatusType.CLOSED ? (
          <Typography>{t('chat.conversationClosed')}</Typography>
        ) : null}
      </Grid>
      {thread?.room.id || newRoomId ? (
        <NewChatMessageForm
          roomId={Number(thread?.room.id || newRoomId)}
          disabled={isChatFormDisabled}
          onSendHandler={onSendHandler}
        />
      ) : null}
    </Grid>
  ) : null;
};

export default ChatRoom;
