import { makeStyles } from '@material-ui/core';
import { EventType } from '../interfaces';
import { ListEvent } from './ListEvent/ListEvent';

export interface CalendarPropsType {
  events: EventType[];
  user: any;
}

const useStyles = makeStyles(() => ({
  scrollContainer: {
    overflowY: 'auto',
    maxHeight: '300px',
    width: '100%',
    boxSizing: 'border-box'
  }
}));
export default function CalendarList(props: CalendarPropsType) {
  const classes = useStyles();
  const currentTime = new Date();

  const filteredEvents = props.events.filter(
    (e: EventType) => e.endTime && new Date(e.endTime) > currentTime
  );
  return (
    <div className={classes.scrollContainer}>
      {filteredEvents.map((event, index) => (
        <ListEvent key={`list-event-${index}`} event={event} user={props.user} />
      ))}
    </div>
  );
}
