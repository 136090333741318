import { Grid } from '@material-ui/core';
import AttachmentListItem from 'components/Attachments/AttachmentList/AttachmentListItem';
import SectionWrapper from 'components/Sections/SectionWrapper/SectionWrapper';
import useGetAttachments from 'hooks/useGetAttachments';
import AttachmentsSection from './AttachmentsSection';
import { AttachmentType } from './interfaces';
import { useCallback, useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import useT from 'hooks/useT';
import { Button } from '@groupbuilderoy/gb-components-library';
import useStyles from './styles';

interface Props {
  section: AttachmentsSection;
}

const AttachmentsPage = ({ section }: Props) => {
  const { loading, attachments, error } = useGetAttachments(String(section.id));
  const t = useT();
  const classes = useStyles();

  const images = attachments.reduce((images: string[], current: AttachmentType) => {
    if (current?.mimeType?.includes('image')) {
      images.push(current.url);
    }
    return images;
  }, [] as string[]);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const download = () => {
    const viewer = document.getElementById('ReactSimpleImageViewer');
    const img = viewer?.children.item(3)?.firstChild?.firstChild as HTMLImageElement;
    window.open(img.src, '_blank');
  };

  return (
    <>
      {isViewerOpen && (
        <div className={classes.attachmentsPage}>
          {' '}
          <Button
            caption={t('attachments.download')}
            onClick={() => download()}
            style={{ position: 'fixed', zIndex: 2, top: '25px', right: '75px' }}
          />
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
            backgroundStyle={{ background: 'rgba(0, 0, 0, 0.3)' }}
          />
        </div>
      )}
      <SectionWrapper
        title={section.name}
        subtitle={section.description}
        loading={loading}
        error={error}
      >
        {attachments.length && (
          <Grid container spacing={3}>
            {attachments.map((attachment, index) => {
              return (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                  <AttachmentListItem
                    key={`attachment-${index}`}
                    attachment={attachment}
                    onClick={() => {
                      if (attachment?.mimeType?.includes('image')) {
                        const imagesIndex = images.findIndex((img) => img === attachment.url);
                        openImageViewer(imagesIndex);
                      }
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </SectionWrapper>
    </>
  );
};

export default AttachmentsPage;
