import { Color } from '@material-ui/lab';

/* eslint-disable */
export enum SectionType {
  ATTACHMENTS = 4,
  ANNOUNCEMENTS = 10,
  CALENDAR = 5,
  MATERIAL_SELECTIONS = 2,
  EVENTS = 11,
  FOLDER = 0,
  CHECKLIST = 6,
  SURVEYS = 7,
  CONTACTS = 1,
  DEADLINES = 12,
  CUSTOM_CONTENT = 8
}
/* eslint-enable */

export interface SectionSettings {
  validation: {
    allowedTags: string[];
    allowedAttributes: any;
    maxAttachmentFileCount: number;
    maxAttachmentFileSize: number;
    maxHtmlContentSize: number;
    maxNameLength: number;
    maxDescriptionLength: number;
  };
  previewContentTag: string;
}

export interface Attachment {
  filename: string;
  id: number;
  mimeType: string;
  name: string;
  url: string;
  file?: File;
  editorId?: number;
}
export interface ISection {
  id?: number;
  name: string;
  description?: string;
  phaseId: number;
  sectionId?: number;
  type?: SectionType;
  parentSectionId?: number;
  orderNumber?: number;
  width: number;
}

export interface ICustomContentSection extends ISection {
  content: string;
  preview?: string;
  attachments?: Attachment[];
}

export default class Section implements ISection {
  id: number;
  name: string;
  description: string;
  phaseId: number;
  type?: SectionType;
  width: number;
  orderNumber: number;
  parentSectionId?: number;

  listMaxCount = 3;
  actionButtonTxtKey = 'viewAll';
  alertProps = {
    error: { severity: 'error' as Color, txtKey: 'alert.sectionError' },
    noItems: {
      severity: 'info' as Color,
      txtKey: 'alert.sectionNotImplemented'
    }
  };

  constructor(props: any) {
    this.id = props.id;
    this.name = props.name;
    this.description = props.description;
    this.phaseId = props.phaseId;
    this.width = props.width;
    this.orderNumber = props.orderNumber;
    this.type = props.type;
    this.alertProps.noItems.txtKey =
      this.type === SectionType.CALENDAR
        ? 'alert.calendarNotImplemented'
        : 'alert.sectionNotImplemented';
  }
}
