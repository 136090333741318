import HtmlContentViewer from 'components/HtmlContent/HtmlContentViewer';
import SectionWrapper from 'components/Sections/SectionWrapper/SectionWrapper';
import useGetCustomContent from 'hooks/useGetCustomContent';
import CustomContentSection from './CustomContentSection';

interface Props {
  section: CustomContentSection;
}

const CustomContentPage = ({ section }: Props) => {
  const { loading, customContent, error } = useGetCustomContent(section.id);

  return (
    <SectionWrapper
      title={section.name}
      subtitle={section.description}
      loading={loading}
      error={error}
    >
      <HtmlContentViewer
        content={String(customContent?.content)}
        attachments={customContent?.attachments}
      />
    </SectionWrapper>
  );
};

export default CustomContentPage;
