import { createStyles, makeStyles } from '@material-ui/core/styles';
export default makeStyles(() =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    leftButtons: {
      display: 'flex'
    },
    rightButton: {
      marginLeft: 'auto'
    },
    button: {
      marginBottom: '1rem',
      marginLeft: '1rem'
    },
    buttonLeft: {
      marginLeft: '0'
    }
  })
);
