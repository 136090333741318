import { CircularProgress } from '@material-ui/core';
import useStyles from './styles';

export default function Progress(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress />
    </div>
  );
}
