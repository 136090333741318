import { ICustomContentSection, SectionType } from 'components/Sections/Section';
import { useEffect, useState } from 'react';
import useApi from './useApi';

export default function useGetCustomContent(sectionId: number) {
  const api = useApi();

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [customContent, setCustomContent] = useState<ICustomContentSection>();

  const fetch = async () => {
    try {
      const customContentSection = (await api.getSection(
        sectionId,
        SectionType.CUSTOM_CONTENT
      )) as ICustomContentSection;

      setCustomContent(customContentSection);
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line

  return { loading, customContent, error };
}
