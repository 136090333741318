import { DetailedListItem, IconKeys } from '@groupbuilderoy/gb-components-library';
import { IssueState } from 'axiosInstances/instances/CheckListResponse';
import SectionCard from 'components/Sections/SectionCard/SectionCard';
import useGetChecklistItems from 'hooks/useGetChecklistItems';
import useT from 'hooks/useT';
import ChecklistSection from './ChecklistSection';

interface Props {
  section: ChecklistSection;
}

const ChecklistCard = ({ section }: Props) => {
  const t = useT();
  const { checklistItems, fetchingChecklistItems, error } = useGetChecklistItems(section);

  function getIcon(status: IssueState) {
    switch (status) {
      case IssueState.ACCEPTED:
        return IconKeys.accepted;
      case IssueState.APPROVED:
        return IconKeys.approved;
      case IssueState.COMPLETED:
        return IconKeys.completed;
      case IssueState.NEW:
        return IconKeys.new;
      case IssueState.NO_ACTION_NEEDED:
        return IconKeys.noActionNeeded;
      default:
        return IconKeys.check;
    }
  }

  return (
    <SectionCard section={section} loading={fetchingChecklistItems} error={error}>
      {checklistItems?.summary?.length &&
        checklistItems?.summary?.map((item, index) => {
          return (
            <DetailedListItem
              key={index}
              icon={getIcon(item.status)}
              title={`${item.percentage}% ${item.count}/${item.totalCount}`}
              subtitle={t(`checklist.status.${item.status}`)}
            />
          );
        })}
    </SectionCard>
  );
};

export default ChecklistCard;
