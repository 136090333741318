import { createStyles, makeStyles } from '@material-ui/core/styles';

const spacing = '20px';

export default makeStyles(() =>
  createStyles({
    form: {
      '& *': {
        fontFamily: 'Helvetica Neue,sans-serif !important'
      }
    },
    headingContainer: {
      margin: '1.5rem 0',
      position: 'relative'
    },
    inputField: {
      marginTop: spacing,
      width: '100%'
    },
    buttonRow: {
      marginTop: spacing,
      display: 'flex',
      justifyContent: 'end'
    },
    formAlert: {
      marginBottom: '10px'
    },
    formAlertSrv: {
      marginBottom: '10px',
      color: 'black',
      background: '#FFE800',
      border: 'none',
      '& *': {
        color: 'black'
      }
    },
    iconImage: {
      width: '40px',
      height: '40px'
    },
    iconSelectors: {
      maxWidth: '400px',
      minWidth: '200px'
    },
    deleteButton: {
      position: 'absolute',
      top: '5px',
      right: '1rem'
    },
    action: {
      position: 'relative',
      cursor: 'pointer',
      margin: '0'
    }
  })
);
