import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '5rem',
      position: 'sticky',
      bottom: 0,
      paddingTop: theme.spacing(2),
      background: 'white'
    },
    sendIcon: {
      right: '1rem',
      position: 'absolute',
      transform: 'rotate(330deg)',
      cursor: 'pointer'
    },
    input: {
      width: '100%'
    }
  })
);
