import { useTheme } from '@material-ui/core';
import { MessagingClientType, MessagingUI } from '@groupbuilderoy/messaging-client';
import { useContext } from 'react';
import useStyles from './styles';
import { Apartment } from '@groupbuilderoy/messaging-client/dist/interfaces';
import i18next from 'i18next';
import Progress from 'components/Progress/Progress';
import { useShowMessaging } from 'hooks/useShowMessaging';
import { Alert } from '@material-ui/lab';
import useT from 'hooks/useT';
import ChatStateProvider from 'store/chatState/ChatStateProvider';
import Chat from 'components/Chat/Chat';
import { AppContext } from 'context/App/AppContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';

export default function Messaging() {
  const { appState } = useContext(AppContext);
  const { token, startParameters } = appState;
  const { projectState } = useContext(ProjectContext);
  const { apartmentState } = useContext(ApartmentContext);
  const { selectedProject } = projectState;
  const { selectedApartment } = apartmentState;
  const { tenantId, organisationId } = startParameters;
  const theme = useTheme();
  const classes = useStyles();
  const { messagingEnabled, newMessagingEnabled } = useShowMessaging();
  const t = useT();

  if (messagingEnabled === null || projectState.loading || apartmentState.loading) {
    return <Progress />;
  } else if (messagingEnabled === false) {
    return (
      <Alert variant='outlined' severity='info'>
        {t('alert.messagingNotEnabled')}
      </Alert>
    );
  } else if (!newMessagingEnabled) {
    return (
      <ChatStateProvider>
        <Chat />
      </ChatStateProvider>
    );
  }

  return (
    <div className={classes.container} data-testid='messaging-ui'>
      <MessagingUI
        token={String(token)}
        tenantId={String(tenantId)}
        organisationId={String(organisationId)}
        project={{
          id: Number(selectedProject?.id),
          name: String(selectedProject?.name)
        }}
        theme={theme}
        language={i18next.language}
        apartments={[{ id: selectedApartment?.id, name: selectedApartment?.name } as Apartment]}
        clientType={MessagingClientType.CustomerJourney}
      />
    </div>
  );
}
