import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    content: {
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'center',
      width: '100%'
    },
    headingContainer: {
      margin: '1.5rem 0'
    },
    subtitle: {
      margin: '0',
      color: theme.palette.grey[900],
      fontSize: '.875rem'
    }
  })
);
