import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      position: 'relative',
      padding: '8px 4px 20px',

      '&:not(:last-child)': {
        borderBottom: '1px solid ' + theme.palette.grey[400]
      },

      '&:not(:first-child)': {
        paddingTop: '25px'
      },

      '& > div': {
        alignItems: 'flex-start'
      },

      '& .icon': {
        opacity: 0.5
      },

      '& .image': {
        borderRadius: '50%',
        overflow: 'hidden'
      },

      '&.link': {
        cursor: 'pointer'
      }
    },
    details: {
      alignItems: 'flex-start',
      paddingLeft: '1rem'
    },
    name: {
      color: theme.palette.common.black
    },
    detail: {
      fontSize: theme.typography.subtitle2.fontSize,
      opacity: 0.85
    },
    description: {
      color: theme.palette.common.black,
      fontSize: theme.typography.caption.fontSize,
      lineHeight: '1.1',
      marginTop: '1rem'
    }
  });
});
