import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(5)
  },
  formContainer: {
    marginBottom: theme.spacing(5)
  },
  textField: {
    marginBottom: theme.spacing(5)
  },
  fileList: {
    marginTop: theme.spacing(5)
  },
  listItem: {
    marginBottom: theme.spacing(5)
  },
  buttonGroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px 22px 24px'
  },
  rightMargin: {
    marginLeft: '10px',
    fontSize: theme.typography.overline.fontSize,
    textTransform: 'none'
  },
  button: {
    fontSize: theme.typography.overline.fontSize,
    textTransform: 'none'
  },
  titleStyle: {
    color: theme.palette.sectionsHeader?.main
  },
  snackBar: {
    padding: '8px',
    display: 'flex',
    flexDirection: 'row'
  },
  snackBarContainer: {
    backgroundColor: theme.palette.navBarPrimary?.main,
    borderRadius: 10,
    marginBottom: '30px',
    padding: 4
  },
  rightButtonContainer: {
    marginLeft: 'auto',
    display: 'flex',
    gap: theme.spacing(1)
  },
  questionText: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.black,
    fontSize: theme.typography.subtitle2.fontSize
  } as CSSProperties
}));

export default useStyles;
