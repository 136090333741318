import { AxiosResponse } from 'axios';
import { instance } from 'axiosInstances';
import UserRights from 'context/App/UserRights';
import { BaseResponse } from './types';
import { createConfig, createPrivatePath } from './utils';
import { User } from 'context/App/interfaces';

export interface LoginResponse {
  data: {
    userID: number;
    name: string;
    userLevel: number;
    canUpload: boolean;
    email: string;
  };
  error: null | string;
  token: string;
}

export const login = (
  username: string,
  password: string,
  tenantId: string
): Promise<AxiosResponse<LoginResponse>> =>
  instance.post(`/v1/public/accounts/login`, {
    username,
    password,
    schema: tenantId
  });

export interface TokenExchangeResponse {
  token: string;
  user: {
    email: string;
    name: string;
    userId: number;
    userLevel: number;
    auth0Id: string;
  };
}

export const tokenExchange = async (
  organisationId: string,
  tenantId: string,
  accessToken: string
): Promise<TokenExchangeResponse> => {
  try {
    const { data, error } = (await instance.get(
      `/v6/public/organisations/${organisationId}/tenants/${tenantId}/token-exchange`,
      createConfig({ token: accessToken })
    )) as any;

    if (error || !data.data.token || !data.data.user) throw new Error('Failed to login');

    const token = data.data.token as string;
    const user = data.data.user;

    return { token, user };
  } catch (e) {
    throw e;
  }
};

export const fetchUserRights = async ({
  token,
  organisationId,
  tenantId,
  projectId
}: any): Promise<UserRights> => {
  try {
    const { error, data } = (await instance.get(
      createPrivatePath({ organisationId, tenantId, projectId }) + '/user-rights',
      createConfig({ token })
    )) as BaseResponse;

    if (error || !data.data?.userRights) throw new Error('Unable to fetch user rights!');

    return new UserRights(data.data.userRights);
  } catch (e) {
    throw e;
  }
};

export const getProjectsWithApartments = async (token: string) => {
  try {
    const response = (await instance.get(
      createPrivatePath({}, 'v2') + '/projects/with-apartments',
      createConfig({ token })
    )) as BaseResponse;

    if (response.error || !response.data)
      throw new Error('Invalid response for projects and apartments');

    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const renewToken = async (authToken: string): Promise<TokenExchangeResponse> => {
  try {
    const { data, error } = (await instance.get(
      'v1/private/accounts/login/renew-token',
      createConfig({ token: authToken })
    )) as any;
    if (error || !data.token || !data.data) throw new Error('Failed to renew authToken');

    const token = data.token as string;
    const user = data.data;

    return { token, user };
  } catch (e) {
    throw e;
  }
};

export const getUserDetailsByBearerToken = async (
  organisationId: string,
  tenantId: string,
  authToken: string
): Promise<User> => {
  try {
    const { data } = (await instance.get(
      `/v6/private/organisations/${organisationId}/tenants/${tenantId}/token-bearer`,
      createConfig({ token: authToken })
    )) as any;
    if (data.error || !data.data.user) throw new Error('Failed to get user data by bearer token');

    const user = data.data.user;

    return user;
  } catch (e) {
    throw e;
  }
};

interface OwnerUserParams {
  organisationId: string;
  tenantId: string;
  apartmentId: string;
  projectId: string;
  gbToken: string;
  userLevel: number;
}

export const GetOwnerUserForApartment = async ({
  gbToken,
  organisationId,
  tenantId,
  projectId,
  apartmentId,
  userLevel
}: OwnerUserParams) => {
  const { data } = await instance.get(
    `/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/users?apartmentIds=${apartmentId}&userLevels=${userLevel}&includeIds=true`,
    createConfig({ token: gbToken })
  );
  return data.data.users;
};

export const GetOwnerSearchUsers = async ({
  gbToken,
  organisationId,
  tenantId,
  projectId
}: {
  organisationId: string;
  tenantId: string;
  projectId: string;
  gbToken: string;
}) => {
  const { data } = await instance.get(
    `/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments?rooms=false&owners=true&selectionStats=false&attachmentsCount=false`,
    createConfig({ token: gbToken })
  );
  return data.data.apartments;
};
