import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import { AppContext } from 'context/App/AppContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import i18next from 'i18next';
import { useContext } from 'react';

export const useGetCJ2Url = (integrated?: boolean) => {
  const { appState } = useContext(AppContext);
  const { projectState } = useContext(ProjectContext);
  const { apartmentState } = useContext(ApartmentContext);
  const { selectedProject } = projectState;
  const { selectedApartment } = apartmentState;
  const { tenantSettings, startParameters, token } = appState;

  const url = `${tenantSettings?.customerJourney2_url}?apartmentId=${selectedApartment?.id}&tenantId=${startParameters.tenantId}&organisationId=${startParameters.organisationId}&projectId=${selectedProject?.id}&gbToken=${token}&lang=${i18next.language}`;
  return integrated ? url + '&integratedApp=true' : url;
};
