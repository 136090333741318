import {
  FaFolderOpen,
  FaUsers,
  FaCheckSquare,
  FaFileAlt,
  FaCalendarAlt,
  FaQuestionCircle,
  FaPaintBrush,
  FaBullhorn,
  FaClipboardList,
  FaClock,
  FaPlus
} from 'react-icons/fa';
import { SectionType } from '../Section';

import { IconType } from 'react-icons';

export const sectionIcons: Record<SectionType, IconType> = {
  [SectionType.FOLDER]: FaFolderOpen,
  [SectionType.CONTACTS]: FaUsers,
  [SectionType.CHECKLIST]: FaCheckSquare,
  [SectionType.ATTACHMENTS]: FaFileAlt,
  [SectionType.CALENDAR]: FaCalendarAlt,
  [SectionType.SURVEYS]: FaQuestionCircle,
  [SectionType.MATERIAL_SELECTIONS]: FaPaintBrush,
  [SectionType.ANNOUNCEMENTS]: FaBullhorn,
  [SectionType.EVENTS]: FaClipboardList,
  [SectionType.DEADLINES]: FaClock,
  [SectionType.CUSTOM_CONTENT]: FaPlus
};
