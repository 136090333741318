import { useContext, useEffect } from 'react';
import { SectionContext } from 'context/Section/SectionContext';
import FormsSection from './FormSection';
import { DetailedListItem, IconKeys } from '@groupbuilderoy/gb-components-library';
import { FormsContext } from 'context/Forms/FormsContext';
import SectionCard from '../SectionCard/SectionCard';
import useGetForms from 'hooks/useGetForms';

interface Props {
  section: FormsSection;
}

const FormsCard = ({ section }: Props) => {
  const { sectionState, dispatch: dispatchSectionState } = useContext(SectionContext);
  const { formsState } = useContext(FormsContext);
  const { loading, error } = useGetForms(sectionState.sections[0].id.toString());

  useEffect(() => {
    dispatchSectionState({ ...sectionState, showBackButton: false });
  }, []); // eslint-disable-line
  return (
    <>
      <SectionCard section={section} loading={loading} error={!!error}>
        <>
          {formsState.formsSection?.forms.length &&
            formsState.formsSection?.forms.map((form, index) => {
              return (
                <DetailedListItem
                  key={index}
                  icon={IconKeys.signable}
                  title={form.name}
                  subtitle={form.description ?? ''}
                />
              );
            })}
        </>
      </SectionCard>
    </>
  );
};

export default FormsCard;
