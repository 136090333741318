import { SvgIcon, Typography, useTheme } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import DateSquare from 'components/DateSquare/DateSquare';
import { BulletinReadStatusType, BulletinType } from '../../../../types';
import styles from './styles';

import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { useState } from 'react';
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const Bulletin: React.FC<BulletinType> = ({
  sendTime,
  received,
  userName,
  content
}: BulletinType) => {
  const classes = styles();
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={classes.messageRoot} onClick={() => setExpanded(!expanded)}>
      <DateSquare
        showYear
        millisecondsTime={sendTime}
        color={
          received === BulletinReadStatusType.READ
            ? theme.palette.grey[600]
            : theme.palette.primary.main
        }
      />
      <div className={classes.messageContainer}>
        <Typography className={classes.messageSender}>{userName}</Typography>
        {!expanded ? (
          <ResponsiveEllipsis
            maxLine='2'
            text={content}
            ellipsis='...'
            basedOn='words'
            className={classes.message}
          />
        ) : (
          <Typography className={classes.message}>{content}</Typography>
        )}
      </div>
      <SvgIcon className={classes.messageArrow}>
        <ArrowForwardIos />
      </SvgIcon>
    </div>
  );
};

export default Bulletin;
