import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => {
  return createStyles({
    heading: {
      margin: '1.5rem 0'
    },
    subtitle: {
      margin: '0',
      color: theme.palette.grey[900],
      fontSize: '.875rem'
    }
  });
});
