import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    headingContainer: {
      margin: '1.5rem 0'
    },
    topButtonRow: {
      justifyContent: 'space-between',
      alignItems: 'baseline'
    },
    alert: {
      margin: '0 0 15px'
    },
    content: {
      margin: '0 0 10px'
    },
    createNewButton: {
      textTransform: 'none'
    },
    subtitle: {
      margin: '0',
      color: theme.palette.grey[900],
      fontSize: '.875rem'
    }
  })
);
