import React, { useContext } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { languages } from 'i18n';
import i18next from 'i18next';
import useStyles from './styles';
import { Icon, IconKeys } from '@groupbuilderoy/gb-components-library';
import { Select } from '@groupbuilderoy/gb-components-library';
import { AppContext } from 'context/App/AppContext';

export default function LanguageSelect() {
  const { appState } = useContext(AppContext);
  const changeLanguage = (code: string) => {
    i18next.changeLanguage(code).then(() => {
      window.location.reload();
    });
  };
  const classes = useStyles();
  return (
    <Select
      data-testid='language-selector'
      caption=''
      onChange={changeLanguage}
      itemList={
        appState.tenantSettings?.organisationId === 'srv'
          ? languages.filter((l) => l.code === 'fi-FI')
          : languages
      }
      identifierKey={'code'}
      renderKey={'title'}
      customRenderValue={
        <div className={classes.root}>
          <IconButton className={classes.icon}>
            <Icon iconKey={IconKeys.languages} />
          </IconButton>
          <Typography className={classes.langName}>
            {languages.find((l) => l.dateFnsLocaleKey === i18next.language)?.title}
          </Typography>
        </div>
      }
      hideIcon={true}
      initialValue={i18next.language || 'en'}
    />
  );
}
